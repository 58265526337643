import './App.css';
import {

    Routes,
    Route,
    useNavigate,


} from "react-router-dom";


import { History } from "history";
import TenantSelector from './components/Home/tenantSelector';
import Index from './components/Home/index';
import PoppedWizard from './components/template/fullWidth/poppedWizard';
import AccessDenied from './components/template/AccessDenied/AccessDenied';
import Authentication from './components/Auth';
import  OAuthLogin   from './components/OAuthLogin'
function App() {
    if (window.location.href.toLowerCase().indexOf("localhost:3000") > 0) {
      // import('./components/template/styles/themes/default.scss');
       import('./components/template/styles/themes/om.scss');
    } else if (window.location.href.toLowerCase().indexOf("oml.cxms.co.za") > 0) {
        import('./components/template/styles/themes/om.scss');
    } else {
        import('./components/template/styles/themes/default.scss');
    }
    return (

        <Routes>
            <Route path="/" element={<TenantSelector />} />
            <Route path="/:tId" element={<Authentication><Index /></Authentication>} />
            <Route path="/:tId/Workflow/:eId" element={<Authentication><PoppedWizard Popped={true} FullWidth={false} /></Authentication>} />
            <Route path="/auth/callback" element={<OAuthLogin />} />
            <Route path="/unauthorised" element={<AccessDenied />} />
        </Routes>


    )
}

export default App;
