import { getPreference, iPreference, savePrefence } from "../../interfaces/preference";
import { persistOnResize } from "../BurgerMenu";

var m_pos:any = 0;
function resize(e:any){
    var parent = document.getElementById("mainPane")!;
    var leftP = document.getElementById("leftPane")!;
    var rightP= document.getElementById("rightPane")!;
    m_pos = e.x;
    const parentWidth = parent.offsetWidth;
    const parentPos = parent.offsetLeft;
    const dragDist = m_pos-parentPos
    const percent = dragDist*1./parentWidth
    const newLeftPWidth = (percent > 0.69 ? 0.69*parentWidth : percent < 0.308 ? 0.308*parentWidth : (percent)*parentWidth)
    persistOnResize(newLeftPWidth)
    leftP.style.width = newLeftPWidth+"px"
    rightP.style.width = (1-percent > 0.69 ? 0.69*parentWidth : 1-percent < 0.308 ? 0.308*parentWidth : (1-percent)*parentWidth)+"px"
}
function changeWidth(e: any){
    var parent = document.getElementById("mainPane")!;
    var leftP = document.getElementById("leftPane")!;
    var rightP= document.getElementById("rightPane")!;
    let parentWidth= parseInt(getComputedStyle(parent, '').width);
    let LeftWidth = parseInt(getComputedStyle(leftP, '').width);
    let rightWidth = parseInt(getComputedStyle(rightP, '').width);
 
     if(  (LeftWidth +rightWidth)  < parentWidth){
        rightWidth += (parentWidth -  (LeftWidth +rightWidth));
    }
    leftP.style.width = ((LeftWidth/parentWidth)*100)  + "%";
    rightP.style.width = ((rightWidth/parentWidth)*100) + "%";
    if(window.innerWidth > 1000 && window.innerWidth< 1600){
        if(rightWidth<310){
            leftP.style.width = "69%";
            rightP.style.width= "30.80%";
        }
    }

    let preference:iPreference =  getPreference();
    preference.interface.LeftPane = ((LeftWidth/parentWidth)*100);
    preference.interface.RightPane =  ((rightWidth/parentWidth)*100)
    leftP.className = "firstColFullWidth"
    rightP.className = "secondCol"
    savePrefence(preference);
    document.removeEventListener("mouseup", changeWidth, false);
    document.removeEventListener("mousedown", changeWidth, false);
    document.removeEventListener("mousemove", resize, false);

}
export const startDrag =   (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    var a = "";
    var leftP = document.getElementById("leftPane")!;
    var rightP= document.getElementById("rightPane")!;
    
    leftP.className = "firstColFullWidth disableSelection"
    rightP.className = "secondCol disableSelection"
        m_pos = e.pageX;
     //   e.preventDefault();
        document.addEventListener("mousemove", resize, false);
     
        document.addEventListener("mouseup",  changeWidth, false);
    

}