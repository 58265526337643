import { EStepKey } from "../../Logic/Wokflow/stepLogic";
import { WizardStepProps } from "./stepType";
import Step from './template'
import FormNumberRow from '../../components/Generic/Form/Numeric/';
import { isValidWorkItem } from "./enquirerDetails";
import { WizardContext } from "../../contexts/wizard-context";
import React from "react";


function FinishStep(props: WizardStepProps) {
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));

    const handleWorkItemsChange = (newValue: string) => {
        wizard.workflow.data.EnquirerDetails = {
            ...wizard.workflow.data.EnquirerDetails,
            WorkItems: isValidWorkItem(+newValue) ? +newValue : 1
        };
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.EnquirerDetails.WorkItems = isValidWorkItem(+newValue) ? +newValue : 1
          return currentState;
        }) ;
 
    }

    
 
    return (
        <>
            <Step
                step={EStepKey.WIZARD_SUMMARY_STEP}
                title={"Finished"}
                Enquiry={props.Enquiry}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
                backF={onBack}
            >
                <div className="py-2">The Enquiry Wizard will:</div>
                <ol className="px-3 my-0">{
                    wizard
                        .workflow
                        .history
                        .map((step, i) => {
                            if (step.summary.length > 0) {
                                return step.summary.map(s => <li className="py-2">{s}</li>)
                            }
                        })
                }</ol>
                <div className="py-2 mb-3">Click the 'Finish' button to action the above or the 'Back' button to make changes.</div>
                <div className="my-2" style={{ height: "1px", background: "#CFCFCF" }}></div>
                <div>Productivity Figures:</div>
                <div className="my-2 d-flex align-items-center">
                    <div style={{paddingRight:"15px", marginBottom: "8px"}}>Work Items earned for this Wizard walkthrough:</div>
                    <FormNumberRow
                        label=''
                        value={wizard.workflow.data.EnquirerDetails.WorkItems}
                        isNotValid={!isValidWorkItem(wizard.workflow.data.EnquirerDetails.WorkItems)}
                        onChange={(newValue) => handleWorkItemsChange(newValue)}
                        key={"mis-field-Items"}
                        firstCol={0}
                        id={"15"}
                        min={1}
                        max={1000}
                    />
                </div>
            </Step>
        </>
    )
}

export default FinishStep;