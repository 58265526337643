import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import './email.scss';
import { BsExclamationLg, BsArrowDown, BsDownload, BsChevronDoubleUp } from "react-icons/bs";
import MainAttachment from './mainAttachmentItem';
import { iGenericComm } from '../../interfaces/iGenericComm';
import CommIcon from '../Enquiries/communication/commIcon';
import { getAllAttachments } from '../../Logic/GenericComms/CommParts';
import { getEnquiryById } from '../../interfaces/enquiryList';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../state/store';
import { setEmailVisible } from '../../state/secondColSlice';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { getLastCommunicationPosition, setLastCommunication } from '../../Logic/LastTouched/comm';
import { dateTimeFormat222 } from '../Enquiries/enquiry/enquiry';
import { handleInsideClick } from '../../hooks/useOutsideClick';


type previewProps = {
    comm: iGenericComm,
    FullWidth: boolean,
    enquirerName: string,
    activeEnquiryId:number,
 
}

function Preview(props: previewProps) {
    const frameRef = React.useRef<HTMLIFrameElement>(null)
    const [frameHeight, setFrameHeight] = useState("57px") 
    const [showAll, setShowAll] = useState(false)
    const [body, setBody] = useState("");
    const [attachments, setAttachments] = useState <Array<{ id: string; name: string; mimeType: string; size: number; }>>([]);
    const dispatch: React.Dispatch<any> = useAppDispatch();


    useEffect(()=>{
        (async function callingFunc() {
                setBody(await props.comm.getBody());
                setAttachments(await props.comm.getAttachments());
        })();
       
    },[props.comm.id])

    let MainAttachmentList;
    let sumValues: number = 0;
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // on update
    useEffect(() => {
        return function cleanup() {
            setShowAll(false)
        }
    }, [attachments])


    useEffect(() => {
		const enqHolder = document.getElementById("enquiryHolder");
        const enquiryH =  enqHolder ? parseInt(getComputedStyle(enqHolder, '').height) : 0;
        const commH= parseInt(getComputedStyle(document.getElementById("previewMetaElements")!, '').height);
        let comPreview = document.getElementById("bodyRow")!;
        //let frmPreview = document.getElementById("emailBodyiFrame")!;
        comPreview.style.height= window.innerHeight - (enquiryH + commH +169) +"px";
        window.setTimeout(()=>{
            comPreview.scrollTop = getLastCommunicationPosition(props.activeEnquiryId? props.activeEnquiryId:0) //+ 200;
            comPreview.scrollLeft = 0;
        },50)
       // frmPreview.style.height= window.innerHeight - (enquiryH + commH +70  ) +"px";
       // frmPreview.style.overflow = "auto";
        //comPreview.style.height= "1500px";
    })
    



    if (attachments.length > 0) {
        attachments.forEach((item) => { sumValues = sumValues + item.size });


        MainAttachmentList = attachments.map(
            (item) =>
                <MainAttachment
                    id={item.id}
                    name={item.name}
                    mimeType={item.mimeType}
                    size={item.size}
                    key={"mainAttachment11_" + item.id}
                />

        );
    }


    sumValues = (sumValues / 1000)
    let attachmentSizeIndicater: string = "KB"
    if (sumValues > 999.99) {
        sumValues = (sumValues / 1000);
        attachmentSizeIndicater = "MB";
    }
    function closePops() {
        closeAttach();
    }
    function closeAttach() {
        var allElements = document.querySelectorAll('*[id^="mAttach-"]');
        allElements.forEach((item) => { item.className = "MainAttachmentOptionsContainer invisible" });
    }

    function resizeIframe() {
        let ifrm = frameRef.current!;
        if (frameRef != null) {
           // let ifrm1 = document.getElementById('bodyRow') as HTMLElement;
            ifrm.style.height = ifrm.contentDocument?.body.clientHeight! + 50 + "px";
            ifrm.style.width="100%" // set the frame to the available width;
            if(ifrm.contentDocument?.body.scrollWidth){
                if(ifrm.scrollWidth < ifrm.contentDocument?.body.scrollWidth) // check if the content of the email is wider than the iframe then set the frame width to the width of the content
                {
                    ifrm.style.width = ifrm.contentDocument?.body.scrollWidth + "px";
                }
            }
        }
    }
    let timer:any = null;
function saveScrollPos(){
    if(timer !== null) {
        clearTimeout(timer);        
    }
    timer = setTimeout(function() { 
        setLastCommunication( (props.activeEnquiryId? props.activeEnquiryId:0),(props.comm? props.comm.id:0), document.getElementById("bodyRow")?.scrollTop!)
    }, 150);
}

    return (
        <Fragment >
            <div className='email-preview' style={{ marginLeft: props.FullWidth ? "4px" : "5px" }} onClick={() => { closePops() }}>
                <div className="email-preview--details">
                    <div id="previewMetaElements">
                            <div className="email-preview--details--subject-row">
                                <div className='email-preview--details--subject-row--subject' data-testid="testSubject">{props.comm.subject}</div>
                                <CommIcon className='icon' type={props.comm.type} unread={props.comm.unread} inBound={props.comm.incoming} />
                            </div>
                            <div className='email-preview--details--addresses-row'>
                                <div className="profile" title={"From: " + props.comm.from}>
                                    {userInitials(props.comm.from)}
                                </div>
                                <div className="from-to-cc">
                                    <div className='from' data-testid="testSenderName">{props.comm.from}
                                        <div className='email-preview--details--subject-row--date' style={{ fontSize: "7pt", float: 'right' }}>{weekday[new Date(props.comm.date).getDay()]} {dateTimeFormat222(props.comm.date)}</div>
                                    </div>
                                    <div className="to-cc">
                                        <div className="to">
                                            <div className='label to-label'>To:</div>
                                            <div className='to-addr' data-testid="testtoAddress">{props.comm.to}</div>
                                        </div>
                                        {props.comm.cc && <div className="cc">
                                            <div className='label cc-label'>Cc:</div>
                                            <div className='cc-addr' data-testid="testccAddress">{props.comm.cc}</div>
                                        </div>}

                                    </div>
                                </div>
                            </div>
                            <div className="email-preview--details--priority-row"> {
                                props.comm.priority === 1 ? <BsExclamationLg className="exclamation" /> :
                                props.comm.priority === 2 ? <BsExclamationLg className="exclamation" /> :
                                props.comm.priority === 4 ? <BsArrowDown className="arrow" /> :
                                props.comm.priority === 5 ? <BsArrowDown className="arrow" /> :
                                <Fragment/>
                            }
                                <div className='label'> {
                                    props.comm.priority === 1 ? "Highest Importance" :
                                    props.comm.priority === 2 ? "High Importance" :
                                    props.comm.priority === 4 ? "Low Importance" :
                                    props.comm.priority === 5 ? "Lowest Importance" :
                                    <Fragment/>
                                }
                                </div>
                            
                            </div>
                            {attachments?.length > 0 && <div className="email-preview--details--attachments-row">
                                <div className="attachments">
                                    {showAll ? MainAttachmentList : MainAttachmentList?.slice(0, 4)}
                                </div>
                                {/* <br style={{ clear: "both", height: "auto" }} className={e.attachments.length > 1 ? "" : "invisible"}></br> */}
                                {attachments?.length > 1 && attachments?.length <= 4 ?
                                    <div className="attachment-actions less-4">
                                        <div className="total">
                                            {`${attachments?.length} attachments (${(sumValues).toFixed(2)} ${attachmentSizeIndicater})`}
                                        </div>
                                        <div
                                            onClick={() => {
                                                getAllAttachments(attachments, "multiple");
                                            }}
                                            className="download-all"
                                        >
                                            <i><BsDownload></BsDownload></i>
                                            <div className="download-label">Download All</div>
                                        </div>
                                    </div> : (
                                        attachments.length > 4 ?
                                            <div className="attachment-actions more-4">
                                                <div className="total" onClick={() => setShowAll(!showAll)}>
                                                    <i><BsChevronDoubleUp className={`double-chevron${showAll ? " flipped" : ""}`} /></i>
                                                    {`${!showAll ? "Show all " : ""}${attachments?.length} attachments (${(sumValues).toFixed(2)} ${attachmentSizeIndicater})`}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        getAllAttachments(attachments, "multiple");
                                                    }}
                                                    className="download-all"
                                                >
                                                    <i><BsDownload></BsDownload></i>
                                                    <div className="download-label">Download All</div>
                                                </div>
                                            </div> : []
                                    )}
                            </div>}

                            {/* <div className="email-preview--details--body-row" id="bodyRow">
                                <iframe className='iframe' srcDoc={e.body} id="emailBodyiFrame" onLoad={() => { resizeIframe() }}/>
                            </div> */}
                    </div>
                    <div className="email-preview--details--body-row" id="bodyRow" onScrollCapture={()=> {saveScrollPos()}}>
                        <iframe
                            id="emailBodyiFrame"
                            sandbox='allow-same-origin'
                            srcDoc={body}
                            data-testid="emailBodyiFrame1"
                            scrolling='no'
                            onLoad={(e) => {
                                resizeIframe();
                                // @ts-ignore
                                const iframeElt: HTMLIFrameElement = e.target;
                                const iframeDoc = iframeElt.contentDocument || iframeElt.contentWindow?.document;
                                iframeDoc?.addEventListener("mousedown", handleInsideClick);
                            }}
                            ref={frameRef}></iframe>

                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export function removeParentheses(name: string): string {

    const openParenIndex = name? name.indexOf('(') : -1;
    if (openParenIndex == -1) {
        return name;
    }

    let closeParenIndex = openParenIndex;
    let parenthesesCount = 1;

    while (parenthesesCount > 0 && closeParenIndex < name.length - 1) {
        closeParenIndex++;

        if (name.charAt(closeParenIndex) == '(') {
            parenthesesCount++;
        } else if (name.charAt(closeParenIndex) == ')') {
            parenthesesCount--;
        }
    }

    if (parenthesesCount != 0) {
        return name;
    }

    const newName = name.slice(0, openParenIndex) + name.slice(closeParenIndex + 1);
    return removeParentheses(newName.trim()); // rec
}

export const userInitials = (name: string) => {
    const nameStripped = removeParentheses(name)

    const nameSur = nameStripped? nameStripped.split(" ") : "  ";
    return nameSur[0].charAt(0).toUpperCase() + nameSur[nameSur.length - 1].charAt(0).toUpperCase()
}

export default Preview;


