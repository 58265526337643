import Tag from "./Tag";
import SmartTag from "./SmartTag";
import StandardTag from "./StandardTag";
import TextReader from "./TextReader";
import SkinTag from "./SkinTag";
import MisTag from "./MisTag";
import ParsedTags from "./ParsedTags";

export default function ParseTags(text: string): ParsedTags {
    const reader = new TextReader(text);

    const tags: ParsedTags = {
        FontTags: new Array<Tag>(),
        CursorTags: new Array<Tag>(),
        BodyTags: new Array<Tag>(),
        IncludeTags: new Array<Tag>(),
        EmbedTags: new Array<Tag>(),
        StandardTag: new Array<StandardTag>(),
        TenantTags: new Array<StandardTag>(),
        SkinTags: new Array<SkinTag>(),
        SmartTags: new Array<SmartTag>(),
        MisTags: new Array<MisTag>(),
        ErrorTags: new Array<Tag>(),
    }
    
    while (reader.Read()) {
        let currentChar = reader.Char;
        if (currentChar === "<" || currentChar === "[") {

            if (!reader.Read())
                continue;
            
            if (reader.Char !== "#")
                continue;

            const startPosition = reader.Position - 1;
            const tag = ParseTag(reader, currentChar);

            tag.StartPosition = startPosition;
            tag.Length = (reader.Position - startPosition) + 1;

            if (tag.Type === "Smart") {
                tags.SmartTags.push(tag as SmartTag);
            } else if (tag.Type === "Skin") {
                tags.SkinTags.push(tag as SkinTag);
            } else if (tag.Type === "MIS") {
                tags.MisTags.push(tag as MisTag);
            } else if (tag.Type === "Standard") {
                MapOtherTag(tags, tag as StandardTag);
            } else if (tag.Type === "Error") {
                tags.ErrorTags.push(tag);
            }
        }
    }

    return tags;
}

const StandardTagSubTypes: Array<string> = [
    "date_received", 
    "date_received_full", 
    "date_sent", 
    "date_sent_full", 
    "date", 
    "time", 
    "date_time", 
    "user", 
    "user_firstname", 
    "user_lastname", 
    "mailbox_address", 
    "mailbox_name", 
    "mailbox_telephone", 
    "mailbox_facsimile", 
    "reference", 
    "enquirer_name", 
    "enquirer_address", 
    "enquiry_subject", 
    "user_telephone", 
    "user_facsimile", 
    "user_mobile", 
    "user_address"
];

function MapOtherTag(tags: ParsedTags, tag: StandardTag)
{
    if (tag.Subtype === undefined || tag.Subtype === null || tag.Subtype === "") {
        tags.ErrorTags.push(tag);
    } else if (tag.Subtype.toLocaleLowerCase() === "font") {
        tags.FontTags.push(tag);
    } else if (tag.Subtype.toLocaleLowerCase() === "cursor") {
        tags.CursorTags.push(tag);
    } else if (tag.Subtype.toLocaleLowerCase() === "body") {
        tags.BodyTags.push(tag);
    } else if (tag.Subtype.toLocaleLowerCase() === "include") {
        tags.IncludeTags.push(tag);
    } else if (tag.Subtype.toLocaleLowerCase() === "embed") {
        tags.EmbedTags.push(tag);
    } else if (StandardTagSubTypes.find(x => x === tag.Subtype.toLocaleLowerCase())) {
        tags.StandardTag.push(tag);
    }
    else {
        tags.TenantTags.push(tag);
    }

}

function ParseTag(reader: TextReader, openChar: string) : SmartTag | StandardTag | Tag {
    
    const closeChar = openChar === "[" ? "]" : ">";
    let tagText = "";

    let inQuote = false;
    while (reader.Read()) {
        if (reader.Char === '"')
            inQuote = !inQuote;
        
        if (!inQuote && reader.Char === closeChar)
            break;

            tagText += reader.Char;
    }

    try {
        if (tagText.toLowerCase().startsWith("tag ")) {
            return ParseSmartTag(tagText);
        } else if (tagText.toLowerCase().startsWith("skin_key ")) {
            return ParseSkinTag(tagText);
        } else if (tagText.toLowerCase().startsWith("mis_value ")) {
            return ParseMISTag(tagText);
        } else {
            return ParseStandardTag(tagText);
        }
        
    } catch(error) {
        return {
            Type: "Error",
            Value: "***INVALIDTAG***",
            StartPosition: 0,
            Length: 0,
        }
    }

}

function ParseSmartTag(tagText: string) : SmartTag {
    const reader = new TextReader(tagText);
    reader.Position = 3;

    const result: SmartTag = {
        Type: "Smart",
        Value: "",
        StartPosition: 0,
        Length: 0,
        ID: "",
        Caption: "",
        Subtype: undefined,
    };
    
    let currentProp = "";
    let currentValue = "";

    let hasEquals = false;

    while(reader.Read()) {

        let newValue: string;

        if (/\s/.test(reader.Char)) {
            continue;
        } else if (reader.Char === "=") {
            if (hasEquals) {
                throw new Error("Invalid Tag");
            }

            hasEquals = true;
            continue;
        } else if (/[a-zA-Z]/.test(reader.Char)) {
            reader.Position = reader.Position - 1;
            newValue = ReadUnquotedValue(reader);
        } else if (reader.Char === '"') {
            newValue = ReadQuotedValue(reader);
        } else {
            throw new Error("Invalid Tag");
        }

        if (!hasEquals) {
            currentProp = newValue.toLowerCase();
            continue;
        }

        currentValue = newValue;

        if (currentProp === "id") {
            result.ID = currentValue;
        } else if (currentProp === "caption") {
            result.Caption = currentValue;
        } else if (currentProp === "value") {
            result.Value = currentValue;
        } else if (currentProp === "type") {
            switch (currentValue.toLowerCase().trim()) {
                case "text":
                    result.Subtype = "text";
                    break;
                case "numeric":
                    result.Subtype = "numeric";
                    break;
                case "currency":
                    result.Subtype = "currency";
                    break;
                case "dropdown":
                    result.Subtype = "dropdown";
                    break;
                case "date":
                    result.Subtype = "date";
                    break;
                case "time":
                    result.Subtype = "time";
                    break;
                case "datetime":
                    result.Subtype = "datetime";
                    break;
                default:
                    throw new Error("Invalid SmartTag type");
            }
        } else if (currentProp.startsWith("value") && !isNaN(parseInt(currentProp.slice(5)))) {
            if (result.options === undefined)
                result.options = [];
            
            result.options.push(currentValue);
        }

        currentProp = "";
        currentValue = "";
        hasEquals = false;
    }

    if (
        result.Subtype === "dropdown" &&
        result.Value !== "" &&
        result.Value !== undefined &&
        result.Value !== null &&
        result.options?.findIndex(x => x.toLowerCase() === result.Value.toLowerCase()) === -1
    ) {
        if (result.options === undefined)
            result.options = [];
        
        result.options = [result.Value, ...result.options!];
    }

    return result;
}

function ParseStandardTag(tagText: string) : StandardTag {
    const reader = new TextReader(tagText);

    const result: StandardTag = {
        Type: "Standard",
        Value: "",
        StartPosition: 0,
        Length: 0,
        Subtype: "",
    };
    
    while(reader.Read()) {
        if (/\s/.test(reader.Char)) {
            continue;
        } else if (/[a-zA-Z]/.test(reader.Char)) {
            reader.Position = reader.Position - 1;
            result.Subtype = ReadUnquotedValue(reader).toLowerCase();
            break;
        } else {
            throw new Error("Invalid Tag");
        }
    }

    return result;
}

function ParseSkinTag(tagText: string) : SkinTag {
    const reader = new TextReader(tagText);
    reader.Position = 8;

    const result: SkinTag = {
        Type: "Skin",
        Value: "",
        StartPosition: 0,
        Length: 0,
        SkinID: 0,
    };
    
    while(reader.Read()) {
        if (/\s/.test(reader.Char)) {
            continue;
        } else if (/[0-9]/.test(reader.Char)) {
            reader.Position = reader.Position - 1;
            result.Value = ReadUnquotedValue(reader);
            break;
        } else {
            throw new Error("Invalid SkinTag");
        }
    }

    let skinID = parseInt(result.Value);
    if (isNaN(skinID) || skinID < 1)
        throw new Error("Invalid SkinTag");

    result.SkinID = skinID;
    result.Value = "";

    return result;
}

function ParseMISTag(tagText: string) : MisTag {
    const reader = new TextReader(tagText);
    reader.Position = 9;

    const result: MisTag = {
        Type: "MIS",
        Value: "",
        StartPosition: 0,
        Length: 0,
        FieldID: 0,
    };
    
    while(reader.Read()) {
        if (/\s/.test(reader.Char)) {
            continue;
        } else if (/[0-9]/.test(reader.Char)) {
            reader.Position = reader.Position - 1;
            result.Value = ReadUnquotedValue(reader);
            break;
        } else {
            throw new Error("Invalid MisTag");
        }
    }

    let fieldID = parseInt(result.Value);
    if (isNaN(fieldID) || fieldID < 1)
        throw new Error("Invalid MisTag");

    result.FieldID = fieldID;
    result.Value = "";

    return result;
}

function ReadQuotedValue(reader: TextReader) : string {
    let text = "";

    while (reader.Read()) {
        if (reader.Char === '"')
            break;

        text += reader.Char;
    }

    return text;
}

function ReadUnquotedValue(reader: TextReader) : string {
    let text = "";

    while (reader.Read()) {
        if (/\s/.test(reader.Char)) {
            break;
        } else if (/[a-zA-Z0-9_]/.test(reader.Char)) {
            text += reader.Char;
        } else if (reader.Char === "=") {
            reader.Position = reader.Position - 1;
            break;
        } else {
            throw new Error("Invalid Tag");
        }
    }

    return text;
}