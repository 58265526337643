import { useRef, useState } from 'react';
import './index.scss';
import React from 'react';
import { PillElement } from './PillElement';
import { iPill } from '../../../../interfaces/Pill';
import { EKeyboardKeys } from '../../../../constants/KeyboardKeys';
import { isEmail } from '../../../../interfaces/emailModel';
import parse from '../../../../service/email-parser/email-parser';
import { ContactList } from './ContactsDropdown';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { iContact, iContactData } from '../../../../interfaces/Contact';
import iEmailAddress from '../../../../interfaces/iEmailAddress';
import { getResults } from './contacts.helper';

interface Props {
    label: string,
    value: string,
    values?: iEmailAddress[],
    onChange: (newValue: string) => void,
    onChangeValues: (newValues: iEmailAddress[]) => void,
    setFocus?: boolean,
    isNotValid?: boolean,
    internalButton?: {
        label: string,
        onClick: () => void
    },
    allContacts: iContactData[]
}

export function EmailDropdownInputWithPills(props: Props) {
    const [fieldValue, setFieldValue] = useState(props.value);
    const [isFocused, setIsFocused] = useState(props.setFocus ?? false);
    const [pills, setPills] = useState<iPill[]>([]);
    const [contactShown, setContactShown] = useState(false);
    const [searchResults, setSearchResults] = useState<iContact[]>([]);

    const emailControllRef = useRef<HTMLInputElement>(null)

    const dropdownRef = useRef<HTMLDivElement>(null)
    useOutsideClick(dropdownRef, () => {
        addNewPill(fieldValue);
        setContactShown(false);
    });

    React.useEffect(() => {
        setSearchResults(
            getResults(fieldValue, props.allContacts, 5)
                .map((ct, i) => (
                    {
                        ...ct,
                        id: `search-contact-result-element-${i}`
                    }
                ))
        );
    }, [fieldValue])

    React.useEffect(() => {
        setPills(
            props
                .values
                ?.map((pd, i) => ({ ...pd, id: `pill-element-${i}` })) ??
            []
        );
    }, [props.values]);

    const handleContactClick = (id: string) => {
        const clickedContact: iContact = searchResults.find(ct => {
            return ct.id === id;
        })!;
        hardAddNewPill(clickedContact.address, clickedContact.name);
        setContactShown(false);
    }

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value.trim();

        // onPaste with multiple emails, capture all or none
        const emailsParsed: iEmailAddress[] = parse(newValue); // ignored groups
        const validParsedEmails: iEmailAddress[] = emailsParsed.filter(ep => isEmail(ep.address))
        if (emailsParsed.length > 1 && emailsParsed.length === validParsedEmails.length) {
            const validEmailPills: iPill[] =
                validParsedEmails
                    .map((em, i) => {
                        return {
                            name: em.name,
                            address: em.address.trim(),
                            id: `pill-element-${i + pills.length}`
                        }
                    });

            const newPills: iPill[] = [...pills, ...validEmailPills];
            setPills(newPills);
            props.onChangeValues(newPills);
            setFieldValue("");
            props.onChange("");
            return;
        }

        // on type or there is/are pasted value/s that failed validation
        setFieldValue(newValue);
        props.onChange(newValue);
    }

    const handleUnfocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false);
    }

    const handlePillClose = (id: string) => {
        const newPills = pills.filter(p => p.id !== id);
        setPills(newPills);
        props.onChangeValues(newPills);
    }
    const closeLastPill = () => {
        const newPills: iPill[] = JSON.parse(JSON.stringify(pills));
        newPills.pop();
        setPills(newPills);
        props.onChangeValues(newPills);
    }

    const addNewPill = (address: string, name?: string) => {
        const valid = isEmail(address);
        if (valid) {
            hardAddNewPill(address, name);
        }
    }

    const hardAddNewPill = (address: string, name?: string) => {
        const newPills: iPill[] = JSON.parse(JSON.stringify(pills));
        newPills.push({ address, name, id: `pill-element-${newPills.length}` });
        setPills(newPills);
        props.onChangeValues(newPills);
        setFieldValue("");
        props.onChange("");
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.code;

        if (!fieldValue) {
            if (key === EKeyboardKeys.BACKSPACE) {
                closeLastPill();
            }
        }
        else {
            if (
                key === EKeyboardKeys.ENTER ||
                key === EKeyboardKeys.ENTER_NUMPAD ||
                key === EKeyboardKeys.SPACE ||
                key === EKeyboardKeys.COMMA ||
                key === EKeyboardKeys.SEMICOLON
            ) {
                addNewPill(fieldValue);
            }
        }
    }

    return (
        <div className="d-flex">
            {props.label && <button disabled className='g-btn-disabled pills-input-label limit-text'>
                {props.label}
            </button>}
            <div
                className="pills-input-with-dropdown"
                ref={dropdownRef}
            >
                <div className={`pills-input${isFocused ? " focused" : ""}${props.isNotValid ? " invalid" : ""}`}>
                    {pills.length > 0 && <div className="pills-list">
                        {pills.map((v, i) => <PillElement
                            key={`pill-element-${i}`}
                            {...v}
                            onClick={handlePillClose}
                        />)}
                    </div>}


                    <input
                        value={fieldValue}
                        className='pills-input-input'
                        type="text"
                        ref={emailControllRef}
                        onChange={handleInputChange}

                        onFocus={() => {
                            setContactShown(true);
                            setIsFocused(true);
                        }}
                        onBlur={handleUnfocus}
                        onKeyDown={handleKeyPress}
                        autoComplete="off"
                        aria-autocomplete="list"
                        aria-controls="autocomplete-list"
                    />

                    {props.internalButton && <button onClick={props.internalButton.onClick} className='pills-input-inner-label limit-text'>
                        {props.internalButton.label}
                    </button>}
                </div>

                {contactShown && <ContactList
                    onContactClick={handleContactClick}
                    constacts={searchResults}
                />}
            </div>
        </div>
    );
}