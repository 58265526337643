import { createSlice } from '@reduxjs/toolkit'
export interface iSiteConnection {
connected:boolean
}

const initialState: iSiteConnection = {
    connected:true
}
export const Connection = createSlice({
    name: 'Connection',
    initialState,
    reducers: {
      
      
        changeConnection: (state,action) => {
            state.connected =action.payload;
            
        } 

    }
})


// Action creators are generated for each case reducer function
export const { changeConnection } = Connection.actions

export default Connection;