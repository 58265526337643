import * as React from 'react';
import DataTable, { dataColumn } from '../Generic/DataTable';
import { iEnquiry } from '../../interfaces/iEnquiry';
import { ActivateWizardModule } from '../../Logic/Enquiries/activateWizard';
import { useContextMenu } from '../../hooks/useContextMenu';
import Statuses from '../../interfaces/Statuses';
import { sortByField, SortEnquiriesParams, sortOwner, sortStatus } from '../../utilities/sort';
import { useEffect, useState } from 'react';
import { saveSessionValue } from '../../utilities/localStore/calls';
import { GlobalModalContext } from '../../contexts/GlobalModalContext';
import { BsPaperclip, BsThermometerHalf } from 'react-icons/bs';
import { Bell } from 'react-feather';
import { dateTimeFormat222 } from './enquiry/enquiry';
import { EnquiryContextMenu } from '../Menu';
import { getLastTouched, saveLastTouched } from '../../Logic/LastTouched/misc';
export interface gridProps {
    data:iEnquiry[],
    theme: string,
    selectedEnquiry: number,
    columns: string[],
    sortFunction: (column:string)=>{}
    sortCol:SortEnquiriesParams| undefined
}
function EnquiryGrid(props: gridProps) {
    //const [allEnquiries, setAllenquiries] = useState<Array<iEnquiry>>(props.data);
    //const [sortBy, setSortBy] = React.useState<SortEnquiriesParams>();
    let AllColumns:dataColumn<iEnquiry>[] =[
        {
            title: <>&nbsp;</>,
            key:"verticalSPacer",
            getData: () => {
                return <div className="verticalBorder">
                    <div style={{ height: "40px", width: "8px", marginTop: "-20px", marginBottom: "-40px", float: "left", marginLeft: "-6px" }} >&nbsp;</div>
                </div>
            },
            width: "10px",
            canResize: false,
            sort: undefined,
            textAlignment: "left",
            minWidth: "10px"
        },
        {
            title: <BsThermometerHalf></BsThermometerHalf>,
            toolTip:"Priority",
            key:"priority",
            getData: (data:iEnquiry) => {
                return <div className="sla-priority-bar" style={{marginLeft:"2px"}}>
                    <div style={{ height: ((255 - data.priority) / 255.0) * 100.0 + "%" }} className="sla-priority--value">&nbsp;</div>
                </div>
            },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("priority") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <Bell size={12}></Bell>,
            key:"hasAlarm",
            toolTip:"Alarms",
            getData: (data:iEnquiry) => { return data.hasAlarm == true ? <Bell size={12}></Bell> : "" },
            width: "18px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasAlarm") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "15px"
        },
        {
            title: <BsPaperclip></BsPaperclip>,
            key:"hasUnworkedAttachments",
            toolTip:"Unworked Attachments",
            getData: (data:iEnquiry) => { return data.hasUnworkedAttachments === true ? <BsPaperclip></BsPaperclip> : "" },
            width: "20px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("hasUnworkedAttachments") },
                displayIcon: false
            },
            textAlignment: "left",
            minWidth: "10px"
        },

        {
            title: <>Reference</>,
            key:"enquiryReference",
            toolTip:"Reference",
            getData: (data:iEnquiry) => {
                return <span className="enquiryReference">{data.enquiryReference}</span>
            },
            width: "130px",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquiryReference") },
                displayIcon: true
            },
            textAlignment: "left",
           minWidth: "90px"
        },
        {
            title: <>Owner</>,
            key:"owner",
            toolTip:"Owner",
            getData: (data:iEnquiry) => { return data.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ') },
            width: "20%",
            
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("owner") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Enquirer</>,
            key:"enquirerName",
            toolTip:"Enquirer",
            getData: (data:iEnquiry) => { return data.enquirerName },
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("enquirerName") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Received</>,
            key:"dateReceived",
            toolTip:"Received",
            getData: (data:iEnquiry) => { return dateTimeFormat222(data.dateReceived )},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("dateReceived") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Expiry</>,
            key:"activeSlaCycle.timeout",
            toolTip:"Expiry",
            getData: (data:iEnquiry) => { return dateTimeFormat222(data.activeSlaCycle?.timeout? data.activeSlaCycle?.timeout :"")},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        }, 
   
        {
            title: <>Status</>,
            key:"status",
            toolTip:"Status",
            getData: (data:iEnquiry) => { return Statuses.Name(data.status) },
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("status") },
                displayIcon: true
            },
            textAlignment: "left",
          minWidth: "80px"
        },
        {
            title: <>Subject</>,
            key:"subject",
            toolTip:"Subject",
            getData: (data:iEnquiry) => { return data.subject},
            width: "35%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("subject") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
       
        {
            title: <>Completed</>,
            key:"dateCompleted",
            toolTip:"Completed",
            getData: (data:iEnquiry) => { return  data.dateCompleted?  dateTimeFormat222(data.dateCompleted):""},
            width: "20%",
            canResize: true,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("activeSlaCycle.timeout") },
                displayIcon: true
            },
            textAlignment: "left",
            minWidth: "80px"
        },
        {
            title: <>Items</>,
            key:"workItems",
            toolTip:"Items",
            getData: (data:iEnquiry) => { return data.workItems },
            width: "50px",
            canResize: false,
            sort: {
                direction: undefined,
                sortMethod: () => { props.sortFunction("workItems") },
                displayIcon: false
            },
            textAlignment: "right",
            minWidth: "50px",
            maxWidth: "50px"
        },
    ]
 
    AllColumns = AllColumns.filter(col => props.columns.find(propCol => propCol === col.key));
    for (let col of AllColumns) {
        if(props.sortCol && props.sortCol.orderBy == col.key)    {
            if(col.sort){
                col.sort.direction = props.sortCol?.desc ? "desc" : "asc"
            }
        }
    }

    const showModal = React.useContext(GlobalModalContext);
    const { contextMenuState, setContextMenuState, closeContextMenu } = useContextMenu();
    const showDataTableContextMenu = (data: string, x: number, y: number) => {
        closeContextMenu();
        setContextMenuState({ data: data, visible: true, x: x, y: y })
    }

    const keydownHandle = (event: React.KeyboardEvent<HTMLTableRowElement>) => {
        if (event == null)
            return;
        if (event.key != "ArrowUp" && event.key != "ArrowDown")
            return;

        const activeEnquiryIndex = props.data.findIndex(x => x.id === props.selectedEnquiry);
        let nextEnquiry = 0;
        let nextEnquiryIndex;
        if (event.key == "ArrowUp") {
            nextEnquiryIndex = activeEnquiryIndex > 0 ? activeEnquiryIndex - 1 : activeEnquiryIndex;
        }
        else {
            nextEnquiryIndex = activeEnquiryIndex < props.data.length - 1 ? activeEnquiryIndex + 1 : activeEnquiryIndex;
        }
        // let listMenuHeaderDiv: HTMLElement = document.getElementById('listMenuHeader')!;
        //  listMenuHeaderDiv.scrollTop = nextEnquiryIndex * 10;
        nextEnquiry = props.data[nextEnquiryIndex].id;
        if (props.selectedEnquiry != nextEnquiry) {
            const enqModule = new ActivateWizardModule();
            enqModule.click = 0;
            enqModule.showEnquiry(nextEnquiry);
        }
    }

    const customClassforGrid = (e:iEnquiry) :string => {
        let classes =  getExpiryColour(e) ;
        classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
        return classes;
    };
    const getExpiryColour = (e:iEnquiry):string => {
        if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
            let reminderDate = e.activeSlaCycle.reminder? new Date( e.activeSlaCycle?.reminder) : "";
            let alarmDate = e.activeSlaCycle.alarm ? new Date(e.activeSlaCycle?.alarm) : "";
            let timeoutDate = e.activeSlaCycle.timeout ? new Date(e.activeSlaCycle?.timeout) : "";
            const currentDate = new Date();
            if (currentDate < reminderDate) {
                return " expiry-green"
            }
            else if (currentDate >= reminderDate && currentDate < alarmDate) {
                return " expiry-orange"
            }
            else if (currentDate >= alarmDate && currentDate < timeoutDate) {
                return " expiry-red"
            }
            else if (currentDate > reminderDate) {
                return " expiry-purple"
            }
            else{return "";}
        } else {
            return "";
        }
    }

 
    


    return (
        <>
        {AllColumns.length>0 ?  
        <DataTable data={props.data} theme="dataTable_default"
                    columns={AllColumns} uniqueIdentifiyer={(x)=>{return x.id.toString()}} 
                    onRowClick={(x: string[]) =>{
                        const enqM = new ActivateWizardModule();
                        if (x.length == 1) {
                            enqM.showEnquiry(parseInt(x[0]));
                        }
                    }
                    } 
                    onRowDblClick={(x: string[]) =>{
                        if (x.length == 1) {
                            const enqM = new ActivateWizardModule();
                            enqM.ActivateWizard(parseInt(x[0]), () => showModal({ type: "EnquiryLocked" }));
                        }
                    }}
                    selectedData={[props.selectedEnquiry.toString()]}
                    contextMenu={showDataTableContextMenu} keyPress={keydownHandle}
                    spacerRow={3}  
                    getCustomClass={(e)=>{
                        let classes =  getExpiryColour(e) ;
                        classes += (!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : "";
                        return classes;
                    }}
                ></DataTable> : <></>
    }
    {(contextMenuState.visible) && (
                <EnquiryContextMenu
                    x={contextMenuState.x}
                    y={contextMenuState.y}
                    enquiryId={parseInt(contextMenuState.data)}
                    onHide={closeContextMenu}
                />
            )}
 </>      
    )
};

export default EnquiryGrid;

 