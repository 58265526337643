import './topBar.scss';
import { AdvancedSearchPM } from "../../../interfaces/AdvancedSearch/AdvancedSearchPM";
import { GetSearchResults } from "../../../Logic/Search";
import { setActiveEnquiry } from "../../../state/activeEnquiry";
import { setEmailVisible, setWorkflowVisible } from "../../../state/secondColSlice";
import { setSearchResultsVisibility } from "../../../state/siteSlice";
import { useAppDispatch } from "../../../state/store";
import { switchFirstTab } from "../../../state/tabRow";
import { getSessionValue, saveSessionValue } from "../../../utilities/localStore/calls";
import { iLastTouched } from "../../../interfaces/lastTouched";
import AvancedSearch from "../../AdvancedSearch";
import { Col, Row } from "react-bootstrap";
import { BsX } from "react-icons/bs";
import { getLastTouched, saveLastTouched } from '../../../Logic/LastTouched/misc';

export interface AvancedSearchDialogProps {
    onClose: () => void,
    fullWidth: boolean,
}

function AvancedSearchDialog(props: AvancedSearchDialogProps) {
    const dispatch = useAppDispatch();

    const onSearch = async (query: AdvancedSearchPM) => {
        const searchResults = await GetSearchResults(query);
        saveSessionValue("advancedSearchResults", searchResults);


        if (searchResults.Results.length > 0) {
            const iLastTouched = getLastTouched();
            iLastTouched.SearchResults.EnquiryLists[0].orderBy = {
                orderBy: null,
                desc: false
            };

            if (props.fullWidth) {
                iLastTouched.lastActiveListType = "Results";
                iLastTouched.SearchResults.EnquiryLists[0].EnquiryId = searchResults.Results[0];
            }
            
            saveLastTouched(iLastTouched);

            dispatch(setActiveEnquiry(searchResults.Results[0]))
            dispatch(setEmailVisible(true));
            dispatch(setWorkflowVisible(false));
            dispatch(setSearchResultsVisibility());
            dispatch(switchFirstTab("Search Results"));
            props.onClose();

        }
        else {

            dispatch(setActiveEnquiry(-1))
            dispatch(setEmailVisible(false));
            dispatch(setWorkflowVisible(false));
            dispatch(setSearchResultsVisibility());
            dispatch(switchFirstTab("Search Results"));
            props.onClose();
        }
    };

    const persistQuery = (query: AdvancedSearchPM) => {
        saveSessionValue("advancedSearchQuery", query);
    };

    const loadQuery = () => {
        return getSessionValue<AdvancedSearchPM>("advancedSearchQuery")
    };

    return (
        <>
            <div className="offClick" onClick={props.onClose} />
            <div className="advanced-search-dialog">
                <Row>
                    <Col xs={10} className="search-label-col search-title-col"><label className="search-field-label search-title"><b>Search Enquiries</b></label></Col>
                    <Col xs={2} className="search-close-column">{props.onClose !== undefined ? <BsX onClick={props.onClose} /> : <></>}</Col>
                </Row>
                <AvancedSearch onClose={props.onClose} onSearch={onSearch} persistQuery={persistQuery} loadQuery={loadQuery} />
            </div>
        </>
    );
}


export default AvancedSearchDialog;