import iEmailAddress from "../interfaces/iEmailAddress";
import store from "../state/store";
import { Get } from "../utilities/apiCall";
import { addItem, getItem } from "../utilities/localStore/indexDBCalls";

interface CachedItem<T> {
    uId: string,
    updatedAt: string,
    data?: T | undefined
}

export async function GetMyContacts(): Promise<iEmailAddress[]> {
    let user = store.getState().UserInformation;

    return await GetCachedItem<iEmailAddress[]>("user_contacts", user.updatedAt, `Users/${user.userID}/Contacts`);
}

async function GetCachedItem<T>(key: string, lastUpdated: string, fetchUri: string): Promise<T> {
    let cachedData: CachedItem<T> = await getItem("Cache", key);
    
    if (!cachedData) {
        cachedData = {
            uId: key,
            updatedAt: new Date(0).toISOString()
        }
    }

    if (!cachedData.data || new Date(cachedData.updatedAt) < new Date(lastUpdated)) {
        cachedData.updatedAt = lastUpdated;
        cachedData.data = await Get(fetchUri);
    
        await addItem("Cache", cachedData);
    }

    return cachedData.data!;
}