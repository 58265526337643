export function isNullOrWhitespace(input:string|undefined) {
    if (typeof input === 'undefined' || input === null) return true;
    return input.replace(/\s/g, '').length < 1;
}

export function htmlEncode (str:string)
{
   var div = document.createElement('div');
   var text = document.createTextNode(str);
   div.appendChild(text);
   return div.innerHTML;
}
export function IsEmailAddress(str: string):boolean {
    //Check if string contain @ sign 
    if (str.indexOf('@')<0)
        return false;
    //Check for more than 1 @ sign
    var firstoccurrenceATsign = str.indexOf('@');
    var NextoccurrenceATSign = str.lastIndexOf('@')
    if ((firstoccurrenceATsign != NextoccurrenceATSign) && (NextoccurrenceATSign >-1))
        return false;
    //Check if @ sign is the 1st character
    if (firstoccurrenceATsign == 0)
        return false;
    //Check if @ sign is the last character
  if (firstoccurrenceATsign == (str.length-1))
        return false;

    return true;
}

/**
 * Split a delimited string into substrings using the comma, space, semicolon or space separators and return them as an array.
 * @param mixedDelStr The string with combined delimeters i.e. '"," or " " or ";" or any combination with the space'
 * @returns list of substrings spplit by the common delimiters
 * @example splitByAll("Alic, Bob;Charl, Dolly Erik ,Frank,,"); // should return ["Alic", "Bob", "Charl", "Dolly", "Erik" , "Frank"]
 */
export function splitByAll(mixedDelStr: string): string [] {
    const subs: string [] = mixedDelStr.split(/[ ,]+|[ ;]+|[ ]/);
    return subs.filter(s => s.length > 0);
}