import { createSlice } from '@reduxjs/toolkit'
import { DeleteWorkflowInStorage } from './workflowSlice';
import { addConfirm, removeConfirm } from '../utilities/windowConfirm';

export interface iTabs{
    tabs: Array<iTabRow>
}
export interface iTabRow {
   label: string,
   enquiryId: number,
   active: boolean
}

const closeAll = (tabs: Array<iTabRow>) => {

    let testA: Array<iTabRow> = 
    tabs.map((x) => {
        x.active = false;
       
        return x
      });
 
    return testA;
}


const initialState: iTabs = {
    tabs:[
            {
                label: "",
                enquiryId: 10.35,
                active: true    
            }
        ]
}
export const tabRowSlice = createSlice({
    name: 'tabRow',
    initialState,
    reducers: {
        setTabVisibility: (state,action) => {
           state.tabs =  closeAll(state.tabs);
           let newArray = [...state.tabs]
           if(newArray.findIndex(i=> i.enquiryId==action.payload)>-1){
              newArray[newArray.findIndex(i=> i.enquiryId==action.payload)].active = true;
            }
           state.tabs = newArray
        },
        addTab: (state,action) => {
            state.tabs =  closeAll(state.tabs);
            let newArray = [...state.tabs];
            if(newArray.findIndex(i=> i.enquiryId==action.payload.enquiryId)>-1){
                newArray[newArray.findIndex(i=> i.enquiryId==action.payload.enquiryId)].active = true;
            }else{
                newArray.push(action.payload) ;
            }
            state.tabs = newArray;
            if (state.tabs.length > 1) {
                addConfirm();
            }
        },
        removeTab: (state,action) => {
           let activeTabIndex = state.tabs.findIndex(i=> i.active==true);
           let newArray = [...state.tabs];
           if(newArray[activeTabIndex].enquiryId==action.payload)  //means the tab is being closed is the same one that is active.
           {
                newArray = closeAll(state.tabs).filter(x=> x.enquiryId!= action.payload);
                newArray[0].active=true;
           }
            else{
                newArray = newArray.filter(x=> x.enquiryId!= action.payload);
            }
            state.tabs = newArray;
            DeleteWorkflowInStorage(action.payload);
            if (state.tabs.length <= 1) {
                removeConfirm();
            }
        },
        switchFirstTab: (state,action) => {
            state.tabs =  closeAll(state.tabs);
            let newArray = [...state.tabs];
            newArray[newArray.findIndex(x=>x.enquiryId==10.35)].label=action.payload;
            newArray[newArray.findIndex(x=>x.enquiryId==10.35)].active=true;
            state.tabs = newArray;
        },
        // ,
        // activateQueues: (state, action) => {

        // }
    }
})


// Action creators are generated for each case reducer function
export const { setTabVisibility, addTab, removeTab, switchFirstTab} = tabRowSlice.actions

export default tabRowSlice