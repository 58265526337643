import { BsInfoCircleFill, BsExclamationCircleFill } from 'react-icons/bs';
import './index.scss';
interface Props {
    onDismiss: () => void,
    dismissLabel?: string,
    type?: "warn" | "info" | "error",
    children: JSX.Element[] | JSX.Element
}

export function ToastDismissOverlay(props: Props) {

    const getIcon = () => {
        if (!props.type) {
            return <></>
        }
        if (props.type === "info") {
            return <i className="icon">
                <BsInfoCircleFill className="confirm-message-icon" />
            </i>
        }
        if (props.type === "warn") {
            return <i className="icon">
                <BsExclamationCircleFill className="confirm-message-icon" />
            </i>
        }
    }

    return (
        <div className="toast-dismiss-overlay">
            <div className="toast-dismiss notice-priority">
                {getIcon()}
                <div className='toast-dismiss-msg'>{props.children}</div>
                <button
                    className="toast-dismiss-btn"
                    onClick={props.onDismiss}
                >
                    {props.dismissLabel ?? "Dismiss"}
                </button>
            </div>
        </div>
    )
}
