import '../../Email/email.scss';
import { useSelector } from "react-redux";
import { RootState } from '../../../state/store'
import Preview from '../../Email/preview';
import Enquiry from '../../Enquiries/enquiry/enquiry'
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { ToGenericComm } from '../../../interfaces/iGenericComm';
import { useState} from 'react';
import { WizardContext } from '../../../contexts/wizard-context';
import React from 'react';
import { getLocalValue } from '../../../utilities/localStore/calls';
import { iLocalMessage } from '../../../interfaces/emailModel';

type switchProps = {
    FullWidth: boolean,
    enquiry: iEnquiry,
    interface?: string
}


function EnquiryWrapper(props: switchProps) {
     function changeCommToView(commId:number){

        setcommToView(ToGenericComm(
            props.enquiry.communications.find(x=>x.id==commId)!
        ));
    }
    
        const [commToView, setcommToView] = useState(useSelector((state: RootState) => state.commToView));
        if(commToView.id===0 && props.enquiry.communications.length > 0){
            setcommToView(ToGenericComm(props.enquiry.communications[0]));
        }
        const {wizard} = React.useContext(WizardContext);
        let previewComponent = <Preview comm={commToView} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id}/>
        
    
        if (wizard !== undefined) {
            const localAttachments = getLocalValue<iLocalMessage[]>("localAttachments") ?? [];
            let localMessage = localAttachments.find(x => x.communicationId === wizard.lastCommunication?.id)
    
            if (localMessage !== undefined) {
                let comm = ToGenericComm(wizard?.lastCommunication!);
                previewComponent = <Preview comm={comm} FullWidth={props.FullWidth} enquirerName={props.enquiry?.enquirerName} activeEnquiryId={props.enquiry.id}/>
            }
        }

    return (
        <div className='enquiry-history'  >
          
            <div className='enquiry-history--enquiry'>
                <Enquiry
                    key={"key_" + props.enquiry?.id}
                    e={props.enquiry}
                    interface={props.interface}
                    activeCommId={commToView.id}
                    commOnclick={(e:number)=>{changeCommToView(e)}}
                    hasActivateWiz={false}
                />
            </div>
            
            {
            Object.keys(props.enquiry).length>0 ?
                (props.enquiry.communications.length >0?  previewComponent : null) :
                null
        } 
        </div>
    )
}

export default EnquiryWrapper;