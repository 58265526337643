import { createSlice} from '@reduxjs/toolkit'


export interface iQueues {
   data: Array<iQueue>,
   currentList: Array<iQueue>,
   showQueues :boolean,
   selectedMailbox: number
}
export interface iQueue {
    name: string,
    id: number,
    count:number 
}
 
const initialState: iQueues = {
    data:[],
    currentList: [],
    showQueues:false,
    selectedMailbox:0
}
 
export const QueuesSlice = createSlice({
    name: 'QueuesInformation',
    initialState,
    reducers: {
        setQState: (state, action) => {
            state.data=action.payload.data;
            state.showQueues =action.payload.showQueues;
            state.currentList =action.payload.currentList;
            state.selectedMailbox = action.payload.selectedMailbox;
           }
    }  
})

 
// Action creators are generated for each case reducer function
export const {  setQState } = QueuesSlice.actions

export default QueuesSlice