import {
    BsListNested,
    BsMagic,
    BsPeople,
    BsPersonAdd,
    BsSticky,
    BsTrash3,
    BsCardChecklist
} from "react-icons/bs"
import { IconMenuItems } from "."
import { ActivateWizardModule } from "../../Logic/Enquiries/activateWizard"
import { Dispatch, useContext, useEffect, useState } from "react"
import { CreateNoteContext } from "../../contexts/create-note.context"
import { EnquiryMenuProps } from "../../interfaces/menu"
import { ChangeEnquiryOwnershipContext } from "../../contexts/changeEnquiryOwnership.context"
import { RemoveEnquiriesContext } from "../../contexts/removeEnquiries.context"
import { ChangeEnquiryPriorityContext } from "../../contexts/changeEnquiryPriority.context"
import { PriorityIcon } from "../../assets/svg/priority.icon"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../state/store"
import { getEnquiryById, refreshList } from "../../interfaces/enquiryList"
import { GetMailboxPermissions, Permissions as iPermissions } from "../../interfaces/Permissions"
import { iEnquiry } from "../../interfaces/iEnquiry"
import { setActiveEnquiry } from "../../state/activeEnquiry"
import { takeEnquiryOwnership } from "../../Logic/Enquiries/takeEnquiryOwnership"
import Statuses from "../../interfaces/Statuses"
import { ReplaceEnquiryOwnershipContext } from "../../contexts/replaceEnquiryOwnership.context"
import { EnquiryPropertiesContext } from "../../contexts/enquiry-properties.context"

interface  EnquiryMenuPropswithHide extends EnquiryMenuProps{
    onHide:()=> void
}
export function EnquiryMenuItems({ enquiryId, menuStyle,onHide }: EnquiryMenuPropswithHide) {

    const createNote = useContext(CreateNoteContext)
    const changeEnquiryOwnership = useContext(ChangeEnquiryOwnershipContext)
    const removeEnquiries = useContext(RemoveEnquiriesContext)
    const changeEnquiryPriority = useContext(ChangeEnquiryPriorityContext)
    const replaceEnquiryOwnership = useContext(ReplaceEnquiryOwnershipContext)
    const enquiryProperties = useContext(EnquiryPropertiesContext);
    const permissions = useSelector((state: RootState) => state.permissions);
    const [enquiry,setEnquiry] =useState<iEnquiry>();
    const userID = useSelector((state: RootState) => state.UserInformation).userID;
    const dispatch: Dispatch<any> = useAppDispatch();

    useEffect(()=>{
        setEnquiry(getEnquiryById(enquiryId));
    },[enquiryId])

    function canTakeOwnership(): boolean {
        if (!enquiryId || enquiryId < 0)
            return false;
        const mailboxPermissions = GetMailboxPermissions(permissions, enquiry!.mailbox)
        if (enquiry!.owners?.find(o => o.id === userID) !== undefined)
            return false;
        if (mailboxPermissions.ReplaceEnquiryOwnership)
            return true;
        return mailboxPermissions.TakeEnquiryOwnership &&
            enquiry!.owners?.length === 0;
    }

    return (
       !enquiry? <></>:<IconMenuItems options={[
            {
                icon: <i><BsMagic /></i>,
                label: "Activate Wizard",
                onClick: () => new ActivateWizardModule().ActivateWizard(enquiryId),
                disabled: !(new ActivateWizardModule().wizardIdPermitted(enquiryId))
            },
            {
                icon: <i><BsSticky /></i>,
                label: "Create Note",
                onClick: () => {
                    createNote(enquiryId)
                    onHide();
                },
                disabled: !enquiryId || enquiryId <= 0
            },
            {
                icon: <i><BsPersonAdd /></i>,
                label: "Take Ownership",
                onClick: () => {
                    enquiry!.owners.length === 0 ?
                        takeEnquiryOwnership(enquiryId).then(() => {
                            refreshList(userID).then(() => {
                                dispatch(setActiveEnquiry(0));
                            });
                        }) : replaceEnquiryOwnership(enquiry);
                    onHide();
                },
                disabled: !canTakeOwnership()
            },
            {
                icon: <i><BsPeople /></i>,
                label: "Change Enquiry Ownership",
                onClick: () => {
                    changeEnquiryOwnership([enquiry!])
                    onHide()
                },
                disabled: !GetMailboxPermissions(permissions, enquiry!.mailbox).ModifyEnquiryOwnership,
            },
            {
                icon: <i>
                    <PriorityIcon
                        color={enquiry.status !== Statuses.Received.ID || !GetMailboxPermissions(permissions, enquiry!.mailbox).ModifyEnquiryPriority ? "#E6E7E8" : "black"}
                    />
                </i>,
                label: "Change Enquiry Priority",
                onClick: () => {
                    changeEnquiryPriority([enquiry!])
                    onHide()
                },
                disabled: enquiry.status !== Statuses.Received.ID || !GetMailboxPermissions(permissions, enquiry!.mailbox).ModifyEnquiryPriority
            }, 
            { 
                icon: <i><BsTrash3/></i>,
                label: "Remove Enquiry",
                onClick: () => {
                    removeEnquiries([enquiry!])
                    onHide()
                },
                hidden: !GetMailboxPermissions(permissions, enquiry!.mailbox).CloseEnquiry,
                disabled: !(
                    enquiry.status === Statuses.Received.ID && (
                        enquiry.owners.length === 0 || (
                            enquiry.owners.length === 1 &&
                            enquiry.owners[0].id === userID
                        )
                    )
                )
            },
            {
                icon: <i><BsCardChecklist /></i>,
                label: "Enquiry Properties",
                onClick: () => {
                    enquiryProperties(enquiryId);
                    onHide();
                },
                disabled: !enquiryId || enquiryId <= 0
            }
        ]}
        menuStyle={menuStyle ?? "context-menu"}
        />
    )
}
