import { Fragment, useEffect, useState } from 'react';
import { useAppDispatch, RootState } from '../../state/store'
import { useSelector } from "react-redux";
import './login.css';
import { iAuth, iToken } from '../../interfaces/auth'
import getPkce from 'oauth-pkce';
import { getToken, isTenantValid, logout, refreshIDPToken } from '../../Logic/Auth/TokenManager';
import { useNavigate } from 'react-router-dom';
import { getLocalValue, saveLocalValue } from '../../utilities/localStore/calls';
 


function Login() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const User = useSelector((state: RootState) => state.UserInformation);

    document.addEventListener("DOMContentLoaded", function (event) {
        checkUser()
    });
    useEffect(() => {
        // checkUser()
        const crypto = getLocalValue<iAuth>("auth");
        if (crypto == null) {
            authenticate();
        }

        else {
            if (window.location.href.indexOf("code=") > 0) {
                let paramaters = window.location.href.substring(window.location.href.indexOf("?") + 1).split("&");
                let tenantRedirect = ""
                for (var i = 0; i < paramaters.length; i++) {
                    if (paramaters[i].indexOf("code=") > -1) {
                        crypto.code = paramaters[i].substring(5);
                        saveLocalValue("auth", crypto);
                    }
                    
                    if (paramaters[i].substring(0, 6) === "state=") {
                        tenantRedirect = paramaters[i].substring(6);
                        
                    }
                }
                getToken(crypto).then((data)=>{
                    crypto.token = data;
                    saveLocalValue("auth", crypto);
                    navigate('/' + tenantRedirect);
                })

            } else {
                refreshToken();
            }
        }
    });

    function checkUser() {

        if (User.userID == 0 || User.firstname == "") {

            (document.getElementById("loginBack") as HTMLElement).style.display = "block";
        }
        else {

            (document.getElementById("loginBack") as HTMLElement).style.display = "none";

        }
    }
    function refreshToken() {
        var urlParts = window.location.href.substring((window.location.href.indexOf("://") + 3)).split("/");
        refreshIDPToken().then(() => {
            if (urlParts.length > 1 && urlParts[1] != '') {
                if(isTenantValid(urlParts[1])) {
                    navigate("/" + urlParts[1]);
                }
                else {
                    window.location.href = '/';
                    
                }
            } else {
                navigate("/");
            }
        });
    }

    function authenticate() {

        var urlParts = window.location.href.substring((window.location.href.indexOf("://") + 3)).split("/");
        let refererUri = "&state=";
        for(var i=0 ; i< urlParts.length;i++){
            if (urlParts[i].indexOf("?_referer=") >-1) {
                refererUri += encodeURIComponent(urlParts[i].replace("?_referer=", ""));
            }
        }
      
        getPkce(50, (error, { verifier, challenge }) => {

            //let crypto:iAuth = getPKSEKeys();
            let crypto: iAuth =
            {
                token: {} as iToken,
                code: "",
                verifier: verifier,
                challenge: challenge
            }
            saveLocalValue("auth", crypto);

            let vv = "?client_id=" + (process.env.REACT_APP_ClientId!) + "&response_type=" + (process.env.REACT_APP_AWS_Response_Type!) +
                "&scope=" + process.env.REACT_APP_AWS_Scope + "&redirect_uri=" + encodeURIComponent(window.location.origin + "/auth/callback")
                + "&code_challenge=" + (crypto.challenge)
                + "&code_challenge_method=S256"
                + refererUri
                ;
            let aa = process.env.REACT_APP_Authorise_URL + vv;
            window.location.href = aa;
        });
    }

    return (
        <Fragment key="de1">
            <div style={{ width: "100%", textAlign: "center" }}>
                <br />
                <br />
                <br />
                <img src={require('../Home/Settings.gif')} ></img><br />
                Loading profile....
            </div>
        </Fragment>
    );
}

export default Login;
