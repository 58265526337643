import { iAttachment, iAttachments, iLocalMessage } from "../../interfaces/emailModel";
import { iCommunication } from "../../interfaces/iEnquiry";
import { ToGenericComm, iGenericComm, mailComm } from "../../interfaces/iGenericComm";
import { setActiveComm } from "../../state/activeComm";
import store from "../../state/store";
import { GetWorkflowFromStorage, SaveWorkflowToStorage } from "../../state/workflowSlice";
import { callApi, callApiArray, iRequest } from "../../utilities/apiCall";
import { unzipFileAndBaseBase64, unzipFileAndSave, unzipText } from "../../utilities/gZip";
import { addItem, getItem } from "../../utilities/localStore/indexDBCalls";
import { setLastCommunication } from "../LastTouched/comm";

export class CommModule {

    constructor(){}

    getCommToView(commDTO: iCommunication) {
        let wizard = GetWorkflowFromStorage(commDTO.enquieryId);
        if(wizard!=undefined)
        {
            wizard.lastCommunication = commDTO;
            SaveWorkflowToStorage(wizard);
        }
        setLastCommunication(commDTO.enquieryId, commDTO.id)
        store.dispatch(setActiveComm(commDTO.id));
    }
}

//let completeBody:string = "";
async function getBody(attachments: iAttachments): Promise<any> {

    return new Promise((resolve, reject) => {
        let request: iRequest = {
            url: attachments.body.url!,
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApiArray(request).then((resp) => {

            resolve(resp);

        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllAttachments(props: any, downloadType: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        try {
            let fileIndex: number = 0;
            const zip = require('jszip')();

            getFiles();

            async function getFiles() {
                if (fileIndex < props.length) {
                    getAttachment(props[fileIndex], downloadType).then((returnFile) => {
                        zip.file(props[fileIndex].name, returnFile)
                        fileIndex = fileIndex + 1;
                        getFiles()
                    })
                }
                else {
                    zip.generateAsync({ type: 'blob' }).then((blobdata: any) => {
                        // create zip blob file
                        let zipblob = new Blob([blobdata])

                        // For development and testing purpose
                        // Download the zipped file 
                        var elem = window.document.createElement("a")
                        elem.href = window.URL.createObjectURL(zipblob)
                        elem.download = 'compressed.zip'
                        elem.click()
                    })
                }
            }
        } catch (err) {
            var a = err;
        }
    });
}

export async function getAttachment(props: any, downloadType: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        getItem( "Blobs", props.id).then((dbCall) => {
            if (dbCall == undefined) {
                let request: iRequest = {
                    url: "Communication/Attachment/" + props.id,
                    method: "GET",
                    headers: [],
                    body: "",
                    responseType: "json"
                }
                callApi(request).then((resp) => {

                    let request: iRequest = {
                        url: resp.attachmentUrl,
                        method: "GET",
                        headers: [],
                        body: "",
                        responseType: "json"
                    }
                    callApiArray(request).then((resp) => {

                        addItem( "Blobs", { "id": props.id, "data": resp }).then((dbCall) => {

                            if (downloadType == "tempStore") {
                                let attachmentSize: number = (props.size / 1000);
                                let attachmentSizeIndicater: string = "KB"
                                if (attachmentSize > 999.99) {
                                    attachmentSize = (attachmentSize / 1000);
                                    attachmentSizeIndicater = "MB";
                                }
                                attachmentSizeIndicater = attachmentSize + " " + attachmentSizeIndicater;
                                addItem( "TempStore", { "id": props.id, "mimeType": props.mimeType, "name": props.name, size: attachmentSizeIndicater }).then((dbCall) => {
                                    alert("Downloaded to Temp Store");
                                    resolve(dbCall);
                                })
                            }
                            else {
                                unzipFileAndSave(resp, props.mimeType, props.name, downloadType, props.id).then((filest) => {
                                    resolve(filest);
                                }
                                );
                            }


                        })
                    }).catch((err) => {

                    });
                }).catch((err) => { });
            }
            else {

                if (downloadType == "tempStore") {
                    let attachmentSize: number = (props.size / 1000);
                    let attachmentSizeIndicater: string = "KB"
                    if (attachmentSize > 999.99) {
                        attachmentSize = (attachmentSize / 1000);
                        attachmentSizeIndicater = "MB";
                    }
                    attachmentSizeIndicater = attachmentSize + " " + attachmentSizeIndicater;
                    addItem( "TempStore", { "id": props.id, "mimeType": props.mimeType, "name": props.name, size: attachmentSizeIndicater }).then((dbCalltemp) => {
                        alert("Downloaded to Temp Store");
                        resolve(dbCalltemp);
                    })
                }
                else {
                    unzipFileAndSave(dbCall.data, props.mimeType, props.name, downloadType, props.id).then((filest) => {
                        resolve(filest);
                    }
                    );
                }
            }

        });
    });
}

export async function getComm(comm: iCommunication) : Promise<iLocalMessage> {

    let localMessage: iLocalMessage | undefined = await getItem( "Communications", comm.id.toString())

    if (localMessage === undefined) {
        const request: iRequest = {
            url: "Communication/" + comm.id,
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        };
        
        const response = await callApi(request);

        localMessage = { communicationId: comm.id,   body: '', attachments: [], type: comm.type };

        localMessage.body = response.body;

        if (response.attachments.length > 0) {
            localMessage.attachments = (response.attachments.map((xx: iAttachment) =>
                ({ id: xx.id, name: xx.name, mimeType: xx.mimeType, size: xx.contentLength })
            ));
        }

        await addItem( "Communications", localMessage);
    }

    return localMessage;
}

export async function getMailUrls(comm: iCommunication): Promise<any> {
    let localMessage = { communicationId: comm.id,  body: '', attachments: [], type: "email" };
    return new Promise((resolve, reject) => {
        getItem( "Communications", comm.id).then((dbCall) => {
            if (dbCall == undefined) {
                let request: iRequest = {
                    url: "Mail/" + comm.id,
                    method: "GET",
                    headers: [],
                    body: "",
                    responseType: "json"
                }
                callApi(request).then((resp) => {
                    let attachments: iAttachments = resp;
                    if (resp.attachments.length > 0) {
                        localMessage.attachments = (resp.attachments.map((xx: iAttachment) =>
                            ({ id: xx.id, name: xx.name, mimeType: xx.mimeType, size: xx.contentLength })
                        ));
                    }
                    getBody(resp).then((dt) => {
                        unzipText(dt).then((bodyText) => {
                            if (resp.inlineAttachments.length > 0) {
                                localMessage.body = bodyText;
                                getInlineAttachments(attachments, localMessage).then(() => {
                                    addItem( "Communications", localMessage).then((dbCall) => {
                                        resolve(localMessage);
                                    })
                                });
                            }
                            else {
                                localMessage.body = bodyText;
                                addItem( "Communications", localMessage).then((dbCall) => {
                                    resolve(localMessage);
                                })
                            }
                        }
                        );
                    });

                }).catch((err) => {
                    reject(err);
                    alert(err);
                });
            } else {
                resolve(dbCall);
            }
        });
    });
}


async function getInlineAttachment(attachment: iAttachment, localMessage: iLocalMessage): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        let request: iRequest = {
            url: attachment.url!,
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApiArray(request).then((resp) => {
            unzipFileAndBaseBase64(resp, attachment.mimeType!).then((base64Img) => {
                localMessage.body = localMessage.body.replace(("cid:" + attachment.mimeContentID), base64Img)
                resolve();
            }
            );
        }).catch((err) => {

        });
    });
}


async function getInlineAttachments(attachments: iAttachments, localMessage: iLocalMessage): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        let inlineA = []
        for (let i = 0; i < attachments.inlineAttachments.length; ++i) {
            inlineA.push(getInlineAttachment(attachments.inlineAttachments[i], localMessage));
        }
        Promise.all(inlineA)
            .then((results) => {
                resolve();
            })
            .catch((e) => {
                // Handle errors here
            });
    });
}