import { Buffer } from "buffer";
import { ConstructDefaultMailboxPermissions, MailboxPermissionMap, MailboxPermissions, TenantPermissions, Permissions as iPermissions } from "../interfaces/Permissions";

class Permissions {
    static TenantPermissions = class {
        static WorkEnquiryAnyOrder = "WorkEnquiryAnyOrder";
        static Search = "Search";
        static SearchMailBody = "SearchMailBody";
        static ViewMyEnquiries = "ViewMyEnquiries";
        static ViewMyEnquiriesForUser = "ViewMyEnquiriesForUser";
        static ViewCalendar = "ViewCalendar";
        static ViewCalendarForUser = "ViewCalendarForUser";
        static LogDowntime = "LogDowntime";
        static LogDowntimeForUser = "LogDowntimeForUser";
    }
    static MailboxPermissions = class {
        static PopEnquiry = "PopEnquiry";
        static ModifyEnquiryOwnership = "ModifyEnquiryOwnership";
        static ModifyEnquiryStatus = "ModifyEnquiryStatus";
        static ModifyEnquiryPriority = "ModifyEnquiryPriority";
        static CloseEnquiry = "CloseEnquiry";
        static TakeEnquiryOwnership = "TakeEnquiryOwnership";
        static ReplaceEnquiryOwnership = "ReplaceEnquiryOwnership";
        static ActionEnquiry = "ActionEnquiry";
        static MailboxAdmin = "MailboxAdmin";
        static ViewEnquiryList = "ViewEnquiryList";
        static ViewReports = "ViewReports";
        static BypassOwnershipNote = "BypassOwnershipNote";
        static ActionOtherOwnedEnquiry = "ActionOtherOwnedEnquiry";
        static AllowCreateUnowned = "AllowCreateUnowned";
        static ModifyEnquiryProperties = "ModifyEnquiryProperties";
        static MembershipAdmin = "MembershipAdmin";
        static CreateEnquiry = "CreateEnquiry";
    }
}

function HasTenantPermission(decodedToken: [string, string][], permission: string): boolean {
    const claims = decodedToken.filter(x => x[0].toLowerCase() === permission.toLowerCase())

    if (claims.length === 1) {
        return claims[0][1].toLowerCase() === "true";
    }

    return false;
}

function ConstructTenantPermissions(decodedToken: [string, string][]): TenantPermissions {
    return {
        WorkEnquiryAnyOrder: HasTenantPermission(decodedToken, Permissions.TenantPermissions.WorkEnquiryAnyOrder),
        Search: HasTenantPermission(decodedToken, Permissions.TenantPermissions.Search),
        SearchMailBody: HasTenantPermission(decodedToken, Permissions.TenantPermissions.SearchMailBody),
        ViewMyEnquiries: HasTenantPermission(decodedToken, Permissions.TenantPermissions.ViewMyEnquiries),
        ViewMyEnquiriesForUser: HasTenantPermission(decodedToken, Permissions.TenantPermissions.ViewMyEnquiriesForUser),
        ViewCalendar: HasTenantPermission(decodedToken, Permissions.TenantPermissions.ViewCalendar),
        ViewCalendarForUser: HasTenantPermission(decodedToken, Permissions.TenantPermissions.ViewCalendarForUser),
        LogDowntime: HasTenantPermission(decodedToken, Permissions.TenantPermissions.LogDowntime),
        LogDowntimeForUser: HasTenantPermission(decodedToken, Permissions.TenantPermissions.LogDowntimeForUser),
    };
}

function GetMailboxesForPermission(decodedToken: [string, string][], permission: string): number[] {
    const claims = decodedToken.filter(x => x[0].toLowerCase() === permission.toLowerCase())

    if (claims.length === 1) {
        return claims[0][1]
            .split(",")
            .map(x => x.trim())
            .filter(x => x !== "")
            .map(x => parseInt(x));
    }

    return [];
}

function GetMappedMailbox(map: Map<Number, MailboxPermissions>, mailbox: number): MailboxPermissions {
    if (!map.has(mailbox)) {
        map.set(mailbox, ConstructDefaultMailboxPermissions())
    }

    return map.get(mailbox)!;
}

function SetPermissions(decodedToken: [string, string][], map: Map<Number, MailboxPermissions>, permission: string, setter: (mailboxPermissions: MailboxPermissions) => void) {
    const mailboxes = GetMailboxesForPermission(decodedToken, permission);

    for (let i = 0; i < mailboxes.length; i++) {
        const mailbox = GetMappedMailbox(map, mailboxes[i])
        setter(mailbox);
    }
}

function ConstructMailboxPermissions(decodedToken: [string, string][]): MailboxPermissionMap[] {

    const result: MailboxPermissionMap[] = Array<MailboxPermissionMap>();
    const mailboxPermissions = new Map<Number, MailboxPermissions>();

    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.PopEnquiry, (x) => x.PopEnquiry = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ModifyEnquiryOwnership, (x) => x.ModifyEnquiryOwnership = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ModifyEnquiryStatus, (x) => x.ModifyEnquiryStatus = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ModifyEnquiryPriority, (x) => x.ModifyEnquiryPriority = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.CloseEnquiry, (x) => x.CloseEnquiry = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.TakeEnquiryOwnership, (x) => x.TakeEnquiryOwnership = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ReplaceEnquiryOwnership, (x) => x.ReplaceEnquiryOwnership = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ActionEnquiry, (x) => x.ActionEnquiry = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.MailboxAdmin, (x) => x.MailboxAdmin = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ViewEnquiryList, (x) => x.ViewEnquiryList = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ViewReports, (x) => x.ViewReports = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.BypassOwnershipNote, (x) => x.BypassOwnershipNote = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ActionOtherOwnedEnquiry, (x) => x.ActionOtherOwnedEnquiry = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.AllowCreateUnowned, (x) => x.AllowCreateUnowned = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.ModifyEnquiryProperties, (x) => x.ModifyEnquiryProperties = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.MembershipAdmin, (x) => x.MembershipAdmin = true);
    SetPermissions(decodedToken, mailboxPermissions, Permissions.MailboxPermissions.CreateEnquiry, (x) => x.CreateEnquiry = true);

    mailboxPermissions.forEach((value, key) => {
        result.push({
            ID: key,
            Permissions: value
        })
    });

    return result;
}

function HasMailboxPermissionForAnyMailbox(decodedToken: [string, string][], permission: string) {
    const claims = decodedToken.filter(x => x[0].toLowerCase() === permission.toLowerCase())

    if (claims.length === 1) {
        const mailboxes = claims[0][1]
            .split(",")
            .map(x => x.trim())
            .filter(x => x !== "");

        return mailboxes.length > 0;
    }

    return false;
}

function ConstructAnyMailboxPermissions(decodedToken: [string, string][]): MailboxPermissions {
    return {
        PopEnquiry: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.PopEnquiry),
        ModifyEnquiryOwnership: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ModifyEnquiryOwnership),
        ModifyEnquiryStatus: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ModifyEnquiryStatus),
        ModifyEnquiryPriority: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ModifyEnquiryPriority),
        CloseEnquiry: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.CloseEnquiry),
        TakeEnquiryOwnership: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.TakeEnquiryOwnership),
        ReplaceEnquiryOwnership: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ReplaceEnquiryOwnership),
        ActionEnquiry: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ActionEnquiry),
        MailboxAdmin: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.MailboxAdmin),
        ViewEnquiryList: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ViewEnquiryList),
        ViewReports: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ViewReports),
        BypassOwnershipNote: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.BypassOwnershipNote),
        ActionOtherOwnedEnquiry: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ActionOtherOwnedEnquiry),
        AllowCreateUnowned: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.AllowCreateUnowned),
        ModifyEnquiryProperties: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.ModifyEnquiryProperties),
        MembershipAdmin: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.MembershipAdmin),
        CreateEnquiry: HasMailboxPermissionForAnyMailbox(decodedToken, Permissions.MailboxPermissions.CreateEnquiry),
    }
}

function DecodeTokenPermissions(token: string): Array<[string, string]> {
    let base64 = token.split(".")[1]
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    var padding = (4 - (base64.length % 4)) % 4;
    base64 += "=".repeat(padding);

    const tokenJson = Buffer.from(base64, 'base64').toString("utf-8");

    const jObject = JSON.parse(tokenJson);

    return Object.entries<string>(jObject)
        .filter(x =>
            Object.entries(Permissions.MailboxPermissions).map(y => y[1].toLowerCase()).includes(x[0].toLowerCase()) ||
            Object.entries(Permissions.TenantPermissions).map(y => y[1].toLowerCase()).includes(x[0].toLowerCase())
        );
}

export function ConstructPermissions(token: string): iPermissions {
    const decodedToken = DecodeTokenPermissions(token);

    const result: iPermissions = {
        Tenant: ConstructTenantPermissions(decodedToken),
        Mailboxes: ConstructMailboxPermissions(decodedToken),
        AnyMailbox: ConstructAnyMailboxPermissions(decodedToken),
    }

    return result;
}