export function handleBeforeUnload(e: BeforeUnloadEvent) {
    window.opener.postMessage(
        {
            msg: "close",
            EnquiryId: window.location.href.substring(window.location.href.lastIndexOf("/")+1)
        },
        '*')
    e.preventDefault();
}

export function addConfirm() {
    window.addEventListener("beforeunload", handleBeforeUnload);
}

export function removeConfirm() {
    window.removeEventListener("beforeunload", handleBeforeUnload);
}
