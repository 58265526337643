 
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import './tabNavigations.scss';
import { Row } from 'react-bootstrap';
import Tab from './tab';

function TabNavigations(props: any) {
    const tabsState = useSelector((state: RootState) => state.tabRow.tabs);

    return (
        <>
          <Row className="tabRow">
                {tabsState.map((item, i) => <Tab
                    FullWidth={props.FullWidth}
                    label={item.label}
                    enquiryId={item.enquiryId}
                    active={item.active}
                    key={item.enquiryId}
                ></Tab>)}
        </Row>
        </>
    )
};
export default TabNavigations;