import { iWorkflowModel } from "../../state/workflowSlice";
import { CallEmsApi, iEmsApiResponse, iEmsApiResponseBody } from "./FetchExtension";

export async function Start(enquiryId: number): Promise<iEmsApiResponseBody<any>> {
    return await CallEmsApi<any>("GET", "Enquiry/" + enquiryId + "/Action");
}

export async function KeepAlive(enquiryId: number): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("GET", "Enquiry/" + enquiryId + "/Action/KeepAlive");
}

export async function Cancel(enquiryId: number): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("DELETE", "Enquiry/" + enquiryId + "/Action");
}

export async function Finish(enquiry: iWorkflowModel): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("PUT", "Enquiry/" + enquiry.EnquiryId + "/Action", enquiry);
}
