import { Fragment, useState, useEffect } from 'react';
import './Workflow.scss';
import Step from './template'
import { useAppDispatch } from '../../state/store'
import {  GetWorkflowFromStorage } from '../../state/workflowSlice'
import EnquiryAction from './ActionOption';
import { EStepKey } from '../../Logic/Wokflow/stepLogic';
import { EEnquiryAction } from '../../interfaces/wizard';
import { WizardStepProps } from './stepType';
import EmailResponseIcon from '../../assets/img/icons/enq-actions/person-up.svg';
import CompleteEnquiryIcon from '../../assets/img/icons/enq-actions/x-lg.svg';
import { isNullOrWhitespace } from '../../utilities/string';
import { WizardContext } from '../../contexts/wizard-context';
import React from 'react';
import { getEnquiryById } from '../../interfaces/enquiryList';
import { iEnquiry } from '../../interfaces/iEnquiry';

interface Props extends WizardStepProps {
    wizardActionOption?: EEnquiryAction
}
function Step4(props: Props) {

    let { wizard, onNext, setWizard, onBack } = React.useContext(WizardContext);
    let hasEmail = isNullOrWhitespace(
        wizard
            .workflow
            .data
            .EnquirerDetails
            .Email
    );

    const enquiry = getEnquiryById(wizard.EnquiryId);
    const makeNewEnabled: boolean = enquiry !== undefined && enquiry
        .communications
        ?.filter(c => c.type === "Email" && c.email?.incoming === true)
        .length > 1;
    const splitEnabled: boolean = enquiry !== undefined && enquiry
        .communications
        ?.filter(c => c.type === "Email" && c.email?.incoming === true)
        .length > 0;
    wizard = JSON.parse(JSON.stringify(wizard));
    const nextFunc = () => {
        if (wizard.workflow.data.EnquiryAction === undefined) {
            alert("Please choose an action");
            return;
        }
        onNext()
    }

    const backFunc = () => {
        onBack()
    }
    
    const handleChange = (chosenAction: EEnquiryAction, actionEnabled: boolean) => {
        if (!actionEnabled) {
            return;
        }
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.EnquiryAction = chosenAction;
          return currentState;
        }) ;
    }

    const onDoubleClick = (chosenAction: EEnquiryAction) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.EnquiryAction = chosenAction;
          return currentState;
        }) ;
        nextFunc();
    }

    const onIncludeFeedbackRIFChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackRIF = value;
          return currentState;
        });
    }

    const onIncludeFeedbackForwardChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackForward = value;
          return currentState;
        });
    }

    const onIncludeFeedbackEscalateChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackEscalate = value;
          return currentState;
        });
    }

    const onIncludeFeedbackPendChecked = (value: boolean) => {
        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.includeFeedbackPend = value;
          return currentState;
        });
    }
    return (

        <Fragment>
            <Step
                title="Enquiry Action"
                backF={backFunc}
                step={EStepKey.ENQUIRY_ACTION_STEP}
                nextF={nextFunc}
                Enquiry={props.Enquiry}
                Popped = {props.Popped}
                FullWidth = {props.FullWidth}
            >
                <div className="actions">
                    <div className="heading">
                        Select your action
                    </div>
                    <div className="action-list">
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            action={EEnquiryAction.CorrespondByEmail}
                            enabled={!hasEmail}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Correspond with Enquirer by Email"}
                            subhead={"Reply to the enquirer without completing the enquiry"}
                            color={hasEmail ?'default' :'orange'
                            }
                            onDoubleClick={onDoubleClick}
                        >
                            {!hasEmail && <img className='icon response' src={EmailResponseIcon} alt="Respond to Enquiry by Email" />}
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled={!hasEmail}
                            action={EEnquiryAction.RespondByEmail}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Respond to Enquirer by Email"}
                            subhead={"Reply to the enquirer and complete the enquiry"}
                            color={ hasEmail ?'default' :'orange'
                            }
                            onDoubleClick={onDoubleClick}
                        >
                            {!hasEmail && <img className='icon response' src={EmailResponseIcon} alt="Respond to Enquiry by Email" />}
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            action={EEnquiryAction.CorrespondByOtherMedium}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Correspond with other Medium"}
                            subhead={"Reply to the enquirer by Medium without completing the enquiry"}
                            color={'orange'}
                            enabled
                            onDoubleClick={onDoubleClick}
                        >
                            <img className='icon response' src={EmailResponseIcon} alt="Correspond with other Medium" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            action={EEnquiryAction.RespondByOtherMedium}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Respond to Enquirer with other Medium"}
                            subhead={"Reply to the enquiry by Medium and complete the enquiry"}
                            color={'orange'}
                            onDoubleClick={onDoubleClick}
                            enabled
                        >
                            <img className='icon response' src={EmailResponseIcon} alt="Respond to Enquirer with other Medium" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled
                            action={EEnquiryAction.Complete}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry} head={"Complete Enquiry"}
                            subhead={"Complete without sending an email"}
                            color={'red'}
                            onDoubleClick={onDoubleClick}
                        >
                            <img className='icon x' src={CompleteEnquiryIcon} alt="X" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled
                            action={EEnquiryAction.RequestInternalFeedback}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Request Internal Feedback"}
                            subhead={"Send an email internally requesting feedback"}
                            color={'orange'}
                            onDoubleClick={onDoubleClick}
                            onIncludeFeedbackChange={onIncludeFeedbackRIFChecked}
                            disableCheckbox={hasEmail}
                            includeFeedback={wizard.includeFeedbackRIF && !hasEmail}>
                            <img className='icon response' src={EmailResponseIcon} alt="Request Internal Feedback" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled
                            action={EEnquiryAction.ForwardToAnotherDepartment}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Forward Enquiry to another Department"}
                            subhead={"Delegate the whole enquiry to another service centre"}
                            color={'orange'}
                            onDoubleClick={onDoubleClick}
                            includeFeedback={wizard.includeFeedbackForward && !hasEmail}
                            onIncludeFeedbackChange={onIncludeFeedbackForwardChecked}
                            disableCheckbox={hasEmail}>
                            <img className='icon response' src={EmailResponseIcon} alt="Forward To Another Department" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled
                            action={EEnquiryAction.EscalateToManager}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Escalate Enquiry to Manager"}
                            subhead={"Escalate to a manager via email"}
                            color={'orange'}
                            onDoubleClick={onDoubleClick}
                            includeFeedback={wizard.includeFeedbackEscalate && !hasEmail}
                            onIncludeFeedbackChange={onIncludeFeedbackEscalateChecked}
                            disableCheckbox={hasEmail}>
                            <img className='icon response' src={EmailResponseIcon} alt="Escalate To Manager" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            enabled
                            action={EEnquiryAction.Pend}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Pend Enquiry"}
                            subhead={"Put enquiry on hold"}
                            color={'orange'}
                            onDoubleClick={onDoubleClick}
                            includeFeedback={wizard.includeFeedbackPend && !hasEmail}
                            onIncludeFeedbackChange={onIncludeFeedbackPendChecked}
                            disableCheckbox={hasEmail}>
                           
                            <img className='icon response' src={EmailResponseIcon} alt="Pending" />
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            action={EEnquiryAction.MakeNew}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Make a New Enquiry"}
                            subhead={"This email has been incorrectly linked to an old reference number"}
                            color={`${makeNewEnabled ? 'orange' : 'default'}`}
                            onDoubleClick={onDoubleClick}
                            enabled={makeNewEnabled}
                        >
                            {makeNewEnabled && <img className='icon response' src={EmailResponseIcon} alt="Make a New Enquiry" />}
                        </EnquiryAction>
                        <EnquiryAction
                            wizardActionOption={props.wizardActionOption}
                            action={EEnquiryAction.Split}
                            handleChange={handleChange}
                            Enquiry={props.Enquiry}
                            head={"Split Enquiry"}
                            subhead={"Split the enquiry into two as it comprises of two enquiries with different duration"}
                            color={`${splitEnabled ? 'orange' : 'default'}`}
                            onDoubleClick={onDoubleClick}
                            enabled={splitEnabled}
                        >
                            {splitEnabled && <img className='icon response' src={EmailResponseIcon} alt="Split Enquiry" />}
                        </EnquiryAction>
                    </div>
                </div>
            </Step>

        </Fragment>
    );
}

export default Step4;
