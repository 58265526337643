export interface TenantPermissions {
    WorkEnquiryAnyOrder: boolean,
    Search: boolean,
    SearchMailBody: boolean,
    ViewMyEnquiries: boolean,
    ViewMyEnquiriesForUser: boolean,
    ViewCalendar: boolean,
    ViewCalendarForUser: boolean,
    LogDowntime: boolean,
    LogDowntimeForUser: boolean,
}

export interface MailboxPermissions {
    PopEnquiry: boolean,
    ModifyEnquiryOwnership: boolean,
    ModifyEnquiryStatus: boolean,
    ModifyEnquiryPriority: boolean,
    CloseEnquiry: boolean,
    TakeEnquiryOwnership: boolean,
    ReplaceEnquiryOwnership: boolean,
    ActionEnquiry: boolean,
    MailboxAdmin: boolean,
    ViewEnquiryList: boolean,
    ViewReports: boolean,
    BypassOwnershipNote: boolean,
    ActionOtherOwnedEnquiry: boolean,
    AllowCreateUnowned: boolean,
    ModifyEnquiryProperties: boolean,
    MembershipAdmin: boolean,
    CreateEnquiry: boolean,
}

export interface MailboxPermissionMap {
    ID: Number,
    Permissions: MailboxPermissions
}

export interface Permissions {
    Tenant: TenantPermissions,
    Mailboxes: MailboxPermissionMap[],
    AnyMailbox: MailboxPermissions
}

export function ConstructDefaultMailboxPermissions(): MailboxPermissions {
    return {
        PopEnquiry: false,
        ModifyEnquiryOwnership: false,
        ModifyEnquiryStatus: false,
        ModifyEnquiryPriority: false,
        CloseEnquiry: false,
        TakeEnquiryOwnership: false,
        ReplaceEnquiryOwnership: false,
        ActionEnquiry: false,
        MailboxAdmin: false,
        ViewEnquiryList: false,
        ViewReports: false,
        BypassOwnershipNote: false,
        ActionOtherOwnedEnquiry: false,
        AllowCreateUnowned: false,
        ModifyEnquiryProperties: false,
        MembershipAdmin: false,
        CreateEnquiry: false,
    };   
}

export function ConstructDefault(): Permissions {
    return {
        Tenant: {
            WorkEnquiryAnyOrder: false,
            Search: false,
            SearchMailBody: false,
            ViewMyEnquiries: false,
            ViewMyEnquiriesForUser: false,
            ViewCalendar: false,
            ViewCalendarForUser: false,
            LogDowntime: false,
            LogDowntimeForUser: false,
        },
        Mailboxes: [],
        AnyMailbox: ConstructDefaultMailboxPermissions()
    }
}

export function GetMailboxPermissions(permissions: Permissions, mailbox: number): MailboxPermissions {
    let mailboxPermissions = permissions.Mailboxes.find(x => x.ID === mailbox)?.Permissions;

    if (mailboxPermissions === undefined) {
        mailboxPermissions = ConstructDefaultMailboxPermissions();
    }

    return mailboxPermissions;
}