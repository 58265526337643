
import { BsInfo } from 'react-icons/bs';
export interface props {
    label?:string,
    comboKey?: string,
    disabled?: boolean,
    value: string | number ,
    selectedValue?: string|number ,
    onClick: (value: string) => void,
    onDoubleClick?: (value: string) => void,
    infoTooltip?:string
    tooltip?:string
}
const RadioButtonRow = (props: props) => {

    let rowClass = "RadioButtonRow"
        if(props.disabled==true){
            rowClass = "RadioButtonRowDisabled"
        }
        else if(props.value == props.selectedValue){
            rowClass = "RadioButtonRowActive"
        }

    return (
      <div className={rowClass} onClick={()=>{props.onClick(props.value.toString())}} onDoubleClick={() => props.onDoubleClick?.(props.value.toString())}>
            <div className='RadioButtonRowCol1' title={props.tooltip}><input type="radio"  disabled={props.disabled? true: false} value={props.value} checked={props.value == props.selectedValue? true:false} readOnly={true}></input></div>
            <div className='RadioButtonRowCol2' title={props.tooltip}>{props.label}</div>
            {props.infoTooltip!?.trim()== "" || props.infoTooltip==undefined? "" : <div className='RadioButtonRowCol3' title={props.infoTooltip}><BsInfo></BsInfo></div>}
      </div>
    )
}

export default RadioButtonRow;