 import { useEffect, useRef, useState } from 'react';
import './styles.scss'
 import { BsExclamationCircleFill } from "react-icons/bs"
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../state/store';
import { keepTheConnectionAlive } from '../../utilities/userProcessing';
import { changeConnection } from '../../state/connection';
function NOConnection(props: any) {
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const dispatch = useAppDispatch();
    const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x => x.active == true);
    
    const [timer, setTimer] = useState(10);
  
    const changeTime=()=>{
 
        if(timer > 0){
            setTimer((prev) => prev - 1);
        }
        else{
            keepTheConnectionAlive(tabState?.enquiryId!).then(()=>{
                dispatch(changeConnection(true));
                clearInterval(intervalRef.current!);
            }
                
            ).catch((err)=>{
                if(err=="unable to connect"){
                    setTimer(10);
                }
            });
            
        }
    
    }
    useEffect(() => {
        intervalRef.current = setInterval(changeTime, 1000);
        return () => clearInterval(intervalRef.current!);
    }, [timer]);

    return(<>
<div className="backgroundDiv"> 
<div className='divModal'>
        <div className="divModalHeader">
        <BsExclamationCircleFill  className="icon-padding"/> Connection Lost
        </div>
        <div className="divModalBody">
        Internet connection is lost, trying to re-establish connection in... 
        <br></br><br></br><br></br>
        <p style={{textAlign:"center"}}>
            <span style={{fontSize:"35pt"}} id="countDownText">{timer}</span>s

        </p>
        </div>
</div>
</div>

</>
)
}

export default NOConnection;
