import './index.scss'
import { iEnquiryPropertiesProps } from './EnquiryPropertiesModal';
import { toDateTime } from '../../utilities/date';
import DateTime from '../Generic/Form/DateTime';

export default function DatesAndTimes(props: iEnquiryPropertiesProps) {

    const { enquiryDetails } = props;

    return (
        <>
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Time Sent"}
                value={toDateTime(enquiryDetails?.sentAt ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Time Received"}
                value={toDateTime(enquiryDetails?.receivedAt ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Time Allocated"}
                value={toDateTime(enquiryDetails?.allocatedAt ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Time Completed"}
                value={toDateTime(enquiryDetails?.completedAt ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <label style={{ marginBottom: "7px", marginTop: "5px" }}>Enquiry Box Cycle SLA</label>
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Reminder"}
                value={toDateTime(enquiryDetails?.boxSlaCycle.reminder ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Alarm"}
                value={toDateTime(enquiryDetails?.boxSlaCycle.alarm ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Timeout"}
                value={toDateTime(enquiryDetails?.boxSlaCycle.timeout ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <label style={{ marginBottom: "7px", marginTop: "5px" }}>Active Response Cycle</label>
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Reminder"}
                value={toDateTime(enquiryDetails?.responseSlaCycle.reminder ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Alarm"}
                value={toDateTime(enquiryDetails?.responseSlaCycle.alarm ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-enquirer-name"}
                label={"Timeout"}
                value={toDateTime(enquiryDetails?.responseSlaCycle.timeout ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
        </>
    )
}
