import { createSlice } from '@reduxjs/toolkit'

export interface iTenantConfiguration {
    templates: Array<iTemplateMetadata>,
    skins: Array<iSkinMetadata>,
    tags: Array<iTagMetadata>,
    editorConfigs: Array<iEditorConfigMetaData>
}

export interface iTemplateMetadata {
    id: number,
    name: string,
    actions: number[],
    mailboxes: number[],
    updatedAt: Date
}

export interface iSkinMetadata {
    id: number,
    name: string,
    mailboxes: number[],
    updatedAt: Date
}

export interface iTagMetadata {
    key: string,
    friendlyName: string,
    updatedAt: Date
}

export interface iEditorConfigMetaData {
    id: number,
    updatedAt: Date
}

export const Configuration = createSlice({
    name: 'Configuration',
    initialState: {} as iTenantConfiguration,
    reducers: {
        setConfiguration: (state, action) => {
            return { ...action.payload }
        },
    }
})

export const { setConfiguration } = Configuration.actions;

export default Configuration;