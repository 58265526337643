import { useState } from "react";

export function useContextMenu() {
    const [contextMenuState, setContextMenuState] = useState({ data:"0", visible: false, x: 0, y: 0 });

    function closeContextMenu() {
        setContextMenuState({ data:"0", visible: false, x: 0, y: 0 })
    }

    function onContextMenu(event: React.MouseEvent) {
        event.preventDefault();
        setContextMenuState({ data:"0", visible: true, x: event.pageX, y: event.pageY })
    }

    return { contextMenuState, setContextMenuState, onContextMenu, closeContextMenu }
}