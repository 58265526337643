import { useEffect, useRef, useState } from 'react';
import { MisField, componentProps } from '../../../interfaces/ActionStartResponse';
import { BsSearch } from 'react-icons/bs';
import '../mis.scss';
import FindDialog, { FindOption } from '../../Generic/FindDialog';


function Dropdown(props: componentProps) {
 
    const [showFindDialog, setShowFindDialog] = useState(false);
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)
    let validField = props.Valid

    const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
        setFieldValue(e.currentTarget.value);
        if (e.currentTarget.value == "") {
            validField = false;
        } else {
            validField = true;
        }
        const sendToParent = props.returnFunc;
        sendToParent( {fieldID:props.MisEntry.fieldID,value:e.currentTarget.value});
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div className='col-sm-3'><label className='g-label'>{props.MisField.label} </label><small className="asterisk">*</small></div>
            <div className='col-sm-9'>
                <div className="controlWithButton">
                    <div>
                        <select style={{ float: "left" }} className={(validField) ? "g-Select-default" : "g-Select-default validationFailed"} id={"mis-select" + props.MisField.id!.toString()} 
                        key={"Select" + props.MisField.id} onChange={handleSelectChange} value={fieldValue}  >
                            <option value=""> Please select... </option>
                            {
                                props.MisField.lookupValues?.map((item: any) =>
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div >
                        <button onClick={() => setShowFindDialog(true)}  ><BsSearch /></button>
                    </div>
                </div>
            </div>

            {showFindDialog &&
                <FindDialog
                    onCancel={() => setShowFindDialog(false)}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== "")
                            setFieldValue(selectedValue);

                        setShowFindDialog(false);
                    }}
                    values={props.MisField.lookupValues!.map<FindOption>(x => {
                        return {
                            label: x.name,
                            value: x.id.toString()
                        };
                    })}
                />
            }
        </div>
    );
}
export default Dropdown;