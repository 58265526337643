import { EKeyboardKeys } from "../../../../../constants/KeyboardKeys";
import { iContact } from "../../../../../interfaces/Contact";
import { BsSearch } from "react-icons/bs";
import { Contact } from "./Contact";
import { useEffect } from "react";

interface Props {
    onContactClick: (id: string) => void,
    constacts: iContact[]
}

export function ContactList(props: Props) {

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, false);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);

    const handleKeyDown = async (e: KeyboardEvent) => {
        switch (e.code) {
            case EKeyboardKeys.ENTER:
                handleItemClick();
                break;
            case EKeyboardKeys.ENTER_NUMPAD:
                handleItemClick();
                break;
            case EKeyboardKeys.SPACE:
                handleItemClick();
                break;
            case EKeyboardKeys.ARROW_UP:
                handleArrowUp();
                break;
            case EKeyboardKeys.ARROW_DOWN:
                handleArrowDown();
                break;
            case EKeyboardKeys.TAB:
                e.preventDefault();
                handleArrowDown();
                break;
            default:
                break;
        }
    }

    const handleItemClick = () => {
        const focusedElt = document.activeElement as HTMLElement
        if (props.constacts.find(ct => ct.id === focusedElt?.id) !== undefined)
            focusedElt.click();
    }

    const handleArrowUp = async () => {
        if (props.constacts.length === 0)
            return;

        if (props.constacts.length === 1) {
            const nextElt = document.getElementById(props.constacts[0].id);
            nextElt?.focus();
            return;
        }

        const focusedElt = document.activeElement as HTMLElement;
        const activeEltIndex = props.constacts.findIndex(ct => ct.id === focusedElt?.id);
        if (activeEltIndex === -1 || activeEltIndex === 0) {
            const nextPos = props.constacts.length - 1;
            const nextElt = document.getElementById(props.constacts[nextPos].id);
            nextElt?.focus();
            return;
        }

        const nextPos = activeEltIndex - 1;
        const nextElt = document.getElementById(props.constacts[nextPos].id);
        nextElt?.focus();
        return;
    }

    const handleArrowDown = async () => {
        if (props.constacts.length === 0)
            return;

        if (props.constacts.length === 1) {
            const nextElt = document.getElementById(props.constacts[0].id);
            nextElt?.focus();
            return;
        }

        const focusedElt = document.activeElement as HTMLElement;
        const activeEltIndex = props.constacts.findIndex(ct => ct.id === focusedElt?.id);
        if (activeEltIndex === -1 || activeEltIndex === props.constacts.length - 1) {
            const nextPos = 0;
            const nextElt = document.getElementById(props.constacts[nextPos].id);
            nextElt?.focus();
            return;
        }

        const nextPos = activeEltIndex + 1;
        const nextElt = document.getElementById(props.constacts[nextPos].id);
        nextElt?.focus();
        return;
    }

    return (
        <ul
            className="contact-list"
            id="autocomplete-list"
            role="listbox"
        >
            {
                props.constacts.length > 0 ?
                    props.constacts.map(ctProps =>
                        <Contact
                            {...ctProps}
                            onClick={props.onContactClick}
                        />
                    ) :
                    <li>No Contacts</li>
            }
            <li className="horizontal-divider"></li>
            <li className="search-button">
                <i className="search-icon"><BsSearch /></i>
                <input className="search-input" placeholder="Search Global Address Book" type="text" disabled />
            </li>
        </ul>
    )
}