
export function AddHours(date: Date, hours: number): Date {
    return new Date(date.getTime() + hours * 60 * 60 * 1000)
}

export function FormatDate(text: string): string {
    const date = new Date(text);
    let result = "";

    result = date.toLocaleDateString("en-za", {day: "numeric"});
    result += " " + date.toLocaleDateString("en-za", {month: "long"});
    result += " " + date.toLocaleDateString("en-za", {year: "numeric"});

    return result;
}

export function FormatTime(text: string): string {
    let date = new Date(text);
    if (!date.getTime()) {
        if (!text) {
            return text;
        }
        
        let time = text.split(":");
        if (time.length < 2) {
            return text;
        }

        date = new Date();
        date.setHours(parseInt(time[0]));
        date.setMinutes(parseInt(time[1]));

        if (time.length >= 3) {
            date.setSeconds(parseInt(time[2]));
        }
    }

    let result = "";

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isMorning = hours < 12;

    if (hours < 12) {
        if (hours < 10)
            result += "0";
        result += hours.toString();
    } else {
        result += (hours - 12).toString();
    }

    result += ":";

    if (minutes < 10)
        result += "0";

    result += minutes.toString();

    result += isMorning ? "am" : "pm";

    return result;
}

export function FormatFullDate(text: string): string {
    let result = "";

    result = FormatDate(text);
    result += " " + FormatTime(text);

    return result;
}