import './index.scss';
import { Row, Col } from "react-bootstrap";
import Sidebar from '../sidebar/sidebar'
import Topbar from '../topBar/topBar'
import Enquiries from '../../Enquiries/MyEnquiry/index'
import Compnents from './components'
import Wizard from './workflow'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../state/store';
import Tabs from '../tabRow/tabNavigations'
import QueueLists from '../../Enquiries/QueueLists';
import SecondRow from '../fullWidth/secondRow';
import SearchResults from '../../Enquiries/SearchResults/Results'
import { getSessionValue, saveSessionValue } from '../../../utilities/localStore/calls';
import { Dispatch, useEffect } from 'react';
import { getEnquiryById } from '../../../interfaces/enquiryList';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { GetWorkflowFromStorage } from '../../../state/workflowSlice';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset, logErrorToService } from '../../../error-boundary/logerror';

function RestrictedWidth() {
  const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
  const tabState = useSelector((state: RootState) => state.tabRow.tabs).find(x => x.active == true);
  const RestrictedWidthState = useSelector((state: RootState) => state.restrictedView)
  const dispatch: Dispatch<any> = useAppDispatch();
  const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
  const enq = getEnquiryById(tabState!.enquiryId > 11 ? tabState!.enquiryId : activeEnquiry.id);

  if (getSessionValue<boolean>("ScreenSizeChanged") == true) { // we do this because when the screen changes from full screen it must show the list of enquirires and not a single enquiry
    saveSessionValue("ScreenSizeChanged", false);
    dispatch(setShowEnquiry(true));
    if (tabState?.enquiryId! < 12) {
      dispatch(setShowWizard(false));
    }
  }
  useEffect(() => {
    if (RestrictedWidthState.showWizard) {
      if (GetWorkflowFromStorage(activeEnquiry.id) == undefined) {
        dispatch(setShowWizard(false));
      }
    }
  }, [RestrictedWidthState.showWizard, RestrictedWidthState.showEnquiry])


  return (

    <>
      <div className="layout-lg">
      
        <div className="aside">
        <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={defaultReset} 
                            >
          <Sidebar FullWidth={false} />
          </ErrorBoundary>
        </div>
        <div className="main-layout" id="mainLayoutDiv">  
          <div className="top-bar"><ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={defaultReset} 
                            >
            <Topbar FullWidth={false} />
            </ErrorBoundary>
          </div>
          <div
            className="main-content"
            id="mainContent"
            style={{
              display: "flex",
              flexDirection: "column"
            }}>
            {/* ----------main content---------- */}
            <div style={
              {
                padding: "0px 12px 0px 12px",
                display: "flex",
                flexDirection: "column",
                flex: "9 1 0", background: "#f3f3f3"

              }}>
              <div style={{ background: "#f3f3f3" }}>
                <Tabs FullWidth={false}></Tabs>
              </div>
              <SecondRow FullWidth={false}></SecondRow>
              <Row className='scroll-small' style={{ flex: "9 1 0", overflowY: "hidden", marginTop: "6px" }}>
                <Col style={{ height: "100%", position: "relative", background: "#f3f3f3" }} xs="12" id="secondColContainer">
                <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={defaultReset} 
                            >
                  {
                    (!RestrictedWidthState.showEnquiry && SiteInformation.enquiriesListVisible) && <Enquiries FullWidth={false}></Enquiries>
                  }
                  {
                    (!RestrictedWidthState.showEnquiry && SiteInformation.queuesListVisible) && <QueueLists></QueueLists>
                  }
                  {
                    (!RestrictedWidthState.showEnquiry && SiteInformation.searchResultsVisible) && <SearchResults FullWidth={false}></SearchResults>
                  }
                  {
                    (RestrictedWidthState.showEnquiry && !RestrictedWidthState.showWizard && enq !== undefined) && (<Compnents enq={enq} hasActivateWiz={tabState !== undefined && tabState?.enquiryId < 12}></Compnents>)
                  }
                  {
                    (RestrictedWidthState.showEnquiry && RestrictedWidthState.showWizard && enq) && (<Wizard Enquiry={enq}></Wizard>)
                  }
                  </ErrorBoundary>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RestrictedWidth;