import { useEffect } from "react";

const useOutsideClick = (ref: React.RefObject<HTMLElement>, callback: () => void) => {
    const handleClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            callback();
        }
    }

    const handleWindowMessage = async (e: MessageEvent<any>) => {
        if (e.data && e.data == "inside-iframe-click") {
            callback();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        window.addEventListener("message", handleWindowMessage);

        return () => {
            document.removeEventListener('mousedown', handleClick);
            window.removeEventListener("message", handleWindowMessage);
        }
    }, [ref, callback])
}

export default useOutsideClick;

export const handleInsideClick = () => {
    window.postMessage("inside-iframe-click");
}