import { ErrorBoundary } from 'react-error-boundary';
import CommPreview from '../../Email/switch'
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset } from '../../../error-boundary/logerror';
import { iEnquiry } from '../../../interfaces/iEnquiry';

interface Props {
    enq: iEnquiry,
    hasActivateWiz: boolean
}

function Components(props: Props) {
 
    return (
        <>
         
        <div style={{  height: "100%", background: "#f3f3f3", padding: "15px 5px 5px 2px",overflow:"auto" }}>
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={defaultReset} 
            >
            <CommPreview FullWidth={false} enquiry={props.enq} hasActivateWiz={props.hasActivateWiz} />
            </ErrorBoundary>
        </div>
        </>
    );
}

export default Components;
