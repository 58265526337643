import { useRef } from 'react';
import 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import { Editor, } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { iHtmlEditorConfig } from '../../interfaces/iMailboxConfig';
import React from 'react';
import { GetCachedEditorConfig } from '../../Logic/TenantConfigCache';

type EditorProps = {
    initialValue: string,
    mailbox:number,
}

function TemplatePreview(props: EditorProps) {
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const [HtmlEditorConfig, setHtmlEditorConfig] = React.useState<iHtmlEditorConfig>({} as iHtmlEditorConfig);
  
    React.useEffect(() => {
    (async () =>{
        const mailboxConfig = await GetCachedEditorConfig(props.mailbox);
        if (Object.keys(mailboxConfig).length > 0) {
            let htmlConfig: iHtmlEditorConfig = {} as iHtmlEditorConfig;

            htmlConfig.Toolbar = 'undo redo | fontfamily  styles  |  ';
            if (mailboxConfig.boldEnabled) {
                htmlConfig.Toolbar += ' bold '
            }
            if (mailboxConfig.italicEnabled) {
                htmlConfig.Toolbar += ' italic '
            }
            if (mailboxConfig.underlineEnabled) {
                htmlConfig.Toolbar += ' underline  '
            }

            if (mailboxConfig.availableTextColors.length > 0) {
                htmlConfig.Toolbar += ' forecolor  '
                htmlConfig.TextColors = [];
            }

            if (mailboxConfig.availableBackColors.length > 0) {
                htmlConfig.Toolbar += ' backcolor  '
                htmlConfig.BackColors = [];
            }
            htmlConfig.Toolbar += ' | ';
            if (mailboxConfig.linkEnabled) {
                htmlConfig.Toolbar += ' link ';
            }
            if (mailboxConfig.imageEnabled) {
                htmlConfig.Toolbar += ' image ';
            }
            if (mailboxConfig.imageEnabled || mailboxConfig.linkEnabled) {
                htmlConfig.Toolbar += ' | ';
            }
            if (mailboxConfig.alignmentEnabled) {
                htmlConfig.Toolbar += ' alignleft aligncenter alignright alignjustify |  '
            }
            if (mailboxConfig.bulletListEnabled) {
                htmlConfig.Toolbar += ' bullist ';
            }
            if (mailboxConfig.numberListEnabled) {
                htmlConfig.Toolbar += ' numlist ';
            }
            htmlConfig.Toolbar += ' checklist outdent indent | removeformat | ';
            if (mailboxConfig.tableEnabled) {
                htmlConfig.Toolbar += ' table ';
            }

            mailboxConfig.availableBackColors.map(
                (item, index) => {
                    htmlConfig.BackColors.push(item.hexCode);
                    htmlConfig.BackColors.push(item.label)
                }
            )
            mailboxConfig.availableTextColors.map(
                (item, index) => {
                    htmlConfig.TextColors.push(item.hexCode);
                    htmlConfig.TextColors.push(item.label)
                }
            )
            htmlConfig.Fonts = [];
            mailboxConfig.availableFonts.map(
                (item, index) => {
                    htmlConfig.Fonts.push(item);
                }
            )
            htmlConfig.DefaultFont = mailboxConfig.defaultFont;
            setHtmlEditorConfig(htmlConfig);
        }
    })();
    }, [props.mailbox]);
   
    return (
        <>
            {
                Object.keys(HtmlEditorConfig).length > 0 &&
                <Editor
                    onInit={(evt, ed) => {
                        editorRef.current = ed;
                        ed.focus();
                        ed.getBody().setAttribute('contenteditable','false');
                    }}
                    initialValue={props.initialValue}
                    init={{
                        setup: function (ed) {
                            ed.on('init', function (args) {
                                ed.editorContainer.style.height = (window.innerHeight - 350) + "px"
                            });
                        },
                        newline_behavior: 'linebreak',
                        color_map_foreground: HtmlEditorConfig.TextColors,
                        color_map_background: HtmlEditorConfig.BackColors,
                        custom_colors: false,
                        branding: false,
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        visual: false,
                        base_url: '/tinymce/',
                        browser_spellcheck: true, contextmenu: false,
                        content_style: 'body {  font-size:10pt; font-family: "' + HtmlEditorConfig.DefaultFont + '" } h1{font-size:26px !important;} p {margin:0px;padding:0px;}' +
                            ' h2{font-size:24px !important;} ' +
                            ' h3{font-size:22px !important;} ' +
                            ' h4{font-size:20px !important;} ' +
                            ' h5{font-size:18px !important;} ' +
                            ' h6{font-size:16px !important;} ' +
                            ' my_class{background:yellow !important} ' +
                            '::-webkit-scrollbar { width: 5px;  height: 5px;}' +
                            '::-webkit-scrollbar-track,::-webkit-scrollbar-track {    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);}' +
                            '::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb {    background-color: #A5AAB3;    outline: 0px solid #A5AAB3;}'+
                            ' p.EmsNormal {margin:0px;padding:0px; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h1.EmsNormal{font-size:26px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' + // the EmsNormal class is added for emails that are replied to in Outlook and other mail apps
                            ' h2.EmsNormal{font-size:24px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h3.EmsNormal{font-size:22px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h4.EmsNormal{font-size:20px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h5.EmsNormal{font-size:18px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' +
                            ' h6.EmsNormal{font-size:16px !important; font-family: "' + HtmlEditorConfig.DefaultFont + '"} ' 
                        ,
                        style_formats: [
                            { title: 'Paragraph', format: 'p' },
                            { title: 'Heading 1', format: 'h1' },
                            { title: 'Heading 2', format: 'h2' },
                            { title: 'Heading 3', format: 'h3' },
                            { title: 'Heading 4', format: 'h4' },
                            { title: 'Heading 5', format: 'h5' },
                            { title: 'Heading 6', format: 'h6' }
                      ],
                        toolbar_items_size: 'small',
                        font_family_formats: HtmlEditorConfig.Fonts.flat(1).toString(),
                        skin: 'tinymce-5',
                        icons: 'default',
                        block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;'
                    }
                    }
                ></Editor>
            }
        </>
    )
}

export default TemplatePreview;