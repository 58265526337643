import { createSlice } from "@reduxjs/toolkit";
import { iWizard, iWizards } from "../interfaces/wizard";

const initialWizard: iWizard = {
    popped: false,
    id: 0
}
const initialState: iWizards = {
    wizards: [initialWizard]
}

const addPoppedWizard = (wizardL: iWizard[], w: iWizard) => {
    return [...wizardL.filter(wiz => wiz.id != w.id), w]
}

const activeWizardSlice = createSlice({
    name: "poppedWizards",
    initialState,
    reducers: {
        setPoppedWizards: (state, action) => {
            state.wizards = addPoppedWizard(state.wizards, action.payload)
        }
    }
})

export const {setPoppedWizards} = activeWizardSlice.actions

export default activeWizardSlice;