import { Dispatch } from 'redux';
import { removeTab, setTabVisibility } from '../../../state/tabRow';
import './tabNavigations.scss';
import { confirm } from "react-confirm-box";
import { RootState, useAppDispatch } from '../../../state/store';
import { GetWorkflowFromStorage, Wizard, WorkflowState, setEnquiryId, setState } from '../../../state/workflowSlice';
import { setWorkflowVisible } from '../../../state/secondColSlice';
import { BsX} from 'react-icons/bs';
import { refreshList } from '../../../interfaces/enquiryList';
import { useSelector } from 'react-redux';
import { closeConnection, keepTheConnectionAlive } from '../../../utilities/userProcessing';
import { changeConnection } from '../../../state/connection';
import { setActiveEnquiry } from '../../../state/activeEnquiry';
import { setShowEnquiry, setShowWizard } from '../../../state/restrictedViewSlice';
import { setActiveComm } from '../../../state/activeComm';
import { useState } from 'react';
import Confirm from '../../Generic/ConfirmDialog';
import { wizardMessage } from '../../Workflow';
import { resetSort } from '../../../utilities/sort';
import { removeNewEnquiryId } from '../../../Logic/Enquiries/activateWizard';
 

function Tab(props: any) {
    const dispatch: Dispatch<any> = useAppDispatch();
    const UserInfo = useSelector((state: RootState) => state.UserInformation);

    const makeTabActive = (enquiryId: number, label: string) => {
        resetSort()
        dispatch(setTabVisibility(enquiryId));
        const workflow = GetWorkflowFromStorage(enquiryId)
        if (workflow) {

            keepTheConnectionAlive(enquiryId).then(()=>{
                //dispatch(setCommToView({...ToGenericComm(workflow.lastCommunication!)}));
                dispatch(setState(workflow));
                dispatch(setWorkflowVisible(true));
                dispatch(setActiveEnquiry(enquiryId));
                if (!props.FullWidth) {  dispatch( setShowEnquiry(true));}
            }
                
            ).catch((err)=>{
                dispatch(changeConnection(false));
                //dispatch(setCommToView({...ToGenericComm(workflow.lastCommunication!)}));
                dispatch(setState(workflow));
                dispatch(setWorkflowVisible(true));
                dispatch(setActiveEnquiry(enquiryId));
                if (!props.FullWidth) {  dispatch( setShowEnquiry(true));}
            });
        } else {
             dispatch(setActiveEnquiry(0));
             dispatch(setActiveComm(0));
             dispatch(setShowEnquiry(false));
             dispatch(setShowWizard(false));
             dispatch(setWorkflowVisible(false));
        }
    }
	 
const deleteTab = async (enquiryId: number) => {
    showConfirmDialogue(enquiryId,
        () => {
            closeConnection(enquiryId).then(() => {
                removeNewEnquiryId(enquiryId)
                dispatch(setShowEnquiry(false));
                refreshList(UserInfo.userID).then(() => {
                     
                    dispatch(removeTab(enquiryId));
                    dispatch(setActiveEnquiry(0));
                    
                
                    return;
                })
                
            }).catch((err) => {
                console.log(err)
                return;
            })
        }, true);
    }

    const [dialogueShown, setDialogueShown] = useState({
        enquiryId: 0,
        okFunc: Function(),
        isShown: false
      })
      const showConfirmDialogue = (enquiryId: number, okFunc: Function, isShown: boolean) => {
        setDialogueShown({
          enquiryId,
          okFunc,
          isShown
        })
      }

    return (
        <>
        <div className={props.active ? "navTab active" : "navTab"} key={props.enquiryId}>
             <div className={props.enquiryId > 100 ? "" : "fixedWidth"}>
                <div onClick={() => { makeTabActive(props.enquiryId, props.label) }} id={"tab-" + props.enquiryId}>{props.label}</div>
                {props.enquiryId > 100 ? <div onClick={() => { deleteTab(props.enquiryId) }}><BsX style={{ fontSize: "15px" }}></BsX></div> : ""}
            </div>
        </div>
            {dialogueShown.isShown && <Confirm
                title={"Warning"}
                onCancel={() => showConfirmDialogue(0, dialogueShown.okFunc, false)}
                onConfirm={() => dialogueShown.okFunc()}
                messageType={"warn"}
            >{[wizardMessage]}</Confirm>}
        </>
    )

};
export default Tab;