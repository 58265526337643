export interface Status {
    ID: number,
    Name: string,
    ShortName: string,
}

class Statuses {
    static IsCompleted(id: number): boolean {
        return id >= 8;
    }

    static Name(id:number):string{
       return Object.entries(Statuses).find((item => item[1].ID==id))?.[1].Name
    }

    static Received: Status = {
        ID: 0,
        Name: "Received",
        ShortName: "Received",
    }
    static HasNew: Status = {
        ID: 2,
        Name: "Has New Message",
        ShortName: "Has New",
    }
    static Pending: Status = {
        ID: 3,
        Name: "Pending - General",
        ShortName: "Pending",
    }
    static PendRif: Status = {
        ID: 4,
        Name: "Pending - Request Internal Feedback",
        ShortName: "Pend - RIF",
    }
    static PendCorr: Status = {
        ID: 5,
        Name: "Pending - Correspond with Enquirer",
        ShortName: "Pend - Corr.",
    }
    static Escalated: Status = {
        ID: 6,
        Name: "Pending - Escalated",
        ShortName: "Escalated",
    }
    static Processing: Status = {
        ID: 7,
        Name: "User Processing",
        ShortName: "Processing",
    }
    static CompletedResponded: Status = {
        ID: 8,
        Name: "Completed - Responded",
        ShortName: "Done - Response",
    }
    static CompletedNoResponse: Status = {
        ID: 9,
        Name: "Completed - No response",
        ShortName: "Done - No Resp.",
    }
    static CompletedForwarded: Status = {
        ID: 10,
        Name: "Completed - Forwarded",
        ShortName: "Done - Delegate",
    }
    static CompletedReassigned: Status = {
        ID: 11,
        Name: "Completed - Reassigned",
        ShortName: "Done - Reassgn",
    }
}

export default Statuses;