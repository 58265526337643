export enum EKeyboardKeys {
    ENTER = "Enter",
    DELETE = "Delete",
    ENTER_NUMPAD = "NumpadEnter",
    BACKSPACE = "Backspace",
    TAB = "Tab",
    SPACE = "Space",
    COMMA = "Comma",
    SEMICOLON = "Semicolon",
    ARROW_UP = "ArrowUp",
    ARROW_DOWN = "ArrowDown"
    // etc.
}