import { iEnquiry } from "./iEnquiry"

export interface iWizard {
    popped: boolean,
    id: number,
    enquiry?: iEnquiry
}
export interface iWizards {
    wizards: Array<iWizard>
}

export enum EEnquiryAction {
    LinkToExisting = 1,
    AssignOtherUser = 2,

    CorrespondByEmail = 3,
    CorrespondByOtherMedium = 4,

    RespondByEmail = 5,
    RespondByOtherMedium = 6,

    Complete = 7,
    
    RequestInternalFeedback = 8,
    ForwardToAnotherDepartment = 9,
    EscalateToManager = 10,
    Pend = 11,

    MakeNew = 12,
    Split = 13,
}
