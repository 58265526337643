/**
 * Correspons to the tblActions in the Database
 */
export enum DraftTemplateGroup {
    CorrespondWithEnquirer = 2,
    RespondToEnquirer = 3,
    CompleteEnquiry = 4,
    RequestInternalFeedback = 5,
    ForwardToAnotherCRC = 6,
    EscalateToCRM = 7,
    EnquirerAcknowledgements = 11
}