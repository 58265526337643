import { useEffect, useState } from 'react';
import './Workflow.scss';
import { useSelector } from "react-redux";
import EnquiryTracker from './enquiryTracker'
import EnquirerDetails from './enquirerDetails'
import ComposeEmail from './ComposeEmailStep/index'
import MIS from './mis'
import  NoConnection   from "../NOConnection/index";
import EnquiryAction from './enquiryAction'
import { RootState, useAppDispatch } from '../../state/store'
import { iCommunication, iEnquiry } from '../../interfaces/iEnquiry';
import { GetWorkflowFromStorage, SaveWorkflowToStorage, Wizard } from '../../state/workflowSlice';
import { getEnquiryById } from '../../interfaces/enquiryList';
import SearchEnquiryStep from './LinkToEnquiry/index';
import { EStepKey, getNextStep, getSummary } from '../../Logic/Wokflow/stepLogic';
import FinishStep from './FinishStep';
import Diarise from './Diarise';
import { WizardContext } from '../../contexts/wizard-context';
import { CommunicationLogger } from './CommunicationLogger';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../error-boundary/errorFallback';
import { logErrorToService } from '../../error-boundary/logerror';
import { SelectEmail } from './SelectEmail';
import { AssignOtherUser } from './AssignOtherUser';
import { ExplanatoryNote } from './ExplanatoryNote';
import { WizardStepProps } from './stepType';
import { GetEnquiryCommunicationCount, GetEnquiryCommunications, iCommCount } from '../../Logic/EmsAPI/Enquiries';
import { ToastDismissOverlay } from '../Generic/ToastDismissOverlay';
import { setLastCommunication } from '../../Logic/LastTouched/comm';
import { updateEnquiriesCache } from '../../state/enquiriesCache';
import useInterval from '../../hooks/useInterval';
import { setShowWizard } from '../../state/restrictedViewSlice';
import { sortComms } from '../../utilities/sort';

function Steps(props: WizardStepProps) {
    const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
    const activeConnection = useSelector((state: RootState) => state.Connection.connected);
    const dispatch = useAppDispatch();

    const [wizard, setWizard] = useState<Wizard>({} as Wizard);

    const [commCount, setCommCount] = useState<iCommCount>({count: props.Enquiry.communications.length ?? 0, isPendingProcessing: false});
    const hasNewComm = commCount.count > wizard.workflow?.data?.CommVerification; // ?: init load has undefined wizard
    const [isPendingDismissed, setIsPendingDismissed] = useState(false);

    const pingCommCount = async () => {
        const response = await GetEnquiryCommunicationCount(props.Enquiry.id);
        if (!response.isOk) {
            throw new Error(`${response.status} returned from server.`);
        }
        if(response.data) {
            setCommCount(response.data);
        }
    }

    useInterval(pingCommCount, 30 * 1000);

    useEffect(() => {
        pingCommCount();
    }, [props.Enquiry.id]);

   useEffect(()=>{
       
            const wiz = GetWorkflowFromStorage(props.Enquiry.id)
            if (wiz !== undefined) {
                setWizard(wiz)
            }
       
    }, [props.Enquiry.id]); 

    useEffect(()=>{
        if(wizard) {
            SaveWorkflowToStorage(wizard);
        }
    }, [wizard]);


    const saveWizard = (wiz: Wizard) => {
        setWizard(wiz);
    }

    const handleNewComm = async () => {
        const commResponse = await GetEnquiryCommunications(props.Enquiry.id);
        if (!commResponse.isOk) {
            throw new Error(`${commResponse.status} returned from server.`);
        }
        if (!commResponse.data) {
            return;
        }

        const comms = commResponse.data;
        sortComms(comms);

        if (SiteInformation.restrictedView) {
            // onShowLatestComms unmounts wiz in responsive
            SaveWorkflowToStorage({
                ...wizard,
                workflow: {
                    ...wizard.workflow,
                    data: {
                        ...wizard.workflow.data,
                        CommVerification: comms.length
                    }
                }
            });
        }
        setWizard(wiz => ({
            ...wiz,
            workflow: {
                ...wiz.workflow,
                data: {
                    ...wiz.workflow.data,
                    CommVerification: comms.length
                }
            }
        }));
        setIsPendingDismissed(false);

        const newEnq = {
            ...props.Enquiry,
            communications: comms
        }
        setLastCommunication(newEnq.id, 0);
        dispatch(updateEnquiriesCache([newEnq]));
        dispatch(setShowWizard(false));
    }

    const onCommVerificationFailed = async () => {
        setIsPendingDismissed(false);
        await pingCommCount();
    }

    const onNext = () => {
        setWizard( (currentWizard) => 
        {
            let wiz= JSON.parse(JSON.stringify(currentWizard));
            const nextStep = getNextStep(wiz.workflow.currentStep, wiz)!
            if (wiz.workflow.currentStep !== wiz.workflow.history.at(-1)?.stepKey)
                wiz
                    .workflow
                    .history
                    .push(
                        {
                            stepKey: wiz.workflow.currentStep,
                            summary: getSummary(wiz)
                        }
                    )
            wiz.workflow.currentStep = nextStep;
            return wiz;
        });
    }

    const onBack = () => {
        // pop and view
        setWizard(currentWizard => {
            let updatedValue: Wizard = JSON.parse(JSON.stringify(currentWizard));
            const prevStep = updatedValue.workflow.history.pop()?.stepKey!
            updatedValue.workflow.currentStep = prevStep
            setWizard(updatedValue);
            SaveWorkflowToStorage(updatedValue);
            return updatedValue;
        })
    }


        let element2: HTMLElement = document.getElementById('popOutWorkflowIcon')!
        if(element2!=null){
            element2.style.display="block";
        }

    return (
        < >
            {props.Popped && <div style={{
                height: "100%", width: "100%", zIndex: 2147483647,
                position: "absolute", top: "0px", left: "0px",
                display: "none", cursor: "wait",
                justifyContent: "center"
            }} id="loader">
                <div className='notice loading'>Still busy. Please wait...</div>
            </div>}
           { Object.keys(wizard).length>0 && <div style={{ marginLeft: "10px", display: props.FullWidth ? "block" : "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                <div key={"wokflowRow_" + props.Enquiry.id} style={{ display: "flex" , position: "relative", height: "100%", flex: "1" }} id={"wokflowRow_" + props.Enquiry.id} >
                    {activeConnection ? null : <NoConnection></NoConnection>}
                    <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={(e) => {
                                const wiz = GetWorkflowFromStorage(props.Enquiry.id);
                                if (wiz !== undefined) {
                                    setWizard(wiz);
                                }
                        }} 
                   >
                    <WizardContext.Provider value={{wizard , onNext, onBack, onCommVerificationFailed, setWizard}}>
                    {wizard!.workflow.currentStep === EStepKey.ENQUIRY_TRACKER_STEP && <EnquiryTracker
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.ENQUIRY_TRACKER_STEP}
                        Enquiry={props.Enquiry}
                    />}
                    {wizard!.workflow.currentStep === EStepKey.ENQUIRER_DETAILS_STEP && <EnquirerDetails
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.ENQUIRER_DETAILS_STEP}
                        Enquiry={props.Enquiry} 
                    />}
                    {wizard!.workflow.currentStep === EStepKey.MIS_STEP && <MIS
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.MIS_STEP}
                        Enquiry={props.Enquiry} 
                    />}
                    {wizard!.workflow.currentStep === EStepKey.ENQUIRY_ACTION_STEP && <EnquiryAction
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.ENQUIRY_ACTION_STEP}
                        wizardActionOption={wizard?.workflow?.data.EnquiryAction}
                        Enquiry={props.Enquiry} 
                    />}
                    {wizard!.workflow.currentStep === EStepKey.RESPOND_BY_EMAIL_STEP && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.RESPOND_BY_EMAIL_STEP}
                        Enquiry={props.Enquiry} 
                        ActionId={3}
                        Title="Response&nbsp;Email&nbsp;to&nbsp;Client"
                        StepKey={EStepKey.RESPOND_BY_EMAIL_STEP}
                        setEnquirerRecipient={true}
                    />}
                    {wizard!.workflow.currentStep === EStepKey.LINK_TO_ENQUIRY_STEP && <SearchEnquiryStep
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.LINK_TO_ENQUIRY_STEP}
                        Enquiry={props.Enquiry} 
                    />}
                    {wizard!.workflow.currentStep === EStepKey.WIZARD_SUMMARY_STEP && <FinishStep
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.WIZARD_SUMMARY_STEP}
                        Enquiry={props.Enquiry} 
                    />}

                    {wizard!.workflow.currentStep === EStepKey.CORRESPOND_BY_EMAIL_STEP && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.CORRESPOND_BY_EMAIL_STEP}
                        Enquiry={props.Enquiry} 
                        ActionId={2}
                        Title="Correspond with Enquirer Email"
                        StepKey={EStepKey.CORRESPOND_BY_EMAIL_STEP}
                        setEnquirerRecipient={true}
                    />}
                    {wizard!.workflow.currentStep === EStepKey.DIARISE && <Diarise
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.DIARISE}
                        Enquiry={props.Enquiry} 
                    />}
                    {wizard!.workflow.currentStep === EStepKey.FEEDBACK && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.FEEDBACK}
                        Enquiry={props.Enquiry} 
                        ActionId={11}
                        Title="Enquirer Feedback Email"
                        StepKey={EStepKey.FEEDBACK}
                        setEnquirerRecipient={true}
                    />}
                    {wizard.workflow.currentStep === EStepKey.INTERNAL_FEEDBACK && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.INTERNAL_FEEDBACK}
                        Enquiry={props.Enquiry} 
                        ActionId={5}
                        Title="Request Internal Feedback Email"
                        StepKey={EStepKey.INTERNAL_FEEDBACK}
                    />}
                    {wizard.workflow.currentStep === EStepKey.FOWARD_EMAIL && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.FOWARD_EMAIL}
                        Enquiry={props.Enquiry} 
                        ActionId={6}
                        Title="Forward Enquiry to another Department Email"
                        StepKey={EStepKey.FOWARD_EMAIL}
                    />}
                    {wizard.workflow.currentStep === EStepKey.ESCALATE_EMAIL && <ComposeEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.ESCALATE_EMAIL}
                        Enquiry={props.Enquiry} 
                        ActionId={7}
                        Title="Escalate to Manager Email"
                        StepKey={EStepKey.ESCALATE_EMAIL}
                    />}
                    {wizard.workflow.currentStep === EStepKey.COMMUNICATION_LOGGER && <CommunicationLogger
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.COMMUNICATION_LOGGER}
                        Enquiry={props.Enquiry}
                    />}
                    {wizard.workflow.currentStep === EStepKey.SELECT_EMAIL && <SelectEmail
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.SELECT_EMAIL}
                        Enquiry={props.Enquiry}
                    />}
                    {wizard.workflow.currentStep === EStepKey.ASSIGN_OTHER_USER && <AssignOtherUser
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.ASSIGN_OTHER_USER}
                        Enquiry={props.Enquiry}
                    />}
                    {wizard.workflow.currentStep === EStepKey.EXPLANATORY_NOTE && <ExplanatoryNote
                        Popped={props.Popped}
                        FullWidth={props.FullWidth}
                        key={EStepKey.EXPLANATORY_NOTE}
                        Enquiry={props.Enquiry}
                    />}
                    </WizardContext.Provider>
                    </ErrorBoundary>
                </div>
                {(commCount.isPendingProcessing && !isPendingDismissed) && <ToastDismissOverlay
                    key={`wizard-toast-${props.Enquiry.id}`}
                    onDismiss={() => setIsPendingDismissed(true)}
                    type="info"
                >
                    <>A new message has been received on this enquiry and is being processed. You will be notified when it is available.</>
                </ToastDismissOverlay>}
                {(hasNewComm && !commCount.isPendingProcessing) && <ToastDismissOverlay
                    key={`wizard-toast-${props.Enquiry.id}`}
                    onDismiss={handleNewComm}
                    type="info"
                    dismissLabel='Show'
                >
                    <>You have a new message on this enquiry.</>
                </ToastDismissOverlay>}
            </div>
        }
        </ >
    );
}

export const wizardMessage = <>
    All processing done in the wizard will be lost if you close it at this point.
    Are you sure that you wish to close this enquiry?
</>

export default Steps;




 
