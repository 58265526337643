
export const mimeTypes: any = {
    "123": "application/vnd.lotus-1-2-3",
    "3dml": "text/vnd.in3d.3dml",
    "3g2": "video/3gpp2",
    "3gp": "video/3gpp",
    "7z": "application/x-7z-compressed",
    "a": "application/octet-stream",
    "aab": "application/x-authorware-bin",
    "aac": "audio/x-aac",
    "aam": "application/x-authorware-map",
    "aas": "application/x-authorware-seg",
    "abs": "audio/x-mpeg",
    "abw": "application/x-abiword",
    "ac": "application/pkix-attr-cert",
    "ac3": "audio/vnd.dolby.dd-raw",
    "acc": "application/vnd.americandynamics.acc",
    "accdb": "application/msaccess",
    "accde": "application/msaccess.exec",
    "ace": "application/x-ace-compressed",
    "acu": "application/vnd.acucobol",
    "adp": "audio/adpcm",
    "adt": "audio/vnd.dlna.adts",
    "aep": "application/vnd.audiograph",
    "afp": "application/vnd.ibm.modcap",
    "ahead": "application/vnd.ahead.space",
    "ai": "application/postscript",
    "aif": "audio/x-aiff",
    "aifc": "audio/x-aiff",
    "aiff": "audio/x-aiff",
    "aim": "application/x-aim",
    "air": "application/vnd.adobe.air-application-installer-package+zip",
    "ait": "application/vnd.dvb.ait",
    "ami": "application/vnd.amiga.ami",
    "anx": "application/annodex",
    "apk": "application/vnd.android.package-archive",
    "application": "application/x-ms-application",
    "apr": "application/vnd.lotus-approach",
    "arc": "application/x-freearc",
    "asf": "video/x-ms-asf",
    "asm": "text/x-asm",
    "aso": "application/vnd.accpac.simply.aso",
    "aspx": "application/xml",
    "ass": "audio/aac",
    "asx": "video/x-ms-asf",
    "atc": "application/vnd.acucorp",
    "atom": "application/atom+xml",
    "atomcat": "application/atomcat+xml",
    "atomsvc": "application/atomsvc+xml",
    "atx": "application/vnd.antix.game-component",
    "au": "audio/basic",
    "avi": "video/x-msvideo",
    "avif": "image/avif",
    "avx": "video/x-rad-screenplay",
    "aw": "application/applixware",
    "axa": "audio/annodex",
    "axv": "video/annodex",
    "azf": "application/vnd.airzip.filesecure.azf",
    "azs": "application/vnd.airzip.filesecure.azs",
    "azw": "application/vnd.amazon.ebook",
    "bat": "application/x-msdownload",
    "bcpio": "application/x-bcpio",
    "bdf": "application/x-font-bdf",
    "bdm": "application/vnd.syncml.dm+wbxml",
    "bed": "application/vnd.realvnc.bed",
    "bh2": "application/vnd.fujitsu.oasysprs",
    "bin": "application/octet-stream",
    "bmi": "application/vnd.bmi",
    "bmp": "image/bmp",
    "body": "text/html",
    "box": "application/vnd.previewsystems.box",
    "btif": "image/prs.btif",
    "bz": "application/x-bzip",
    "bz2": "application/x-bzip2",
    "c": "text/x-c",
    "c11amc": "application/vnd.cluetrust.cartomobile-config",
    "c11amz": "application/vnd.cluetrust.cartomobile-config-pkg",
    "c4g": "application/vnd.clonk.c4group",
    "cab": "application/vnd.ms-cab-compressed",
    "cap": "image/cap",
    "car": "application/vnd.curl.car",
    "cat": "application/vnd.ms-pki.seccat",
    "cc": "text/x-c",
    "ccxml": "application/ccxml+xml",
    "cda": "application/x-cdf",
    "cdbcmsg": "application/vnd.contact.cmsg",
    "cdf": "application/x-netcdf",
    "cdkey": "application/vnd.mediastation.cdkey",
    "cdmia": "application/cdmi-capability",
    "cdmic": "application/cdmi-container",
    "cdmid": "application/cdmi-domain",
    "cdmio": "application/cdmi-object",
    "cdmiq": "application/cdmi-queue",
    "cdx": "chemical/x-cdx",
    "cdxml": "application/vnd.chemdraw+xml",
    "cdy": "application/vnd.cinderella",
    "cer": "application/pkix-cert",
    "cgm": "image/cgm",
    "chat": "application/x-chat",
    "chm": "application/vnd.ms-htmlhelp",
    "chrt": "application/vnd.kde.kchart",
    "cif": "chemical/x-cif",
    "cii": "application/vnd.anser-web-certificate-issue-initiation",
    "cil": "application/vnd.ms-artgalry",
    "cla": "application/vnd.claymore",
    "class": "application/java-vm",
    "clkk": "application/vnd.crick.clicker.keyboard",
    "clkp": "application/vnd.crick.clicker.palette",
    "clkt": "application/vnd.crick.clicker.template",
    "clkw": "application/vnd.crick.clicker.wordbank",
    "clkx": "application/vnd.crick.clicker",
    "clp": "application/x-msclip",
    "cmc": "application/vnd.cosmocaller",
    "cmdf": "chemical/x-cmdf",
    "cml": "chemical/x-cml",
    "cmp": "application/vnd.yellowriver-custom-menu",
    "cmx": "image/x-cmx",
    "cod": "application/vnd.rim.cod",
    "cpio": "application/x-cpio",
    "cpp": "text/x-c",
    "cpt": "application/mac-compactpro",
    "cr2": "image/CR2",
    "crd": "application/x-mscardfile",
    "crl": "application/pkix-crl",
    "crt": "application/x-x509-ca-cert",
    "cryptonote": "application/vnd.rig.cryptonote",
    "cs": "text/plain",
    "csh": "application/x-csh",
    "csml": "chemical/x-csml",
    "csp": "application/vnd.commonspace",
    "csproj": "application/xml",
    "css": "text/css",
    "csv": "application/vnd.ms-excel",
    "cu": "application/cu-seeme",
    "curl": "text/vnd.curl",
    "cww": "application/prs.cww",
    "cxx": "text/x-c",
    "dae": "model/vnd.collada+xml",
    "daf": "application/vnd.mobius.daf",
    "davmount": "application/davmount+xml",
    "dcurl": "text/vnd.curl.dcurl",
    "dd2": "application/vnd.oma.dd2+xml",
    "ddd": "application/vnd.fujixerox.ddd",
    "dds": "image/vnd.ms-dds",
    "deb": "application/x-debian-package",
    "def": "text/plain",
    "der": "application/x-x509-ca-cert",
    "dfac": "application/vnd.dreamfactory",
    "dib": "image/bmp",
    "dir": "application/x-director",
    "dis": "application/vnd.mobius.dis",
    "djvu": "image/vnd.djvu",
    "dll": "application/x-msdownload",
    "dmg": "application/x-apple-diskimage",
    "dna": "application/vnd.dna",
    "dng": "image/DNG",
    "doc": "application/msword",
    "docm": "application/vnd.ms-word.document.macroenabled.12",
    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "dot": "application/msword",
    "dotm": "application/vnd.ms-word.template.macroenabled.12",
    "dotx": "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "dp": "application/vnd.osgi.dp",
    "dpg": "application/vnd.dpgraph",
    "dra": "audio/vnd.dra",
    "dsc": "text/prs.lines.tag",
    "dssc": "application/dssc+der",
    "dtb": "application/x-dtbook+xml",
    "dtd": "application/xml-dtd",
    "dts": "audio/vnd.dts",
    "dtshd": "audio/vnd.dts.hd",
    "dv": "video/x-dv",
    "dvi": "application/x-dvi",
    "dvr-ms": "video/x-ms-dvr",
    "dwf": "model/vnd.dwf",
    "dwg": "image/vnd.dwg",
    "dxf": "image/vnd.dxf",
    "dxp": "application/vnd.spotfire.dxp",
    "ecelp4800": "audio/vnd.nuera.ecelp4800",
    "ecelp7470": "audio/vnd.nuera.ecelp7470",
    "ecelp9600": "audio/vnd.nuera.ecelp9600",
    "edm": "application/vnd.novadigm.edm",
    "edx": "application/vnd.novadigm.edx",
    "efif": "application/vnd.picsel",
    "ei6": "application/vnd.pg.osasli",
    "emf": "application/x-msmetafile",
    "eml": "message/rfc822",
    "emma": "application/emma+xml",
    "eol": "audio/vnd.digital-winds",
    "eot": "application/vnd.ms-fontobject",
    "eps": "application/postscript",
    "epub": "application/epub+zip",
    "es": "application/ecmascript",
    "es3": "application/vnd.eszigno3+xml",
    "esf": "application/vnd.epson.esf",
    "etx": "text/x-setext",
    "exe": "application/x-msdownload",
    "exi": "application/exi",
    "ext": "application/vnd.novadigm.ext",
    "ez": "application/andrew-inset",
    "ez2": "application/vnd.ezpix-album",
    "ez3": "application/vnd.ezpix-package",
    "f": "text/x-fortran",
    "f4v": "video/x-f4v",
    "fbs": "image/vnd.fastbidsheet",
    "fcs": "application/vnd.isac.fcs",
    "fdf": "application/vnd.fdf",
    "fe_launch": "application/vnd.denovo.fcselayout-link",
    "fg5": "application/vnd.fujitsu.oasysgp",
    "fh": "image/x-freehand",
    "fig": "application/x-xfig",
    "flac": "audio/x-flac",
    "fli": "video/x-fli",
    "flo": "application/vnd.micrografx.flo",
    "flv": "video/x-flv",
    "flw": "application/vnd.kde.kivio",
    "flx": "text/vnd.fmi.flexstor",
    "fly": "text/vnd.fly",
    "fm": "application/vnd.framemaker",
    "fnc": "application/vnd.frogans.fnc",
    "fpx": "image/vnd.fpx",
    "fsc": "application/vnd.fsc.weblaunch",
    "fst": "image/vnd.fst",
    "ftc": "application/vnd.fluxtime.clip",
    "fti": "application/vnd.anser-web-funds-transfer-initiation",
    "fvt": "video/vnd.fvt",
    "fx": "text/plain",
    "fxp": "application/vnd.adobe.fxp",
    "fzs": "application/vnd.fuzzysheet",
    "g2w": "application/vnd.geoplan",
    "g3": "image/g3fax",
    "g3w": "application/vnd.geospace",
    "gac": "application/vnd.groove-account",
    "gdl": "model/vnd.gdl",
    "geo": "application/vnd.dynageo",
    "gex": "application/vnd.geometry-explorer",
    "ggb": "application/vnd.geogebra.file",
    "ggt": "application/vnd.geogebra.tool",
    "ghf": "application/vnd.groove-help",
    "gif": "image/gif",
    "gim": "application/vnd.groove-identity-message",
    "gmx": "application/vnd.gmx",
    "gnumeric": "application/x-gnumeric",
    "gph": "application/vnd.flographit",
    "gpx": "application/gpx+xml",
    "gqf": "application/vnd.grafeq",
    "gram": "application/srgs",
    "grv": "application/vnd.groove-injector",
    "grxml": "application/srgs+xml",
    "gsf": "application/x-font-ghostscript",
    "gtar": "application/x-gtar",
    "gtm": "application/vnd.groove-tool-message",
    "gtw": "model/vnd.gtw",
    "gv": "text/vnd.graphviz",
    "gxt": "application/vnd.geonext",
    "gz": "application/gzip",
    "gz.tar.gz": "application/x-tar",
    "h": "text/x-c",
    "h261": "video/h261",
    "h263": "video/h263",
    "h264": "video/h264",
    "h5": "application/x-hdf5",
    "hal": "application/vnd.hal+xml",
    "hbci": "application/vnd.hbci",
    "hdf": "application/x-hdf",
    "hlp": "application/winhlp",
    "hpgl": "application/vnd.hp-hpgl",
    "hpid": "application/vnd.hp-hpid",
    "hpp": "text/plain",
    "hps": "application/vnd.hp-hps",
    "hqx": "application/mac-binhex40",
    "hta": "application/hta",
    "htc": "text/x-component",
    "htke": "application/vnd.kenameaapp",
    "htm": "text/html",
    "html": "text/html",
    "hvd": "application/vnd.yamaha.hv-dic",
    "hvp": "application/vnd.yamaha.hv-voice",
    "hvs": "application/vnd.yamaha.hv-script",
    "hxx": "text/plain",
    "i": "text/plain",
    "i2g": "application/vnd.intergeo",
    "icc": "application/vnd.iccprofile",
    "ice": "x-conference/x-cooltalk",
    "ico": "image/vnd.microsoft.icon",
    "ics": "text/calendar",
    "ief": "image/ief",
    "ifm": "application/vnd.shana.informed.formdata",
    "igl": "application/vnd.igloader",
    "igm": "application/vnd.insors.igm",
    "igs": "model/iges",
    "igx": "application/vnd.micrografx.igx",
    "iif": "application/vnd.shana.informed.interchange",
    "imp": "application/vnd.accpac.simply.imp",
    "ims": "application/vnd.ms-ims",
    "inc": "text/plain",
    "ipfix": "application/ipfix",
    "ipk": "application/vnd.shana.informed.package",
    "irm": "application/vnd.ibm.rights-management",
    "irp": "application/vnd.irepository.package+xml",
    "itp": "application/vnd.shana.informed.formtemplate",
    "ivp": "application/vnd.immervision-ivp",
    "ivu": "application/vnd.immervision-ivu",
    "jad": "text/vnd.sun.j2me.app-descriptor",
    "jam": "application/vnd.jam",
    "jar": "application/java-archive",
    "java": "text/x-java-source",
    "jfif": "image/jpeg",
    "jisp": "application/vnd.jisp",
    "jlt": "application/vnd.hp-jlyt",
    "jnlp": "application/x-java-jnlp-file",
    "joda": "application/vnd.joost.joda-archive",
    "jpe": "image/jpeg",
    "jpeg": "image/jpeg",
    "jpg": "image/jpeg",
    "jpgv": "video/jpeg",
    "jpm": "image/jpm",
    "js": "application/javascript",
    "jsf": "text/plain",
    "json": "application/json",
    "jsonld": "application/ld+json",
    "jspf": "text/plain",
    "kar": "audio/midi",
    "karbon": "application/vnd.kde.karbon",
    "kfo": "application/vnd.kde.kformula",
    "kia": "application/vnd.kidspiration",
    "kml": "application/vnd.google-earth.kml+xml",
    "kmz": "application/vnd.google-earth.kmz",
    "kne": "application/vnd.kinar",
    "kon": "application/vnd.kde.kontour",
    "kpr": "application/vnd.kde.kpresenter",
    "ksp": "application/vnd.kde.kspread",
    "ktx": "image/ktx",
    "ktz": "application/vnd.kahootz",
    "kwd": "application/vnd.kde.kword",
    "lasxml": "application/vnd.las.las+xml",
    "latex": "application/x-latex",
    "lbd": "application/vnd.llamagraphics.life-balance.desktop",
    "lbe": "application/vnd.llamagraphics.life-balance.exchange+xml",
    "les": "application/vnd.hhe.lesson-player",
    "link66": "application/vnd.route66.link66+xml",
    "lrm": "application/vnd.ms-lrm",
    "ltf": "application/vnd.frogans.ltf",
    "lvp": "audio/vnd.lucent.voice",
    "lwp": "application/vnd.lotus-wordpro",
    "m21": "application/mp21",
    "m3u": "audio/x-mpegurl",
    "m3u8": "application/vnd.apple.mpegurl",
    "m4v": "video/x-m4v",
    "ma": "application/mathematica",
    "mads": "application/mads+xml",
    "mag": "application/vnd.ecowin.chart",
    "man": "text/troff",
    "map": "application/json",
    "mathml": "application/mathml+xml",
    "mbk": "application/vnd.mobius.mbk",
    "mbox": "application/mbox",
    "mc1": "application/vnd.medcalcdata",
    "mcd": "application/vnd.mcd",
    "mcurl": "text/vnd.curl.mcurl",
    "mda": "application/msaccess",
    "mdb": "application/x-msaccess",
    "mde": "application/msaccess",
    "mdi": "image/vnd.ms-modi",
    "me": "text/troff",
    "meta4": "application/metalink4+xml",
    "mets": "application/mets+xml",
    "mfm": "application/vnd.mfmp",
    "mgp": "application/vnd.osgeo.mapguide.package",
    "mgz": "application/vnd.proteus.magazine",
    "mht": "message/rfc822",
    "mhtml": "message/rfc822",
    "mid": "audio/midi",
    "midi": "audio/midi",
    "mif": "application/vnd.mif",
    "mj2": "video/mj2",
    "mjs": "application/javascript",
    "mkv": "video/x-matroska",
    "mlp": "application/vnd.dolby.mlp",
    "mmd": "application/vnd.chipnuts.karaoke-mmd",
    "mmf": "application/vnd.smaf",
    "mmr": "image/vnd.fujixerox.edmics-mmr",
    "mny": "application/x-msmoney",
    "mod": "video/mpeg",
    "mods": "application/mods+xml",
    "mov": "video/quicktime",
    "movie": "video/x-sgi-movie",
    "mp1": "audio/mpeg",
    "mp2": "audio/mpeg",
    "mp3": "audio/mp3",
    "mp4": "video/mp4",
    "mp4a": "audio/mp4",
    "mpa": "audio/mpeg",
    "mpc": "application/vnd.mophun.certificate",
    "mpe": "video/mpeg",
    "mpeg": "video/mpeg",
    "mpega": "audio/x-mpeg",
    "mpg": "video/mpeg",
    "mpga": "audio/mpeg",
    "mpkg": "application/vnd.apple.installer+xml",
    "mpm": "application/vnd.blueice.multipass",
    "mpn": "application/vnd.mophun.application",
    "mpp": "application/dash-patch+xml",
    "mpv2": "video/mpeg2",
    "mpy": "application/vnd.ibm.minipay",
    "mqy": "application/vnd.mobius.mqy",
    "mrc": "application/marc",
    "mrcx": "application/marcxml+xml",
    "mscml": "application/mediaservercontrol+xml",
    "mseq": "application/vnd.mseq",
    "msf": "application/vnd.epson.msf",
    "msh": "model/mesh",
    "msl": "application/vnd.mobius.msl",
    "msty": "application/vnd.muvee.style",
    "mts": "model/vnd.mts",
    "mus": "application/vnd.musician",
    "musicxml": "application/vnd.recordare.musicxml+xml",
    "mvb": "application/x-msmediaview",
    "mwf": "application/vnd.mfer",
    "mxf": "application/mxf",
    "mxl": "application/vnd.recordare.musicxml",
    "mxml": "application/xv+xml",
    "mxs": "application/vnd.triscape.mxs",
    "mxu": "video/vnd.mpegurl",
    "n-gage": "application/vnd.nokia.n-gage.symbian.install",
    "n3": "text/n3",
    "nbp": "application/vnd.wolfram.player",
    "nc": "application/x-netcdf",
    "ncx": "application/x-dtbncx+xml",
    "ngdat": "application/vnd.nokia.n-gage.data",
    "nlu": "application/vnd.neurolanguage.nlu",
    "nml": "application/vnd.enliven",
    "nnd": "application/vnd.noblenet-directory",
    "nns": "application/vnd.noblenet-sealer",
    "nnw": "application/vnd.noblenet-web",
    "npx": "image/vnd.net-fpx",
    "nq": "application/n-quads",
    "nsf": "application/vnd.lotus-notes",
    "nt": "application/n-triples",
    "o": "application/octet-stream",
    "oa2": "application/vnd.fujitsu.oasys2",
    "oa3": "application/vnd.fujitsu.oasys3",
    "oas": "application/vnd.fujitsu.oasys",
    "obd": "application/x-msbinder",
    "obj": "application/x-tgif",
    "oda": "application/oda",
    "odb": "application/vnd.oasis.opendocument.database",
    "odc": "application/vnd.oasis.opendocument.chart",
    "odf": "application/vnd.oasis.opendocument.formula",
    "odft": "application/vnd.oasis.opendocument.formula-template",
    "odg": "application/vnd.oasis.opendocument.graphics",
    "odi": "application/vnd.oasis.opendocument.image",
    "odm": "application/vnd.oasis.opendocument.text-master",
    "odp": "application/vnd.oasis.opendocument.presentation",
    "ods": "application/vnd.oasis.opendocument.spreadsheet",
    "odt": "application/vnd.oasis.opendocument.text",
    "oga": "audio/ogg",
    "ogg": "audio/ogg",
    "ogv": "video/ogg",
    "ogx": "application/ogg",
    "onetoc": "application/onenote",
    "opf": "application/oebps-package+xml",
    "opus": "audio/ogg",
    "org": "application/vnd.lotus-organizer",
    "osf": "application/vnd.yamaha.openscoreformat",
    "osfpvg": "application/vnd.yamaha.openscoreformat.osfpvg+xml",
    "otc": "application/vnd.oasis.opendocument.chart-template",
    "otf": "font/otf",
    "otg": "application/vnd.oasis.opendocument.graphics-template",
    "oth": "application/vnd.oasis.opendocument.text-web",
    "oti": "application/vnd.oasis.opendocument.image-template",
    "otp": "application/vnd.oasis.opendocument.presentation-template",
    "ots": "application/vnd.oasis.opendocument.spreadsheet-template",
    "ott": "application/vnd.oasis.opendocument.text-template",
    "oxt": "application/vnd.openofficeorg.extension",
    "p": "text/x-pascal",
    "p10": "application/pkcs10",
    "p12": "application/x-pkcs12",
    "p7b": "application/x-pkcs7-certificates",
    "p7c": "application/pkcs7-mime",
    "p7m": "application/pkcs7-mime",
    "p7r": "application/x-pkcs7-certreqresp",
    "p7s": "application/pkcs7-signature",
    "p8": "application/pkcs8",
    "par": "text/plain-bas",
    "paw": "application/vnd.pawaafile",
    "pbd": "application/vnd.powerbuilder6",
    "pbm": "image/x-portable-bitmap",
    "pcf": "application/x-font-pcf",
    "pcl": "application/vnd.hp-pcl",
    "pclxl": "application/vnd.hp-pclxl",
    "pct": "image/x-pict",
    "pcurl": "application/vnd.curl.pcurl",
    "pcx": "image/vnd.zbrush.pcx",
    "pdb": "application/vnd.palm",
    "pdf": "application/pdf",
    "pfa": "application/x-font-type1",
    "pfr": "application/font-tdpfr",
    "pfx": "application/x-pkcs12",
    "pgm": "image/x-portable-graymap",
    "pgn": "application/x-chess-pgn",
    "pgp": "application/pgp-encrypted",
    "pic": "image/x-pict",
    "pict": "image/pict",
    "pjpeg": "image/pjpeg",
    "pki": "application/pkixcmp",
    "pkipath": "application/pkix-pkipath",
    "pl": "application/x-perl",
    "plb": "application/vnd.3gpp.pic-bw-large",
    "plc": "application/vnd.mobius.plc",
    "plf": "application/vnd.pocketlearn",
    "pls": "application/pls+xml",
    "pml": "application/vnd.ctc-posml",
    "png": "image/png",
    "pnm": "image/x-portable-anymap",
    "pnt": "image/x-macpaint",
    "portpkg": "application/vnd.macports.portpkg",
    "pot": "application/vnd.ms-powerpoint",
    "potm": "application/vnd.ms-powerpoint.template.macroenabled.12",
    "potx": "application/vnd.openxmlformats-officedocument.presentationml.template",
    "ppam": "application/vnd.ms-powerpoint.addin.macroenabled.12",
    "ppd": "application/vnd.cups-ppd",
    "ppm": "image/x-portable-pixmap",
    "pps": "application/vnd.ms-powerpoint",
    "ppsm": "application/vnd.ms-powerpoint.slideshow.macroenabled.12",
    "ppsx": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "ppt": "application/vnd.ms-powerpoint",
    "pptm": "application/vnd.ms-powerpoint.presentation.macroenabled.12",
    "pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "prc": "application/x-mobipocket-ebook",
    "pre": "application/vnd.lotus-freelance",
    "prf": "application/pics-rules",
    "ps": "application/postscript",
    "psb": "application/vnd.3gpp.pic-bw-small",
    "psc1": "application/PowerShell",
    "psd": "image/vnd.adobe.photoshop",
    "psf": "application/x-font-linux-psf",
    "pskcxml": "application/pskc+xml",
    "ptid": "application/vnd.pvi.ptid1",
    "ptx": "image/PTX",
    "pub": "application/x-mspublisher",
    "pvb": "application/vnd.3gpp.pic-bw-var",
    "pwn": "application/vnd.3m.post-it-notes",
    "py": "text/x-python",
    "pya": "audio/vnd.ms-playready.media.pya",
    "pyc": "application/x-python-code",
    "pyo": "application/x-python-code",
    "pyv": "video/vnd.ms-playready.media.pyv",
    "pyw": "text/x-python",
    "qam": "application/vnd.epson.quickanime",
    "qbo": "application/vnd.intu.qbo",
    "qfx": "application/vnd.intu.qfx",
    "qps": "application/vnd.publishare-delta-tree",
    "qt": "video/quicktime",
    "qti": "image/x-quicktime",
    "qtif": "image/x-quicktime",
    "qxd": "application/vnd.quark.quarkxpress",
    "ra": "audio/x-pn-realaudio",
    "ram": "audio/x-pn-realaudio",
    "rar": "application/vnd.rar",
    "ras": "image/x-cmu-raster",
    "raw": "image/RAW",
    "rc": "text/plain",
    "rc2": "text/plain",
    "rcprofile": "application/vnd.ipunplugged.rcprofile",
    "rct": "text/plain",
    "rdf": "application/rdf+xml",
    "rdz": "application/vnd.data-vision.rdz",
    "rep": "application/vnd.businessobjects",
    "res": "application/x-dtbresource+xml",
    "resx": "application/xml",
    "rgb": "image/x-rgb",
    "rif": "application/reginfo+xml",
    "rip": "audio/vnd.rip",
    "rl": "application/resource-lists+xml",
    "rlc": "image/vnd.fujixerox.edmics-rlc",
    "rld": "application/resource-lists-diff+xml",
    "rm": "application/vnd.rn-realmedia",
    "rmp": "audio/x-pn-realaudio-plugin",
    "rms": "application/vnd.jcp.javame.midlet-rms",
    "rnc": "application/relax-ng-compact-syntax",
    "roff": "text/troff",
    "rp9": "application/vnd.cloanto.rp9",
    "rpss": "application/vnd.nokia.radio-presets",
    "rpst": "application/vnd.nokia.radio-preset",
    "rq": "application/sparql-query",
    "rs": "application/rls-services+xml",
    "rsd": "application/rsd+xml",
    "rss": "application/rss+xml",
    "rtf": "application/rtf",
    "rtx": "text/richtext",
    "s": "text/x-asm",
    "saf": "application/vnd.yamaha.smaf-audio",
    "sbml": "application/sbml+xml",
    "sc": "application/vnd.ibm.secure-container",
    "scd": "application/x-msschedule",
    "scm": "application/vnd.lotus-screencam",
    "scq": "application/scvp-cv-request",
    "scs": "application/scvp-cv-response",
    "sct": "text/scriptlet",
    "scurl": "text/vnd.curl.scurl",
    "sda": "application/vnd.stardivision.draw",
    "sdc": "application/vnd.stardivision.calc",
    "sdd": "application/vnd.stardivision.impress",
    "sdkm": "application/vnd.solent.sdkm+xml",
    "sdp": "application/sdp",
    "sdw": "application/vnd.stardivision.writer",
    "see": "application/vnd.seemail",
    "seed": "application/vnd.fdsn.seed",
    "sema": "application/vnd.sema",
    "semd": "application/vnd.semd",
    "semf": "application/vnd.semf",
    "ser": "application/java-serialized-object",
    "setpay": "application/set-payment-initiation",
    "setreg": "application/set-registration-initiation",
    "sfd-hdstx": "application/vnd.hydrostatix.sof-data",
    "sfnt": "application/font-sfnt",
    "sfs": "application/vnd.spotfire.sfs",
    "sgl": "application/vnd.stardivision.writer-global",
    "sgm": "text/sgml",
    "sgml": "text/sgml",
    "sh": "application/x-sh",
    "shar": "application/x-shar",
    "shf": "application/shf+xml",
    "sis": "application/vnd.symbian.install",
    "sit": "application/x-stuffit",
    "sitx": "application/x-stuffitx",
    "skp": "application/vnd.koan",
    "sldm": "application/vnd.ms-powerpoint.slide.macroenabled.12",
    "sldx": "application/vnd.openxmlformats-officedocument.presentationml.slide",
    "slk": "application/vnd.ms-excel",
    "sln": "text/plain",
    "slt": "application/vnd.epson.salt",
    "sm": "application/vnd.stepmania.stepchart",
    "smf": "application/vnd.stardivision.math",
    "smi": "application/smil+xml",
    "snd": "audio/basic",
    "snf": "application/x-font-snf",
    "so": "application/octet-stream",
    "spc": "application/x-pkcs7-certificates",
    "spf": "application/vnd.yamaha.smaf-phrase",
    "spl": "application/x-futuresplash",
    "spot": "text/vnd.in3d.spot",
    "spp": "application/scvp-vp-response",
    "spq": "application/scvp-vp-request",
    "spx": "audio/ogg",
    "src": "application/x-wais-source",
    "srt": "application/x-subrip",
    "sru": "application/sru+xml",
    "srx": "application/sparql-results+xml",
    "sse": "application/vnd.kodak-descriptor",
    "ssf": "application/vnd.epson.ssf",
    "ssml": "application/ssml+xml",
    "st": "application/vnd.sailingtracker.track",
    "stc": "application/vnd.sun.xml.calc.template",
    "std": "application/vnd.sun.xml.draw.template",
    "stf": "application/vnd.wt.stf",
    "sti": "application/vnd.sun.xml.impress.template",
    "stk": "application/hyperstudio",
    "stl": "application/vnd.ms-pki.stl",
    "str": "application/vnd.pg.format",
    "stw": "application/vnd.sun.xml.writer.template",
    "sub": "image/vnd.dvb.subtitle",
    "sus": "application/vnd.sus-calendar",
    "sv4cpio": "application/x-sv4cpio",
    "sv4crc": "application/x-sv4crc",
    "svc": "application/vnd.dvb.service",
    "svd": "application/vnd.svd",
    "svg": "image/svg+xml",
    "svgz": "image/svg+xml",
    "swf": "application/x-shockwave-flash",
    "swi": "application/vnd.aristanetworks.swi",
    "sxc": "application/vnd.sun.xml.calc",
    "sxd": "application/vnd.sun.xml.draw",
    "sxg": "application/vnd.sun.xml.writer.global",
    "sxi": "application/vnd.sun.xml.impress",
    "sxm": "application/vnd.sun.xml.math",
    "sxw": "application/vnd.sun.xml.writer",
    "t": "text/troff",
    "tao": "application/vnd.tao.intent-module-archive",
    "tar": "application/x-tar",
    "tcap": "application/vnd.3gpp2.tcap",
    "tcl": "application/x-tcl",
    "teacher": "application/vnd.smart.teacher",
    "tei": "application/tei+xml",
    "tex": "application/x-tex",
    "texi": "application/x-texinfo",
    "texinfo": "application/x-texinfo",
    "tfi": "application/thraud+xml",
    "tfm": "application/x-tex-tfm",
    "thmx": "application/vnd.ms-officetheme",
    "tif": "image/tiff",
    "tiff": "image/tiff",
    "tmo": "application/vnd.tmobile-livetv",
    "torrent": "application/x-bittorrent",
    "tpl": "application/vnd.groove-tool-template",
    "tpt": "application/vnd.trid.tpt",
    "tr": "text/troff",
    "tra": "application/vnd.trueapp",
    "trm": "application/x-msterminal",
    "ts": "video/mp2t",
    "tsd": "application/timestamped-data",
    "tsv": "text/tab-separated-values",
    "ttf": "font/ttf",
    "ttl": "text/turtle",
    "twd": "application/vnd.simtech-mindmapper",
    "txd": "application/vnd.genomatix.tuxedo",
    "txf": "application/vnd.mobius.txf",
    "txt": "text/plain",
    "txt.script.txt": "text/plain",
    "ufd": "application/vnd.ufdl",
    "ulw": "audio/basic",
    "umj": "application/vnd.umajin",
    "unityweb": "application/vnd.unity",
    "uoml": "application/vnd.uoml+xml",
    "uri": "text/uri-list",
    "ustar": "application/x-ustar",
    "utz": "application/vnd.uiq.theme",
    "uu": "text/x-uuencode",
    "uva": "audio/vnd.dece.audio",
    "uvh": "video/vnd.dece.hd",
    "uvi": "image/vnd.dece.graphic",
    "uvm": "video/vnd.dece.mobile",
    "uvp": "video/vnd.dece.pd",
    "uvs": "video/vnd.dece.sd",
    "uvu": "video/vnd.uvvu.mp4",
    "uvv": "video/vnd.dece.video",
    "vb": "text/plain",
    "vbproj": "application/xml",
    "vcd": "application/x-cdlink",
    "vcf": "text/x-vcard",
    "vcg": "application/vnd.groove-vcard",
    "vcproj": "Application/xml",
    "vcs": "text/x-vcalendar",
    "vcx": "application/vnd.vcx",
    "vis": "application/vnd.visionary",
    "viv": "video/vnd.vivo",
    "vsd": "application/vnd.visio",
    "vsdx": "application/vnd.ms-visio.viewer",
    "vsf": "application/vnd.vsf",
    "vst": "application/vnd.visio",
    "vtu": "model/vnd.vtu",
    "vxml": "application/voicexml+xml",
    "wad": "application/x-doom",
    "wav": "audio/wav",
    "wax": "audio/x-ms-wax",
    "wbmp": "image/vnd.wap.wbmp",
    "wbs": "application/vnd.criticaltools.wbs+xml",
    "wbxml": "application/vnd.wap.wbxml",
    "weba": "audio/webm",
    "webm": "video/webm",
    "webp": "image/webp",
    "wg": "application/vnd.pmi.widget",
    "wgt": "application/widget",
    "wm": "video/x-ms-wm",
    "wma": "audio/x-ms-wma",
    "wmd": "application/x-ms-wmd",
    "wmf": "application/x-msmetafile",
    "wml": "text/vnd.wap.wml",
    "wmlc": "application/vnd.wap.wmlc",
    "wmls": "text/vnd.wap.wmlscript",
    "wmlsc": "application/vnd.wap.wmlscriptc",
    "wmv": "video/x-ms-wmv",
    "wmx": "video/x-ms-wmx",
    "wmz": "application/x-ms-wmz",
    "woff": "font/woff",
    "woff2": "font/woff2",
    "wpd": "application/vnd.wordperfect",
    "wpl": "application/vnd.ms-wpl",
    "wps": "application/vnd.ms-works",
    "wqd": "application/vnd.wqd",
    "wri": "application/x-mswrite",
    "wrl": "model/vrml",
    "wsc": "message/vnd.wfa.wsc",
    "wsdl": "application/wsdl+xml",
    "wspolicy": "application/wspolicy+xml",
    "wtb": "application/vnd.webturbo",
    "wvx": "video/x-ms-wvx",
    "x3d": "model/x3d+xml",
    "xaml": "application/xaml+xml",
    "xap": "application/x-silverlight-app",
    "xar": "application/vnd.xara",
    "xbap": "application/x-ms-xbap",
    "xbd": "application/vnd.fujixerox.docuworks.binder",
    "xbm": "image/x-xbitmap",
    "xdf": "application/xcap-diff+xml",
    "xdm": "application/vnd.syncml.dm+xml",
    "xdp": "application/vnd.adobe.xdp+xml",
    "xdssc": "application/dssc+xml",
    "xdw": "application/vnd.fujixerox.docuworks",
    "xenc": "application/xenc+xml",
    "xer": "application/patch-ops-error+xml",
    "xfdf": "application/vnd.adobe.xfdf",
    "xfdl": "application/vnd.xfdl",
    "xht": "application/xhtml+xml",
    "xhtml": "application/xhtml+xml",
    "xif": "image/vnd.xiff",
    "xlam": "application/vnd.ms-excel.addin.macroenabled.12",
    "xlk": "application/vnd.ms-excel",
    "xls": "application/vnd.ms-excel",
    "xlsb": "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "xlsm": "application/vnd.ms-excel.sheet.macroenabled.12",
    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "xlt": "application/vnd.ms-excel",
    "xltm": "application/vnd.ms-excel.template.macroenabled.12",
    "xltx": "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "xlw": "application/vnd.ms-excel",
    "xml": "application/xml",
    "xo": "application/vnd.olpc-sugar",
    "xop": "application/xop+xml",
    "xpi": "application/x-xpinstall",
    "xpm": "image/x-xpixmap",
    "xpr": "application/vnd.is-xpr",
    "xps": "application/vnd.ms-xpsdocument",
    "xpw": "application/vnd.intercon.formnet",
    "xsl": "application/xml",
    "xslt": "application/xslt+xml",
    "xsm": "application/vnd.syncml+xml",
    "xspf": "application/xspf+xml",
    "xul": "application/vnd.mozilla.xul+xml",
    "xwd": "image/x-xwindowdump",
    "xyz": "chemical/x-xyz",
    "yaml": "text/yaml",
    "yang": "application/yang",
    "yin": "application/yin+xml",
    "z": "application/x-compress",
    "zaz": "application/vnd.zzazz.deck+xml",
    "zip": "application/zip",
    "zir": "application/vnd.zul",
    "zmm": "application/vnd.handheld-entertainment+xml",
    "1km": "application/vnd.1000minds.decision-model+xml",
    "3ds": "image/x-3ds",
    "3gpp": "audio/3gpp",
    "3mf": "model/3mf",
    "acutc": "application/vnd.acucorp",
    "afm": "application/x-font-type1",
    "age": "application/vnd.age",
    "amr": "audio/amr",
    "apng": "image/apng",
    "appcache": "text/cache-manifest",
    "arj": "application/x-arj",
    "asc": "application/pgp-keys",
    "avci": "image/avci",
    "avcs": "image/avcs",
    "azv": "image/vnd.airzip.accelerator.azv",
    "b16": "image/vnd.pco.b16",
    "bdoc": "application/bdoc",
    "blb": "application/x-blorb",
    "blorb": "application/x-blorb",
    "bmml": "application/vnd.balsamiq.bmml+xml",
    "book": "application/vnd.framemaker",
    "boz": "application/x-bzip2",
    "bpk": "application/octet-stream",
    "bsp": "model/vnd.valve.source.compiled-map",
    "buffer": "application/octet-stream",
    "c4d": "application/vnd.clonk.c4group",
    "c4f": "application/vnd.clonk.c4group",
    "c4p": "application/vnd.clonk.c4group",
    "c4u": "application/vnd.clonk.c4group",
    "caf": "audio/x-caf",
    "cb7": "application/x-cbr",
    "cba": "application/x-cbr",
    "cbr": "application/x-cbr",
    "cbt": "application/x-cbr",
    "cbz": "application/x-cbr",
    "cco": "application/x-cocoa",
    "cct": "application/x-director",
    "cdfx": "application/cdfx+xml",
    "cfs": "application/x-cfs-compressed",
    "cjs": "application/node",
    "coffee": "text/coffeescript",
    "com": "application/x-msdownload",
    "conf": "text/plain",
    "cpl": "application/cpl+xml",
    "crx": "application/x-chrome-extension",
    "csl": "application/vnd.citationstyles.style+xml",
    "cst": "application/x-director",
    "cxt": "application/x-director",
    "dart": "application/vnd.dart",
    "dataless": "application/vnd.fdsn.seed",
    "dbf": "application/vnd.dbf",
    "dbk": "application/docbook+xml",
    "dcr": "application/x-director",
    "ddf": "application/vnd.syncml.dmddf+xml",
    "deploy": "application/octet-stream",
    "dgc": "application/x-dgc-compressed",
    "dic": "text/x-c",
    "dist": "application/octet-stream",
    "distz": "application/octet-stream",
    "djv": "image/vnd.djvu",
    "dmp": "application/vnd.tcpdump.pcap",
    "dms": "application/octet-stream",
    "drle": "image/dicom-rle",
    "dump": "application/octet-stream",
    "dvb": "video/vnd.dvb.file",
    "dwd": "application/atsc-dwd+xml",
    "dxr": "application/x-director",
    "ear": "application/java-archive",
    "ecma": "application/ecmascript",
    "elc": "application/octet-stream",
    "emz": "application/x-msmetafile",
    "esa": "application/vnd.osgi.subsystem",
    "et3": "application/vnd.eszigno3+xml",
    "eva": "application/x-eva",
    "evy": "application/x-envoy",
    "exp": "application/express",
    "exr": "image/aces",
    "f77": "text/x-fortran",
    "f90": "text/x-fortran",
    "fcdt": "application/vnd.adobe.formscentral.fcdt",
    "fdt": "application/fdt+xml",
    "fgd": "application/x-director",
    "fh4": "image/x-freehand",
    "fh5": "image/x-freehand",
    "fh7": "image/x-freehand",
    "fhc": "image/x-freehand",
    "fits": "image/fits",
    "fo": "application/vnd.software602.filler.form+xml",
    "for": "text/x-fortran",
    "frame": "application/vnd.framemaker",
    "fxpl": "application/vnd.adobe.fxp",
    "gam": "application/x-tads",
    "gbr": "application/rpki-ghostbusters",
    "gca": "application/x-gca-compressed",
    "gdoc": "application/vnd.google-apps.document",
    "ged": "text/vnd.familysearch.gedcom",
    "geojson": "application/geo+json",
    "glb": "model/gltf-binary",
    "gltf": "model/gltf+json",
    "gml": "application/gml+xml",
    "gqs": "application/vnd.grafeq",
    "gramps": "application/x-gramps-xml",
    "gre": "application/vnd.geometry-explorer",
    "gsheet": "application/vnd.google-apps.spreadsheet",
    "gslides": "application/vnd.google-apps.presentation",
    "gxf": "application/gxf",
    "hbs": "text/x-handlebars-template",
    "hdd": "application/x-virtualbox-hdd",
    "heic": "image/heic",
    "heics": "image/heic-sequence",
    "heif": "image/heif",
    "heifs": "image/heif-sequence",
    "hej2": "image/hej2k",
    "held": "application/atsc-held+xml",
    "hh": "text/x-c",
    "hjson": "application/hjson",
    "hsj2": "image/hsj2",
    "icm": "application/vnd.iccprofile",
    "ifb": "text/calendar",
    "iges": "model/iges",
    "img": "application/octet-stream",
    "in": "text/plain",
    "ini": "text/plain",
    "ink": "application/inkml+xml",
    "inkml": "application/inkml+xml",
    "install": "application/x-install-instructions",
    "iota": "application/vnd.astraea-software.iota",
    "iso": "application/octet-stream",
    "its": "application/its+xml",
    "jade": "text/jade",
    "jardiff": "application/x-java-archive-diff",
    "jhc": "image/jphc",
    "jls": "image/jls",
    "jng": "image/x-jng",
    "jp2": "image/jp2",
    "jpf": "image/jpx",
    "jpg2": "image/jp2",
    "jpgm": "video/jpm",
    "jph": "image/jph",
    "jpx": "image/jpx",
    "json5": "application/json5",
    "jsonml": "application/jsonml+json",
    "jsx": "text/jsx",
    "jxr": "image/jxr",
    "jxra": "image/jxra",
    "jxrs": "image/jxrs",
    "jxs": "image/jxs",
    "jxsc": "image/jxsc",
    "jxsi": "image/jxsi",
    "jxss": "image/jxss",
    "kdbx": "application/x-keepass2",
    "key": "application/vnd.apple.keynote",
    "knp": "application/vnd.kinar",
    "kpt": "application/vnd.kde.kpresenter",
    "kpxx": "application/vnd.ds-keypoint",
    "ktr": "application/vnd.kahootz",
    "ktx2": "image/ktx2",
    "kwt": "application/vnd.kde.kword",
    "less": "text/less",
    "lgr": "application/lgr+xml",
    "lha": "application/x-lzh-compressed",
    "list": "text/plain",
    "list3820": "application/vnd.ibm.modcap",
    "listafp": "application/vnd.ibm.modcap",
    "lnk": "application/x-ms-shortcut",
    "log": "text/plain",
    "lostxml": "application/lost+xml",
    "lrf": "application/octet-stream",
    "lua": "text/x-lua",
    "luac": "application/x-lua-bytecode",
    "lzh": "application/x-lzh-compressed",
    "m13": "application/x-msmediaview",
    "m14": "application/x-msmediaview",
    "m1v": "video/mpeg",
    "m2a": "audio/mpeg",
    "m2v": "video/mpeg",
    "m3a": "audio/mpeg",
    "m4a": "audio/mp4",
    "m4p": "application/mp4",
    "m4s": "video/iso.segment",
    "m4u": "video/vnd.mpegurl",
    "maei": "application/mmt-aei+xml",
    "maker": "application/vnd.framemaker",
    "manifest": "text/cache-manifest",
    "mar": "application/octet-stream",
    "markdown": "text/markdown",
    "mb": "application/mathematica",
    "md": "text/markdown",
    "mdx": "text/mdx",
    "mesh": "model/mesh",
    "metalink": "application/metalink+xml",
    "mft": "application/rpki-manifest",
    "mie": "application/x-mie",
    "mime": "message/rfc822",
    "mjp2": "video/mj2",
    "mk3d": "video/x-matroska",
    "mka": "audio/x-matroska",
    "mkd": "text/x-markdown",
    "mks": "video/x-matroska",
    "mml": "text/mathml",
    "mng": "video/x-mng",
    "mobi": "application/x-mobipocket-ebook",
    "mp21": "application/mp21",
    "mp2a": "audio/mpeg",
    "mp4s": "application/mp4",
    "mp4v": "video/mp4",
    "mpd": "application/dash+xml",
    "mpf": "application/media-policy-dataset+xml",
    "mpg4": "video/mp4",
    "mpt": "application/vnd.ms-project",
    "ms": "text/troff",
    "mseed": "application/vnd.fdsn.mseed",
    "msg": "application/vnd.ms-outlook",
    "msi": "application/octet-stream",
    "msm": "application/octet-stream",
    "msp": "application/octet-stream",
    "mtl": "model/mtl",
    "musd": "application/mmt-usd+xml",
    "mvt": "application/vnd.mapbox-vector-tile",
    "mxmf": "audio/mobile-xmf",
    "nb": "application/mathematica",
    "nfo": "text/x-nfo",
    "nitf": "application/vnd.nitf",
    "nsc": "application/x-conference",
    "ntf": "application/vnd.nitf",
    "numbers": "application/vnd.apple.numbers",
    "nzb": "application/x-nzb",
    "obgx": "application/vnd.openblox.game+xml",
    "ogex": "model/vnd.opengex",
    "omdoc": "application/omdoc+xml",
    "onepkg": "application/onenote",
    "onetmp": "application/onenote",
    "onetoc2": "application/onenote",
    "opml": "text/x-opml",
    "oprc": "application/vnd.palm",
    "osm": "application/vnd.openstreetmap.data+xml",
    "ova": "application/x-virtualbox-ova",
    "ovf": "application/x-virtualbox-ovf",
    "owl": "application/rdf+xml",
    "oxps": "application/oxps",
    "pac": "application/x-ns-proxy-autoconfig",
    "pages": "application/vnd.apple.pages",
    "pas": "text/x-pascal",
    "pcap": "application/vnd.tcpdump.pcap",
    "pde": "text/x-processing",
    "pem": "application/x-x509-ca-cert",
    "pfb": "application/x-font-type1",
    "pfm": "application/x-font-type1",
    "php": "application/x-httpd-php",
    "pkg": "application/octet-stream",
    "pkpass": "application/vnd.apple.pkpass",
    "pm": "application/x-perl",
    "pqa": "application/vnd.palm",
    "provx": "application/provenance+xml",
    "pti": "image/prs.pti",
    "qwd": "application/vnd.quark.quarkxpress",
    "qwt": "application/vnd.quark.quarkxpress",
    "qxb": "application/vnd.quark.quarkxpress",
    "qxl": "application/vnd.quark.quarkxpress",
    "qxt": "application/vnd.quark.quarkxpress",
    "raml": "application/raml+yaml",
    "rapd": "application/route-apd+xml",
    "relo": "application/p2p-overlay+xml",
    "ris": "application/x-research-info-systems",
    "rmi": "audio/midi",
    "rmvb": "application/vnd.rn-realmedia-vbr",
    "rng": "application/xml",
    "roa": "application/rpki-roa",
    "rpm": "application/x-redhat-package-manager",
    "rsat": "application/atsc-rsat+xml",
    "rsheet": "application/urc-ressheet+xml",
    "run": "application/x-makeself",
    "rusd": "application/route-usd+xml",
    "s3m": "audio/s3m",
    "sass": "text/x-sass",
    "scss": "text/x-scss",
    "sdkd": "application/vnd.solent.sdkm+xml",
    "sea": "application/x-sea",
    "senmlx": "application/senml+xml",
    "sensmlx": "application/sensml+xml",
    "sfv": "text/x-sfv",
    "sgi": "image/sgi",
    "shex": "text/shex",
    "shtml": "text/html",
    "sid": "image/x-mrsid-image",
    "sieve": "application/sieve",
    "sig": "application/pgp-signature",
    "sil": "audio/silk",
    "silo": "model/mesh",
    "sisx": "application/vnd.symbian.install",
    "siv": "application/sieve",
    "skd": "application/vnd.koan",
    "skm": "application/vnd.koan",
    "skt": "application/vnd.koan",
    "slim": "text/slim",
    "slm": "text/slim",
    "sls": "application/route-s-tsid+xml",
    "smil": "application/smil+xml",
    "smv": "video/x-smv",
    "smzip": "application/vnd.stepmania.package",
    "spdx": "text/spdx",
    "sql": "application/x-sql",
    "ssdl": "application/ssdl+xml",
    "stpx": "model/step+xml",
    "stpxz": "model/step-xml+zip",
    "stpz": "model/step+zip",
    "styl": "text/stylus",
    "stylus": "text/stylus",
    "susp": "application/vnd.sus-calendar",
    "swa": "application/x-director",
    "swidtag": "application/swid+xml",
    "t3": "application/x-t3vm-image",
    "t38": "image/t38",
    "taglet": "application/vnd.mynfc",
    "tap": "image/vnd.tencent.tap",
    "td": "application/urc-targetdesc+xml",
    "text": "text/plain",
    "tfx": "image/tiff-fx",
    "tga": "image/x-tga",
    "tk": "application/x-tcl",
    "toml": "application/toml",
    "trig": "application/trig",
    "ttc": "font/collection",
    "ttml": "application/ttml+xml",
    "twds": "application/vnd.simtech-mindmapper",
    "u32": "application/x-authorware-bin",
    "u3d": "model/u3d",
    "u8dsn": "message/global-delivery-status",
    "u8hdr": "message/global-headers",
    "u8mdn": "message/global-disposition-notification",
    "u8msg": "message/global",
    "ubj": "application/ubjson",
    "udeb": "application/x-debian-package",
    "ufdl": "application/vnd.ufdl",
    "ulx": "application/x-glulx",
    "uris": "text/uri-list",
    "urls": "text/uri-list",
    "usdz": "model/vnd.usdz+zip",
    "uvd": "application/vnd.dece.data",
    "uvf": "application/vnd.dece.data",
    "uvg": "image/vnd.dece.graphic",
    "uvt": "application/vnd.dece.ttml+xml",
    "uvva": "audio/vnd.dece.audio",
    "uvvd": "application/vnd.dece.data",
    "uvvf": "application/vnd.dece.data",
    "uvvg": "image/vnd.dece.graphic",
    "uvvh": "video/vnd.dece.hd",
    "uvvi": "image/vnd.dece.graphic",
    "uvvm": "video/vnd.dece.mobile",
    "uvvp": "video/vnd.dece.pd",
    "uvvs": "video/vnd.dece.sd",
    "uvvt": "application/vnd.dece.ttml+xml",
    "uvvu": "video/vnd.uvvu.mp4",
    "uvvv": "video/vnd.dece.video",
    "uvvx": "application/vnd.dece.unspecified",
    "uvvz": "application/vnd.dece.zip",
    "uvx": "application/vnd.dece.unspecified",
    "uvz": "application/vnd.dece.zip",
    "vbox": "application/x-virtualbox-vbox",
    "vcard": "text/vcard",
    "vdi": "application/x-virtualbox-vdi",
    "vds": "model/vnd.sap.vds",
    "vhd": "application/x-virtualbox-vhd",
    "vmdk": "application/x-virtualbox-vmdk",
    "vob": "video/x-ms-vob",
    "vor": "application/vnd.stardivision.writer",
    "vox": "application/x-authorware-bin",
    "vrml": "model/vrml",
    "vss": "application/vnd.visio",
    "vsw": "application/vnd.visio",
    "vtf": "image/vnd.valve.source.texture",
    "vtt": "text/vtt",
    "w3d": "application/x-director",
    "wadl": "application/vnd.sun.wadl+xml",
    "war": "application/java-archive",
    "wasm": "application/wasm",
    "wcm": "application/vnd.ms-works",
    "wdb": "application/vnd.ms-works",
    "wdp": "image/vnd.ms-photo",
    "webapp": "application/x-web-app-manifest+json",
    "wif": "application/watcherinfo+xml",
    "wks": "application/vnd.ms-works",
    "x32": "application/x-authorware-bin",
    "x3db": "model/x3d+binary",
    "x3dbz": "model/x3d+binary",
    "x3dv": "model/x3d+vrml",
    "x3dvz": "model/x3d+vrml",
    "x3dz": "model/x3d+xml",
    "xav": "application/xcap-att+xml",
    "xca": "application/xcap-caps+xml",
    "xcs": "application/calendar+xml",
    "xel": "application/xcap-el+xml",
    "xhvml": "application/xv+xml",
    "xla": "application/vnd.ms-excel",
    "xlc": "application/vnd.ms-excel",
    "xlf": "application/x-xliff+xml",
    "xlm": "application/vnd.ms-excel",
    "xm": "audio/xm",
    "xns": "application/xcap-ns+xml",
    "xpl": "application/xproc+xml",
    "xpx": "application/vnd.intercon.formnet",
    "xsd": "application/xml",
    "xvm": "application/xv+xml",
    "xvml": "application/xv+xml",
    "xz": "application/x-xz",
    "yml": "text/yaml",
    "ymp": "text/x-suse-ymp",
    "z1": "application/x-zmachine",
    "z2": "application/x-zmachine",
    "z3": "application/x-zmachine",
    "z4": "application/x-zmachine",
    "z5": "application/x-zmachine",
    "z6": "application/x-zmachine",
    "z7": "application/x-zmachine",
    "z8": "application/x-zmachine",
    "zirz": "application/vnd.zul"
}

export const wordMimes = [
    "application/msword",
    "application/vnd.ms-word.document.macroenabled.12",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-word.template.macroenabled.12",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.oasis.opendocument.text",
    "application/rtf"
]
export const zipMimes = [
    "application/vnd.adobe.air-application-installer-package+zip"
]

export const getMimeType = (fileExtension: string) => {
    const mimeType = mimeTypes[fileExtension];
    return mimeType!=undefined ? mimeType : "application/octet-stream";
}
