import {
    BsFileEarmark,
    BsFileEarmarkPdf,
    BsFileEarmarkExcel,
    BsFileEarmarkWord,
    BsFileEarmarkImage,
    BsFileEarmarkPlay,
    BsFileEarmarkZip,
    BsFileEarmarkMusic,
    BsFileEarmarkPpt,
    BsFileEarmarkRichtext,
    BsFileEarmarkText,
    BsFileEarmarkBinary,
    BsFileEarmarkCode,
    BsFileEarmarkLock2,
    BsFileEarmarkFont,
    BsFileEarmarkBarGraph,
} from "react-icons/bs";
import { getMimeType, wordMimes } from "../../utilities/mimeTypes";
import FileEnvelope from "../../assets/img/icons/file-types/file-earmark-envelope-open.svg";

export function getFileIcon(mimeType: string, name: string) {
    let fileTypeIcon;
    let fType = mimeType.toLowerCase();

    let i = 0;
    let knownType = false;
    while (i < 2 && !knownType) {
        if (fType == "application/pdf") {
            fileTypeIcon = <i className='attachment-icon att-icon-red'>
                <BsFileEarmarkPdf className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType == "application/vnd.openxmlformats-officedocument.wordprocessingml.template") {
            fileTypeIcon = <i className='attachment-icon att-icon-blue'>
                <BsFileEarmarkWord className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType == "application/onenote") {
            fileTypeIcon = <i className='attachment-icon att-icon-purple'>
                <BsFileEarmarkRichtext className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType == "text/csv") {
            fileTypeIcon = <i className='attachment-icon att-icon-green'>
                <BsFileEarmarkExcel className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.split('/')[0] == 'text') {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkText className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType == "application/octet-stream") {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkBinary className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType == "application/xml" || fType.includes('xml') ||
        fType.includes('application/node') || fType == "application/toml" ||
        fType.includes('json')) {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkCode className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.split('/')[0] == 'image') {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkImage className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.split('/')[0] == 'audio' || fType == "application/vnd.ms-wpl") {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkMusic className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.split('/')[0] == 'video') {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkPlay className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('excel') || fType.includes('spreadsheet')) {
            fileTypeIcon = <i className='attachment-icon att-icon-green'>
                <BsFileEarmarkExcel className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (wordMimes.includes(fType)) {
            fileTypeIcon = <i className='attachment-icon att-icon-blue'>
                <BsFileEarmarkWord className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('powerpoint') || fType.includes('presentation') || fType.includes('slideshow')) {
            fileTypeIcon = <i className='attachment-icon att-icon-orange'>
                <BsFileEarmarkPpt className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('zip') || fType.includes('compressed')) {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkZip className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('message') || fType.includes('outlook')) {
            fileTypeIcon = <img className='attachment-icon att-icon-blue' src={FileEnvelope} />;
            knownType = true;
            break;
        }
        else if (fType == "application/x-csh" || fType == "application/vnd.rar" ||
            fType == "application/x-tar") {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkZip className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('msaccess') || fType.includes('database')) {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkLock2 className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('font')) {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkFont className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('visio')) {
            fileTypeIcon = <i className='attachment-icon att-icon-grey'>
                <BsFileEarmarkBarGraph className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        else if (fType.includes('certificate') || fType.includes('cert')) {
            fileTypeIcon = <i className='attachment-icon att-icon-gold'>
                <BsFileEarmarkRichtext className='attachment-icon--icon' />
            </i>;
            knownType = true;
            break;
        }
        if (name.includes(".")) {
            const extension = name.slice(name.lastIndexOf(".") + 1).toLowerCase()
            fType = getMimeType(extension).toLowerCase();
        }
        i += 1;
    }
    if (!knownType)
        {fileTypeIcon = <i className='attachment-icon att-icon-grey'>
            <BsFileEarmark className='attachment-icon--icon' />
        </i>;}

    return fileTypeIcon;
}

export function getFormatFileSize(size: number): string {
    let attachmentSize: number = (size / 1000);
    let attachmentSizeIndicater: string = "KB"
    if (attachmentSize > 999.99) {
        attachmentSize = (attachmentSize / 1000);
        attachmentSizeIndicater = "MB";
    }
    return `${(attachmentSize).toFixed(2)} ${attachmentSizeIndicater}`;
}