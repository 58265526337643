import { createSlice} from '@reduxjs/toolkit'
import { iEnquiry } from '../interfaces/iEnquiry';

export interface test   {
    aaa: Map<number,iEnquiry>
}

const initialState  = {
    data: [] as iEnquiry[]
};

export const enquiriesCache = createSlice({
    name: 'updateEnquiriesCache',
    initialState,
    reducers: {
        updateEnquiriesCache: (state, action) => {
            var map = new Map(state.data.map((obj) => [obj.id, obj]));
           
            for (const newEnquiry of action.payload) {
                map.set(newEnquiry.id, newEnquiry);
            }
 
            state.data = Array.from(map.values());
          },
    },
});

// Action creators are generated for each case reducer function
export const { updateEnquiriesCache} = enquiriesCache.actions

export default enquiriesCache;