import { useRef, useState } from 'react';
import { componentProps } from '../../../interfaces/ActionStartResponse';
import '../mis.scss';
function Numeric(props: componentProps) {
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)

    const minVal = props.MisField.minimumValue ?? -32768;
    const maxVal = props.MisField.maximumValue ?? 32767;

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        let newValue = parseInt(e.currentTarget.value);
        
        if (Number.isNaN(newValue))
            newValue = parseInt(props.MisField.defaultValue ?? minVal.toString());

        if (newValue < minVal)
            newValue = minVal;

        if (newValue > maxVal)
            newValue = maxVal;

        setFieldValue(newValue.toString());
        const sendToParent = props.returnFunc;
        sendToParent( {fieldID:props.MisEntry.fieldID,value:newValue.toString()});
    }
  
    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div   className='col-sm-3'>
                <label  className='g-label'>
                    {props.MisField.label}
                </label>
            </div>
            <div   className='col-sm-9'>
                <input
                    type="number"
                    className="g-TextBox-default"
                    onChange={handleChange}
                    value={fieldValue}
                    step={1}
                    min={minVal}
                    max={maxVal}
                />
           
            </div>
        </div>
    );
}
export default Numeric;

