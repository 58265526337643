import { useEffect, useMemo, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { EnquiryMenuProps } from "../../interfaces/menu";
import { EnquiryMenuItems } from ".";

export interface Props extends EnquiryMenuProps {
    x: number,
    y: number,
    onHide: () => void
}

export function EnquiryContextMenu(props: Props) {

    const menuRef = useRef<HTMLDivElement>(null)
    useOutsideClick(menuRef, props.onHide);
    const [pos, setPos] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const x = props.x + 195 > window.innerWidth ?
            props.x - 195 :
            props.x;
        const y = props.y + 168 > window.innerHeight ?
            props.y - 168 :
            props.y;
        setPos({ x, y });
    }, []);

    return (
        <div
            ref={menuRef}
            style={{ position: "absolute", top: pos.y, left: pos.x }}
        >
            <EnquiryMenuItems onHide={props.onHide} enquiryId={props.enquiryId} />
        </div>
    )
}
