import './index.scss'
import { iEnquiryPropertiesProps } from './EnquiryPropertiesModal';
import Statuses from '../../interfaces/Statuses';
import TextBox from '../Generic/Form/TextBox';
import Numeric from '../Generic/Form/Numeric';
import DateTime from '../Generic/Form/DateTime';
import { toDateTime } from '../../utilities/date';

export default function General(props: iEnquiryPropertiesProps) {

    const { enquiryDetails, handleChange, updateRestricted } = props;
    function isValidWorkItem(workitem: any): boolean {
        if ((workitem < 1) || (workitem > 1000)) {
            return false;
        }
        return true;
    }
    function isValidPriority(NewValue: any): boolean {
        if ((NewValue < 1) || (NewValue > 255)) {
            return false;
        }
        return true;
    }

    return (
        <>
            <TextBox
                id={"enquiry-properties-enquirer-name"}
                label={"Enquirer Name"}
                value={enquiryDetails?.enquirerName ?? ""}
                onChange={(newValue) => { handleChange(newValue, "enquirerName") }}
                maxLength={80}
                firstCol={4}
                disabled={updateRestricted}
            />
            <TextBox
                id={"enquiry-properties-enquirer-email"}
                label={"Enquirer Email"}
                value={enquiryDetails?.enquirerEmail ?? ""}
                onChange={(newValue) => { handleChange(newValue, "enquirerEmail") }}
                maxLength={50}
                firstCol={4}
                disabled={updateRestricted}
            />
            <Numeric
                id={"enquiry-properties-enquirer-name"}
                label={"Enquirer Priority"}
                value={enquiryDetails?.priority ?? ""}
                onChange={(newValue: any) => { handleChange(+newValue, "priority") }}
                firstCol={4}
                min={1} max={255}
                isNotValid={!isValidPriority(enquiryDetails?.priority)}
                disabled={updateRestricted}
            />
            <TextBox
                id={"enquiry-properties-enquirer-email"}
                label={"Subject"}
                value={enquiryDetails?.subject ?? ""}
                onChange={(newValue) => { handleChange(newValue, "subject") }}
                maxLength={50}
                firstCol={4}
                disabled={updateRestricted}
            />
            <TextBox
                id={"enquiry-properties-enquirer-name"}
                label={"Current Status"}
                value={enquiryDetails?.status !== undefined ? Statuses.Name(enquiryDetails?.status) : ""}
                onChange={() => { }}
                maxLength={50}
                firstCol={4}
                disabled
            />
            <Numeric
                id={"enquiry-properties-enquirer-email"}
                label={"Work Items"}
                value={enquiryDetails?.workItems ?? ""}
                onChange={(newValue: any) => { handleChange(+newValue, "workItems") }}
                firstCol={4}
                min={1} max={1000}
                isNotValid={!isValidWorkItem(enquiryDetails?.workItems)}
                disabled={updateRestricted}
            />
            <TextBox
                id={"enquiry-properties-enquirer-email"}
                label={"Last Updated By"}
                value={enquiryDetails?.updatedBy ? `${enquiryDetails?.updatedBy.firstname} ${enquiryDetails?.updatedBy.lastname}` : ""}
                onChange={() => { }}
                maxLength={50}
                firstCol={4}
                disabled
            />
            <DateTime
                id={"enquiry-properties-updated-date"}
                label={"Updated Date"}
                value={toDateTime(enquiryDetails?.responseSlaCycle.timeout ?? "")}
                onChange={() => { }}
                firstCol={4}
                disabled
            />
        </>
    )
}
