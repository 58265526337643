import { getCommsMap, saveCommsMap } from "../../utilities/localStore/calls";



export const setLastCommunication = (EnquiryId:number, CommId: number, Position:number = 0)=>{
    let LastsComms = getCommsMap();
    if(LastsComms.size==0){
        LastsComms.set(EnquiryId.toString(),[{"Id":CommId, "ScrollPosition":Position}]);
    }
    else if(LastsComms.size>0){
    let commList =  LastsComms.get(EnquiryId.toString());
        if(commList!=undefined){
         commList = commList.filter((x: { Id: number; })=> x.Id!=CommId) // put the comm at the begining of the list
         commList.unshift({"Id":CommId, "ScrollPosition":Position});
         LastsComms.set(EnquiryId.toString(),commList);
        }
        else{
            LastsComms.set(EnquiryId.toString(),[{"Id":CommId, "ScrollPosition":Position}]);
        }
    }
    saveCommsMap(LastsComms);
 }


export const getLastCommunication = (EnquiryId:number): number =>{


    let LastsComms = getCommsMap();
    if(LastsComms.size>0){
        let commList =  LastsComms.get(EnquiryId.toString())
        if(commList!=undefined){
            return commList[0].Id;
        }
    }
    return 0;
 
}

export const getLastCommunicationPosition = (EnquiryId:number): number =>{
    let LastsComms = getCommsMap();

    if(LastsComms.size>0){
        let commList =  LastsComms.get(EnquiryId.toString())
        if(commList!=undefined){
            return commList[0].ScrollPosition;
        }
    }
    return 0;
}