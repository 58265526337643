import { isNullOrWhitespace } from "../../utilities/string";

export const formatEmailList=(emails:Array<{name?:string, address:string}>)=>{
    let ems = emails? emails.map((item)=>{
        return (item.address )
    }
    ).join("; ") : ""
    return (ems);
 }

 export const formatEmailForComparison = (email: string) => {
    email = email.replaceAll(",", ";").toLowerCase();
    let emails = email.split(";");
    return emails[0].toString().trim();
}


export const formatEmailforEmbed =(emails:Array<{name?:string, address:string}>)=>{
    let ems = emails? emails.map((item)=>{
        return (  isNullOrWhitespace(item.name!)? item.address : item.name + " <" + item.address + "> ")
    }
    ).join(", ") : ""
    return (ems);
}