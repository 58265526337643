import { Fragment, useState } from 'react';
import './Workflow.scss';
import Step from './template'
import { Dispatch } from "redux";
import { useAppDispatch } from '../../state/store'
import FormTextRow from '../../components/Generic/Form/TextBox/';
import FormEmailRow from '../../components/Generic/Form/Email/';
import FormNumberRow from '../../components/Generic/Form/Numeric/';
import { isNewEnquiry  } from '../../Logic/Enquiries/activateWizard';
import { IsEmailAddress } from '../../utilities/string';
import { EStepKey } from '../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from './stepType';
import { WizardContext } from '../../contexts/wizard-context';
import React from 'react';

export function isValidWorkItem(workitem: number): boolean {
   if ((workitem < 1) || (workitem>1000)){
        return false;
    }
    return true;
}

function Step2(props: WizardStepProps) {
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));
  
 
    const handleChange = (newValue:string , fieldName:string) => {

        setWizard(currentState=>{
            currentState = JSON.parse(JSON.stringify(currentState));
            currentState.workflow.data.EnquirerDetails[fieldName] = newValue;
          return currentState;
        }) ;
       
     }

    const nextFunc = () => {
           
        let passValidation = true;
        if (wizard.workflow.data.EnquirerDetails.FullName == "") {
            passValidation = false;
        }
        if (wizard.workflow.data.EnquirerDetails.Email != "") {
            if (!IsEmailAddress(wizard.workflow.data.EnquirerDetails.Email)) {
                passValidation = false;
            }
        }
        if (!isValidWorkItem(wizard.workflow.data.EnquirerDetails.WorkItems)) {
            passValidation = false;
        }
        if (passValidation) {
            onNext()
        }
       
    }
    const backFunc = () => {
      onBack()
    }
    

 
       return (
        <Fragment >
                <Step title="Enquirer's Details"
                  nextF={nextFunc}
                  step={EStepKey.ENQUIRER_DETAILS_STEP}
                  backF={wizard.isNew && !isNewEnquiry(wizard.workflow.data.EnquiryId) ? backFunc : undefined}
                  Enquiry={props.Enquiry}
                  Popped = {props.Popped}
                  FullWidth = {props.FullWidth}
                >
                <FormTextRow label='Enquirer Name' value={wizard.workflow.data.EnquirerDetails.FullName} requiredIdentifier={true} isNotValid={wizard.workflow.data.EnquirerDetails.FullName == ""} onChange={(newValue) => { handleChange(newValue, "FullName") }} key={"mis-field-name"} id={"10"} maxLength={80}></FormTextRow>
                <FormEmailRow label='Enquirer Email' value={wizard.workflow.data.EnquirerDetails.Email}  onChange={(newValue) => { handleChange(newValue, "Email") }} key={"mis-field-Email"} id={"11"} maxLength={80}></FormEmailRow>  
                <FormTextRow label='Subject'  value={wizard.workflow.data.EnquirerDetails.Subject} onChange={(newValue) => { handleChange(newValue, "Subject") }} key={"mis-field-Subject"} id={"14"} maxLength={128}></FormTextRow>
                <FormNumberRow label='Work Items' requiredIdentifier={true} value={wizard.workflow.data.EnquirerDetails.WorkItems} isNotValid={!isValidWorkItem(wizard.workflow.data.EnquirerDetails.WorkItems)} onChange={(newValue) => { handleChange(newValue, "WorkItems") }} key={"mis-field-Items"} id={"15"} min={1}></FormNumberRow>
            </Step>
 
        </Fragment>
    );
}

export default Step2;
