import { BsX } from "react-icons/bs"
import { PillProps } from "../../../../interfaces/Pill"

export function PillElement(props: PillProps) {
    
    return (
        <div key={props.address} title={props.address} className="pill-elt limit-text">
            <div className="ms-1">{props.name === undefined || props.name === "" ? props.address : props.name}</div>
            <button onClick={() => props.onClick(props.id)}><i><BsX /></i></button>
        </div>
    )
}