import { Col, Row } from "react-bootstrap";
import { SearchFieldPM } from "../../interfaces/AdvancedSearch/SearchFieldPM";
import { useEffect, useRef } from "react";

interface props {
    searchField: SearchFieldPM,
    onChange: (searchField: SearchFieldPM) => void,
    children: any
}

function SearchFieldRowDropDown(props: props) {
    const inputRef = useRef<HTMLSelectElement>(null);
    const inputFocusRef = useRef(false);

    const searchField = props.searchField;

    function setEnabled() {
        searchField.Enabled = !searchField.Enabled;

        if (searchField.Enabled) {
            inputFocusRef.current = true;
        }

        props.onChange(searchField);
    };

    function setValue(newValue: string) {
        searchField.Value = newValue;
        props.onChange(searchField);
    }

    function onInputColClick() {
        if (!searchField.Enabled) {
            setEnabled();
        }
    }

    useEffect(() => {
        if (inputFocusRef.current) {
            inputRef.current!.focus();
            inputFocusRef.current = false;
        }
    });

    return (
        <Row className="rowMarginControl">
            <Col xs={4} >
                <input className="g-Checkbox-default" type="checkbox" checked={searchField.Enabled} onChange={setEnabled} />
                <label className="g-label " onClick={setEnabled}>{searchField.Label}</label>
            </Col>
            <Col xs={8} onClick={onInputColClick}>
                <select
                    className={'g-Select-default' + (!searchField.Enabled ? " search-pointer-events" : "")}
                    disabled={!searchField.Enabled}
                    value={searchField.Value}
                    onChange={(e) => setValue(e.target.value)}
                    ref={inputRef}
                >
                    {props.children}
                </select>
            </Col>
        </Row >
    )
}

export default SearchFieldRowDropDown;
