import { iAuth, iTenant, iTenantContext, iTenants, iToken } from '../../interfaces/auth'
import { getSessionValue, getLocalValue, saveSessionValue, saveLocalValue } from '../../utilities/localStore/calls';
import { ConstructPermissions } from '../../utilities/permissions';
import worker_script from '../../workerProcesses/UpdateAuthTokens';
import store from '../../state/store';
import { setPermissions } from '../../state/PermissionSlice';
import { iLastTouched } from '../../interfaces/lastTouched';
import { GetMailboxPermissions, Permissions as iPermissions } from '../../interfaces/Permissions';
import { iUser, setUser } from '../../state/userSlice';
import { Get, callApi, iRequest } from '../../utilities/apiCall';
import { iTenantConfiguration, setConfiguration } from '../../state/configSlice';
import { getLastTouched, saveLastTouched } from '../LastTouched/misc';

export const awsConfig = {
    ClientId: "",
    Url: "",
    RedirectUrl: ""
}


export async function getTenants(): Promise<Array<iTenant>> {
    const crypto = getLocalValue<iAuth>("auth");
    const hHeaders = new Headers();
    hHeaders.append("Accept", "application/json");
    hHeaders.append("Authorization", "Bearer " + crypto?.token.id_token);
    let fetchOptions: any = {}
    fetchOptions.method = "GET";
    fetchOptions.headers = hHeaders;
    let response = await fetch(process.env.REACT_APP_STS + "Tenants", fetchOptions);
    if(response.status == 401){
        throw new Error("401")
    }
    let tenants: Array<iTenant> = await response.json();
    updateRecentTenants(tenants);
    return tenants;
}

export async function getTenantAuth(crypto: iAuth, tenant: string): Promise<iTenantContext> {
        const hHeaders = new Headers();
        hHeaders.append("Accept", "application/json");
        hHeaders.append("Authorization", "Bearer " + crypto.token.id_token);
        var fetchOptions: any = {}
        fetchOptions.method = "GET";
        fetchOptions.headers = hHeaders;
        const response = await fetch(process.env.REACT_APP_STS + "Tenant/" + tenant + "/AcquireToken", fetchOptions);
        if(response.status==403)
        {
                throw new Error("403") //cant access tenant so refresh the tokens
        }
        else if(response.status==401) {    
                throw new Error("401") // auth credentionals failed , logout                
        }
        return response.json();
}

export function UpdateConfiguration(tenantName: string) {
    
    let request: iRequest = {
        url: "Config",
        method: "GET",
        headers: [],
        body: "",
        responseType: "json"
    }

    callApi(request, tenantName)
        .then((data: iTenantConfiguration) => {
            store.dispatch(setConfiguration(data));
        });
}


export async function getToken(crypto: iAuth): Promise<any> {

    const tokenEndpoint = new URL(
        process.env.REACT_APP_Token_URL!
    );
    tokenEndpoint.searchParams.append('grant_type', 'authorization_code');
    tokenEndpoint.searchParams.append('client_id', process.env.REACT_APP_ClientId!);
    tokenEndpoint.searchParams.append('code_verifier', crypto.verifier);
    tokenEndpoint.searchParams.append('code', crypto.code);
    tokenEndpoint.searchParams.append('redirect_uri', (window.location.origin + "/auth/callback"));

    const hHeaders = new Headers();
    hHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    hHeaders.append("Accept", "application/json");
    var fetchOptions: any = {}
    fetchOptions.method = "POST";
    fetchOptions.headers = hHeaders;
    fetchOptions.body = tokenEndpoint.searchParams;

    try {
        const response = await fetch(process.env.REACT_APP_Token_URL!, fetchOptions)

            .then(
                (data) => {
                    if (data.status == 204) {
                        return "No Content";
                    }
                    else {

                        return data.json();
                    }
                }

            ).catch((err) => {
                return err;

            });

        return response;
    }
    catch (err) {
        alert(err);
    }

}

export async function postToS3(crypto: iAuth): Promise<any> {

}

 
export async function refreshIDPToken(): Promise<any> {
    try {
        const crypto = getLocalValue<iAuth>("auth");
        if (!crypto)
            throw new Error("Failed to refresh");
        const tokenEndpoint = new URL(
            process.env.REACT_APP_Token_URL!
        );

        tokenEndpoint.searchParams.append('grant_type', 'refresh_token');
        tokenEndpoint.searchParams.append('refresh_token', crypto.token.refresh_token);
        const hHeaders = new Headers();
        hHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        hHeaders.append("Accept", "application/json");
        var fetchOptions: any = {}
        fetchOptions.method = "POST";
        fetchOptions.headers = hHeaders;
        fetchOptions.body = tokenEndpoint.searchParams;

        let response = await      fetch(process.env.REACT_APP_Token_URL!, fetchOptions);
        if (response.status != 200) {
            throw new Error("error");
        }
        if (crypto !== null) {
            crypto.token = await response.json() ;
            saveLocalValue("auth", crypto);
        }
    }
    catch (err) {
        logout();
        window.location.href=window.location.origin;
    }
}

export const getRecentTenants = () => {
    const tenants = getLocalValue<iTenants>("RecentTenants")
    return tenants ? tenants.tenants : []
}

export const saveRecentTenant = (tenant: iTenant) => {
    let tenants = getRecentTenants()
    if (tenants.find(t => t.key == tenant.key)) {
        tenants = tenants.filter(t => t.key != tenant.key)
    }
    tenants.unshift(tenant)
    saveLocalValue("RecentTenants", { tenants: tenants })
}

export const updateRecentTenants = (allowedTenants: iTenant[]) => {
    const recentTenants = getRecentTenants();
    const intersection = recentTenants.filter(x => allowedTenants.find(y => y.key === x.key && y.name === x.name) !== undefined);
    saveLocalValue("RecentTenants", { tenants: intersection })
}

export const logout = () => {
    sessionStorage.clear();
    localStorage.removeItem("auth") // Remove tenant tokens as well.
    store.dispatch({ type: "USER_LOGOUT" });
}

export function setTenantContext(tenant: iTenant, tenantAuth: iTenantContext) {
    const tenantStore = getSessionValue<iTenant>("tenant");
    if(tenantStore==null){
        saveSessionValue("tenant",{
            name: tenant.name,
            key: tenant.key,
            token: tenantAuth.token,
            accessToMultipleTenants:false
        });
    }else{
        tenantStore.name = tenant.name;
        tenantStore.key = tenant.key;
        tenantStore.token = tenantAuth.token;
        saveSessionValue("tenant",tenantStore);
    }
    
    const permissions = ConstructPermissions(tenantAuth.token);

    const user: iUser = tenantAuth;
    user.mailboxes.sort((x, y) => x.name.localeCompare(y.name))

    setLastTouched(user, permissions);

    store.dispatch(setPermissions(permissions));
    store.dispatch(setUser(user));
}

function setLastTouched(user: iUser, permissions: iPermissions) {
    let lastTouched = getSessionValue<iLastTouched>("LastTouched");

    if (lastTouched === undefined || lastTouched === null) {
        lastTouched = {
            SearchResults: {
                ActiveList: "Results",
                EnquiryLists: [{
                    Name: "Results",
                    EnquiryId: 0,
                    CommId:0
                }]
            },
            MyEnquiries: {
                ActiveList: "Unresolved", // what q or resolved or unresolved is open. set this as a default
                EnquiryLists: [
                    {
                        Name: "Unresolved",
                        EnquiryId: 0,
                        CommId:0
                    },
                    {
                        Name: "Resolved",
                        EnquiryId: 0,
                        CommId:0
                    }
                ]
            },
            Queue:
            {
                ActiveList: 0,
                EnquiryLists: []
            },
            lastActiveListType: ''
        }
    }

    const viewableMailboxes = user.mailboxes
        .filter(x => GetMailboxPermissions(permissions, x.id).ViewEnquiryList);

    const existingEnquiryList = lastTouched.Queue.EnquiryLists;
    lastTouched.Queue.EnquiryLists = [];
    
    for (const mailbox of viewableMailboxes) {
        const existing = existingEnquiryList.find(x => x.Name === mailbox.id);
        
        if (existing !== undefined) {
            lastTouched.Queue.EnquiryLists.push(existing)
        } else {
            lastTouched.Queue.EnquiryLists.push({
                Name: mailbox.id,
                EnquiryId: 0,
                CommId:0,
                Label: mailbox.name + " " + mailbox.reference
            });
        }
    }

    saveLastTouched(lastTouched);
}

export async function UpdateAuthTokens(): Promise<any> {
    var promise = new Promise<any>(async (resolve, reject) => {
        const code = worker_script.toString();
        const blob = new Blob([`(${code})()`]);
        const worker: Worker = new Worker(URL.createObjectURL(blob));
        const crypto = getLocalValue<iAuth>("auth");
        const tenant = getLocalValue<iTenant>("tenant");
        if (!crypto || !tenant){
            logout();
            return;
        }
        let data = { "token": crypto.token.refresh_token, "tenant": tenant };
        await worker.postMessage(data);
        worker.onmessage = event => {
            if (event.data.error == false) {
                crypto.token = event.data.data;
                saveLocalValue("auth", crypto);
                let tenantContext: iTenantContext = crypto.token.tenantAuth;
                setTenantContext(tenant, tenantContext);
                UpdateConfiguration(tenant.key);
                setTimeout(UpdateAuthTokens, 55 * 60 * 1000);
                worker.terminate();
                resolve("success");
            }
            else {
                alert("an error occured and token could not be updated - " + event.data.data);
                logout();
                worker.terminate();
                reject("error");
            }
        }
    });
    return promise
};

export const getTenantToken = (tenantName?: string): string | undefined => {
    const tenant = getSessionValue<iTenant>("tenant");
    return  tenant?.token;
}


export const getActiveTenant=(returnToLower:boolean = true) : string =>{

    const tenant = getSessionValue<iTenant>("tenant");
    if(tenant){
        return  returnToLower? tenant.key.toLowerCase() : tenant.key;
    }
    return ""
  }

  export const getActiveTenantName=(returnToLower:boolean = true) : string =>{
    const tenant = getSessionValue<iTenant>("tenant");
    if(tenant){
        return  returnToLower? tenant.name.toLowerCase() : tenant.name;
    }
    return ""
  }

 
  export const isTenantValid = (tenant:string) =>{
    if(getActiveTenant(true)==tenant.toLowerCase()){
        return true;
    }else{return false;}
  }