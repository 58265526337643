import { isDesktop, isTablet } from 'react-device-detect';
import { Post } from '../utilities/apiCall';
import { getLocalValue, saveLocalValue } from '../utilities/localStore/calls';

export interface iErrorLog {
        ErrorIdentifier: string,
        ErrorAt: Date,
        ErrorMessage?: string,
        ErrorStack?:string,
        Url: string,
        Browser?: string,
        BrowserVersion?: string,
        OS: string,
        DeviceType?: string,
        DeviceOrentation?: string,
        ScreenResolution?: string,
        BrowserWindowSize?: string,
        HasMouse?:boolean,
        HasTouchscreen?:boolean
}

export function logErrorToService(error: Error, refNumber: string) {
    let errorLog: iErrorLog;
    errorLog = {
        ErrorIdentifier: refNumber,//
        ErrorMessage: error.message,
        ErrorStack: error.stack,
        ErrorAt: new Date(),
        OS: window.navigator.platform,
        Url: window.location.href.toString(),
        BrowserWindowSize: window.outerWidth.toString() + "x" + window.outerHeight.toString(),
        ScreenResolution: window.screen.width.toString() + "x" + window.screen.height.toString(),
        DeviceOrentation: window.screen.orientation.type.toString(),
        DeviceType: isDesktop == true ? "Computer" : "Tablet/Phone",
        HasTouchscreen: isTablet,
        HasMouse: !isTablet
    }
    
    const errorLogArray = getLocalValue<iErrorLog[]>("errorlog") ?? [];
    errorLogArray.push(errorLog);
    SaveError(errorLogArray);
}
export async function uploadErrorList() {
    let errorLogArray = getLocalValue<iErrorLog[]>("errorlog");
    try {
        if (errorLogArray !== null && errorLogArray.length > 0) {
            await Post("ErrorLog", errorLogArray)
            errorLogArray = [];
            SaveError(errorLogArray);
        }
    }
    catch (err) { }   
}
export const SaveError = (data: iErrorLog[]) => {
    saveLocalValue("errorlog", data);
}

export const defaultReset = () =>{
 
}
