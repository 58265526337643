import { useEffect, useState } from 'react';
import { componentProps } from '../../../../interfaces/ActionStartResponse';
import { BsSearch } from 'react-icons/bs';
import '../form.scss';
import FindDialog, { FindOption } from '../../../Generic/FindDialog';

interface Props extends componentProps {
    firstCol?: number
}

function Dropdown(props: Props) {

    const [showFindDialog, setShowFindDialog] = useState(false);
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)
    let validField = props.Valid

    const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
        updateValues(e.currentTarget.value);
    }

    useEffect(() => {
        setFieldValue(props.MisEntry.value);
    }, [props.MisEntry.value]);

    const updateValues = (value: string) => {
        setFieldValue(value);
        if (value == "") {
            validField = false;
        } else {
            validField = true;
        }
        const sendToParent = props.returnFunc;
        sendToParent({ fieldID: props.MisEntry.fieldID, value: value });
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div className={props.firstCol !== undefined ? `col-sm-${props.firstCol}` : "col-sm-3"}><label className='g-label'>{props.MisField.label} </label><small className="asterisk">*</small></div>
            <div className={props.firstCol !== undefined ? `col-sm-${12 - props.firstCol}` : "col-sm-9"}>
                <div className="controlWithButton">
                    <div>
                        <select style={{ float: "left" }} className={(validField) ? "g-Select-default" : "g-Select-default validationFailed"} id={"mis-select" + props.MisField.id!.toString()}
                            key={"Select" + props.MisField.id} onChange={handleSelectChange} value={fieldValue}  >
                            <option value=""> Please select... </option>
                            {
                                props.MisField.lookupValues?.map((item: any) =>
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                )
                            }
                        </select>
                    </div>
                    {!props.excludeQuickFind && <div >
                        <button onClick={() => setShowFindDialog(true)} type='button'><BsSearch /></button>
                    </div>}
                </div>
            </div>

            {showFindDialog &&
                <FindDialog
                    onCancel={() => setShowFindDialog(false)}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== "")
                            updateValues(selectedValue);

                        setShowFindDialog(false);
                    }}
                    values={props.MisField.lookupValues!.map<FindOption>(x => {
                        return {
                            label: x.name,
                            value: x.id.toString()
                        };
                    })}
                />
            }
        </div>
    );
}
export default Dropdown;