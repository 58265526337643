import React from 'react';
import ReactDOM from "react-dom/client";
 
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css?v=117';
import { Provider } from 'react-redux';
import store from './state/store';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { iWizardWorker, iXHRRequests } from './interfaces/workers';
export let persistor = persistStore({...store});

declare global {
  interface Window { Workers:  iWizardWorker[]
                    XhrRequests : iXHRRequests[]
      }
}
window.Workers = [] as iWizardWorker[];
window.XhrRequests = [] as iXHRRequests[];

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    
 // <React.StrictMode>
         <BrowserRouter>
           
           <PersistGate loading={null} persistor={persistor}>
              <Provider store={store}> <App    ></App></Provider>   
           </PersistGate>
        </BrowserRouter>
      
        
  //</React.StrictMode>,
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
