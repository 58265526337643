
const AccessDenied = (props: any) => {

    return (
        <div className="access-denied">
            <div className="access-denied--content">
                <div className="access-denied--content--head">Access Denied</div>
                <div className="access-denied--content--message">
                    You do not have access to this resource.
                </div>
            </div>
        </div>
    )
}

export default AccessDenied;