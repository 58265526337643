import { iLastTouched } from "../../interfaces/lastTouched";
import { getSessionValue, saveSessionValue } from "../../utilities/localStore/calls";

export const getLastTouched = (): iLastTouched => {
    return getSessionValue<iLastTouched>("LastTouched")!; // Never null: On user login sets last touched
}

export const saveLastTouched=(iLastTouched:iLastTouched)=>{
    saveSessionValue("LastTouched",iLastTouched);
}
