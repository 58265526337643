import { iCreateNoteForm } from "../../components/CreateNoteForm";
import { Delete, Post, uploadToS3Bucket } from "../../utilities/apiCall";
import { zipFile } from "../../utilities/gZip";
import { getLocalValue, saveLocalValue } from "../../utilities/localStore/calls";
import { getMimeType } from "../../utilities/mimeTypes";

export interface iFileMeta {
    ContentLength: number,
    CompressedContentLength: number,
    Name: string,
    MimeType: string,
}

export interface iNoteData {
    enquiryID: number,
    priority?: 1 | 2 | 3 | 4 | 5,
    subject?: string,
    body?: string,
    AttachmentDraftID?: number
}

export async function createNote(note: iCreateNoteForm): Promise<any> {
    return await Post("Note", noteMapper(note)).then((resp) => {
        clearDraftId(note.AttachmentDraftID);
        return resp;
    })
}

/**
 * maps to the backend note
 */
export function noteMapper(note: iCreateNoteForm): iNoteData {

    return note.AttachmentDraftID > 0 ? note.subject?.length && !note.body?.length ? {
        enquiryID: note.enquiryID,
        priority: note.priority,
        subject: note.subject,
        AttachmentDraftID: note.AttachmentDraftID
    } : !note.subject?.length && note.body?.length ? {
        enquiryID: note.enquiryID,
        priority: note.priority,
        body: note.body,
        AttachmentDraftID: note.AttachmentDraftID
    } : {
        enquiryID: note.enquiryID,
        priority: note.priority,
        subject: note.subject,
        body: note.body,
        AttachmentDraftID: note.AttachmentDraftID
    } : note.subject?.length && !note.body?.length ? {
        enquiryID: note.enquiryID,
        priority: note.priority,
        subject: note.subject
    } : !note.subject?.length && note.body?.length ? {
        enquiryID: note.enquiryID,
        priority: note.priority,
        body: note.body
    } : {
        enquiryID: note.enquiryID,
        priority: note.priority,
        subject: note.subject,
        body: note.body
    }
}

export async function saveDraftFile(file: File, attachmentDraftID: number): Promise<number> {
    const res = await createPart(file, attachmentDraftID)
    const fileId = res.id
    const uploadUrl = res.uploadUrl
    const fileByte = res.fileByte
    try {
        await uploadToS3Bucket(uploadUrl, fileByte);
        await completePart(fileId);
    } catch (error) {
        console.log(error)
    }
    return fileId;
}

export function storeDraftId(draftId: number) {
    let drafts = getLocalValue<number[]>("note-draft-ids")
    if (drafts) {
        drafts = [...drafts, draftId]
    }
    else {
        drafts = [draftId]
    }
    saveLocalValue("note-draft-ids", drafts)
}

export function clearDraftId(draftId: number) {
    let drafts = getLocalValue<number[]>("note-draft-ids")
    if (drafts) {
        drafts = drafts.filter(id => id !== draftId)
    }
    if (drafts === null || drafts.length === 0) {
        localStorage.removeItem("note-draft-ids")
        return
    }
    saveLocalValue("note-draft-ids", drafts)
}

export async function clearDraftIds() {
    let draftIds = getLocalValue<number[]>("note-draft-ids")
    if (draftIds) {
        draftIds.map(draftId => deleteAttachmentDraft(draftId))
    }
    return;
}

export async function completePart(partDraftId: number): Promise<any> {
    return await Post("AttachmentDraft/PartComplete", { ID: partDraftId })
}

export async function createAttachmentDraft(enquiryID: number): Promise<number> {
    const draftId = await Post("AttachmentDraft", { EnquiryID: enquiryID }).then((resp) => {
        storeDraftId(resp.id);
        return resp;
    })
    return draftId.id;
}

export async function deleteAttachmentDraft(draftId: number): Promise<any> {
    return await Delete(`AttachmentDraft/${draftId}`).then(() => {
        clearDraftId(draftId);
    })
}

export function createPart(file: File, attachmentDraftID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        file.arrayBuffer().then((f) => {
            zipFile(f, file.type).then((fileByte) => {
                const meta: iFileMeta = {
                    ContentLength: f.byteLength,
                    CompressedContentLength: fileByte.length,
                    MimeType: file.type ? file.type : getMimeType(file.name),
                    Name: file.name,
                }
                try {
                    Post(`AttachmentDraft/${attachmentDraftID}/Part`, meta)
                        .then((res) => {
                            resolve({ ...res, fileByte: fileByte });
                        })
                        .catch((err) => {
                            reject(err)
                        })
                } catch (error) {
                    reject(error)
                }
            })
        })
    })
}

export async function deleteFile(attachmentDraftID: number, partId: number): Promise<any> {
    return await Delete(`AttachmentDraft/${attachmentDraftID}/Part/${partId}`)
}