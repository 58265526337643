import { useEffect, useRef, useState } from 'react';
import './email.scss';
import { BsChevronDown } from "react-icons/bs";
import { getAttachment } from '../../Logic/GenericComms/CommParts';
import { getIcon } from "../../components/Generic/mimeIcon";


function MainAttachmentItem(props: any) {

    let fileTypeIcon = getIcon(props.mimeType, props.name);
   

    let attachmentSize: number = (props.size / 1000);
    let attachmentSizeIndicater: string = "KB"
    if (attachmentSize > 999.99) {
        attachmentSize = (attachmentSize / 1000);
        attachmentSizeIndicater = "MB";
    }

    const [active, setActive] = useState(false);
    const attachmentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleWindowClick = (event: MouseEvent) => {

            if (attachmentRef.current && !attachmentRef.current.contains(event.target as Node)) {
                setActive(false);
            }
        };

        window.addEventListener('click', handleWindowClick);

        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, [attachmentRef]);

    const toggleActive = () => {
        setActive(!active);
    };

    return (

        <div style={{ display: "inline-block", marginBottom: "16px", marginRight: "8px" }}>

            <div key={"mainAttachment_" + props.id} className="attachment-container" ref={attachmentRef}>
                <div className="attachment">
                    <div className="attachment--details" onClick={() => { getAttachment(props, "open") }}>
                        {/* <img className="attachment--details--icon file-type-red" src={fileTypeIcon} alt="Attachment file type" /> */}
                        {fileTypeIcon}
                        <div className="attachment--details--file-details" title={props.name}>
                            <div className="attachment--details--file-details--file-name limit-text" data-testid="testName">{props.name}</div>
                            <div className="attachment--details--file-details--file-size limit-text" data-testid="dataSize">{`${(attachmentSize).toFixed(2)} ${attachmentSizeIndicater}`}</div>
                        </div>
                    </div>

                    <button className={`attachment--btn-dropdown${active ? " active" : ""}`} onClick={toggleActive} id={`attachment--btn-dropdown-${props.id}`} title="More actions">
                        <BsChevronDown className='attachment--btn-dropdown--icon' id={`attachment--btn-dropdown--icon-${props.id}`} />
                    </button>
                </div>
                {active && (
                    <div className="dropdown-menu-options" id={`dropdown-menu-options-${props.id}`}  >
                        <div onMouseLeave={toggleActive} className='dropdown-menu-options-buffer'>
                            <div key={1} className="dropdown-menu-options--option limit-text" onClick={() => { getAttachment(props, "open") }}>Open</div>
                            <div key={2} className="dropdown-menu-options--option limit-text" onClick={() => { getAttachment(props, "download") }}>Download</div>
                            <div key={3} className="dropdown-menu-options--option limit-text" onClick={() => { getAttachment(props, "tempStore") }}>Temporary Store</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MainAttachmentItem;
