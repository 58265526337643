import { HTMLInputTypeAttribute, useState } from 'react';
import '../form.scss';
import React from 'react';
import { IsEmailAddress } from '../../../../utilities/string';
export interface emailBoxProps {
    id: string,
    label: string,
    value: any,
    onChange: (newValue: string) => any,
    setFocus?: boolean
    maxLength: number
    firstCol?: number
    requiredIdentifier?: boolean
}
function Email(props: emailBoxProps) {
    const [fieldValue, setFieldValue] = useState(props.value)
    React.useEffect(() => {
        setFieldValue(props.value)
    }, [props.value]);
    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {

        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);

    }
    const handleOnblur = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value != "") {
            if (IsEmailAddress(e.currentTarget.value)) {
                e.currentTarget.className = "g-TextBox-default";
            }
            else
            {
                e.currentTarget.className = "g-TextBox-default validationFailed";
            }
        }
    }
    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
            <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}><label className='g-label'>{props.label}</label><small className="asterisk">{(props.requiredIdentifier ? "*" : "")}</small></div>
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
            <input className={"g-TextBox-default"} onBlur={handleOnblur} onChange={handleInputChange} value={fieldValue} maxLength={props.maxLength} autoFocus={props.setFocus} />  
            </div>
        </div>
    );
}
export default Email;

