import React from "react";
import Confirm from "../Generic/ConfirmDialog";
import TextBox from "../Generic/Form/TextBox";
import Dropdown from "../Generic/Form/DropDown";
import { BsPaperclip } from "react-icons/bs"
import { createAttachmentDraft, createNote, deleteAttachmentDraft, deleteFile, saveDraftFile } from "../../Logic/GenericComms/createNote";
import { MisEntry } from "../../interfaces/ActionStartResponse";
import Attachment from "./attachment";
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../../error-boundary/errorFallback'
import { logErrorToService } from '../../error-boundary/logerror'

export interface Props {
    enquiryId: number,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void
}

export interface iAttachment {
    f: File,
    partId: number
}

export interface iCreateNoteForm {
    enquiryID: number,
    priority: 1 | 2 | 3 | 4 | 5,
    attachments?: iAttachment [],
    subject?: string,
    body?: string,
    AttachmentDraftID: number
}

function CreateNote(props: Props) {

    const [note, setNote] = React.useState<iCreateNoteForm>({ enquiryID: props.enquiryId, priority: 3, attachments: [], AttachmentDraftID: 0 });
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const validPriority = note?.priority !== undefined;
    const validNote = ((note.body !== undefined && note.body.length > 0) || (note.subject !== undefined && note.subject.length > 0) ) && validPriority;
    function handleChange(key: keyof iCreateNoteForm, newValue: string | number | undefined) {
        setNote({ ...note!, [key]: newValue })
    }
    async function handleFileChange(e: any) {
        if (e.target.files && e.target.files.length) {
            if (note.AttachmentDraftID === 0) {
                const dId = await createAttachmentDraft(props.enquiryId);
                const fileId = await saveDraftFile(e.target.files[0], dId);
                setNote(n => ({
                    ...n,
                    AttachmentDraftID: dId,
                    attachments: n.attachments ?
                        [...n.attachments, { partId: fileId, f: e.target.files[0] }] :
                        [{ partId: fileId, f: e.target.files[0] }]
                }));
            }
            else {
                const fileId = await saveDraftFile(e.target.files[0], note.AttachmentDraftID);
                setNote(n => ({
                    ...n,
                    AttachmentDraftID: note.AttachmentDraftID,
                    attachments: n.attachments ?
                        [...n.attachments, { partId: fileId, f: e.target.files[0] }] :
                        [{ partId: fileId, f: e.target.files[0] }]
                }));
            }
        }
    }

    function handleCancel() {
        if (note.AttachmentDraftID > 0) {
            deleteAttachmentDraft(note.AttachmentDraftID)
        }
        props.onCancel();
    }

    function deleteAttachmentPart(partId: number) {
        
        deleteFile(note.AttachmentDraftID, partId).then(() => {
            setNote(n => ({ ...n, attachments: n.attachments?.filter((a) => a.partId !== partId) }))
        })
    }


    function handleSubmit() {
        if ((note?.body?.length || note?.subject?.length) && validPriority) {
            createNote(note).then(() => {
                props.onCreated()
            }).catch(() => {
                props.onError()
            });
        }
        setConfirmCancel(false);
    }

    return (<>
       <Confirm
            title="Enquiry Note"
            onCancel={() => { setConfirmCancel(true) }}
            onConfirm={() => { handleSubmit() }}
            isValid={validNote}
        >
            <Dropdown
                MisField={{
                    id: 273283,
                    name: "create-note-priority-dropdown",
                    label: "Priority",
                    defaultValue: "3",
                    displayOrder: 0,
                    fieldType: "Dropdown",
                    lookupValues: [
                        { name: "Highest", id: 1, order: 1 },
                        { name: "High", id: 2, order: 2 },
                        { name: "Normal", id: 3, order: 3 },
                        { name: "Low", id: 4, order: 4 },
                        { name: "Lowest", id: 5, order: 5 }
                    ]
                }}
                MisEntry={{ fieldID: 3, value: "3" }}
                returnFunc={(entry: MisEntry) =>
                    handleChange("priority", entry.value !== "" ? +entry.value : undefined)}
                Valid={validPriority}
                excludeQuickFind={true}
            />
            <TextBox
                id={"create-note-subject"}
                label={"Subject"}
                value={note?.subject || ""}
                onChange={(value: string) => handleChange("subject", value)}
                maxLength={50}
            />
            <div className="d-flex mb-2 justify-content-between align-items-center">
                <div className="g-tab-selected g-default-tab-padding" style={{ marginLeft: "0px" }}>Note Composer</div>
                <label htmlFor="create-note-attachment" className="g-btn-icon-shadow"> <i><BsPaperclip /></i> </label>
                <input type="file" onChange={handleFileChange} className="d-none" name="attachment" id="create-note-attachment" />
            </div>
            <>{(note.attachments && note.attachments.length > 0) &&
                <div className="d-flex pb-1 justify-content-between align-items-center mb-2 overflow-auto"
                    style={{ gap: "5px", maxWidth: "317px" }}>
                    {note.attachments?.map((att, i) => <Attachment
                        key={`note-${i}-att`}
                        attachment={att}
                        onAttDelete={(partId) => { deleteAttachmentPart(partId)}}
                    />)}
                </div>
            }</>
            <textarea
                className="g-TextArea-default"
                onChange={(e) => { handleChange("body", e.currentTarget.value) }}
                value={note?.body || ""}
            />
        </Confirm>
        {confirmCancel && <ErrorBoundary FallbackComponent={ErrorFallback}
             > <Confirm
            onCancel={() => setConfirmCancel(false)}
            title="Warning"
            onConfirm={() => { handleCancel() }}
        >
            <>Unsaved changes will be lost. Are you sure that you wish to close?</>
            </Confirm></ErrorBoundary>}
    </>)
}

export default CreateNote;
