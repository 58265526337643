import { EPriority } from "./Priority";

export interface iEmail extends iLocalMessage {
    uId: string;
    id?: number;
    enquiryId: number;
    dateSent: string;
    incoming: boolean;
    priority: EPriority;
    subject: string;
    hasAttachments: string;
    unread: string;
    unsent: string;
    body: string;
    date: string;
    maildraftId: number,
    embeddedMessageId?: number,
    includeEmbeddedMessageAttachments?: boolean,
    from: { name?: string, address: string }
    to: Array<{ name?: string, address: string }>
    cc: Array<{ name?: string, address: string }>
    bcc: Array<{ name?: string, address: string }>,
    updateDate?: Date
}
export interface iAttachments {
    body: iAttachment,
    inlineAttachments: Array<iAttachment>,
    attachments: Array<iAttachment>,
}
export interface iAttachment {
    id: number,
    contentLength?: number,
    compressedContentLength?: number,
    name: string,
    mimeType?: string,
    mimeContentID?: string,
    mimeContentLocation?: string,
    type: number,
    url?: string
}

export interface iLocalMessage {
    communicationId: number,
    body: string,
    attachments: Array<iLocalMessageAttachment>,
    inlineAttachments?: Array<{ id: string, file: string, name: string, mimeType: string, size: number, dataUrl?: string, mimeContentID?: string }>,
    type: string,
    attachedMessages?: number[]
}

export interface iDraftRequest {
    To?: Array<{ Name: string, Address: string }>,
    Cc?: Array<{ Name: string, Address: string }>,
    Bcc?: Array<{ Name: string, Address: string }>,
    Subject: string,
    EnquiryID?: number,
    Priority: EPriority,
    AttachedMessages?: number[]
}

export interface iLocalMessageAttachment{
    id: string, file: string, name: string, mimeType: string, size: number, 
    status? : "pending" | "uploaded" | "error";
    uploadUrl?:string
}

export interface iComposeEmail {
    To: string,
    Cc?: string,
    Bcc?: string,
    Subject: string,
    Body: string,
    Attachments?: Array<iAttachment>
}

export interface iPart {
    ContentLength: number,
    CompressedContentLength: number,
    Name?: string,
    MimeType: string;
    MimeContentID?: string,
    Type: number,
}

export function isEmail(search: string): boolean {
    var serchfind: boolean;
    var regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(search);
    return serchfind;
}