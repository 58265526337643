import { HTMLInputTypeAttribute, useState } from 'react';
import '../form.scss';
import React from 'react';

export interface textboxProps {
    id: string,
    label?: string,
    value: any,
    onChange: (newValue: string) => any,
    setFocus?: boolean
    maxLength: number
    firstCol?: number
    isNotValid?: boolean
    requiredIdentifier?: boolean
    disabled?:boolean
}

function TextBox(props: textboxProps) {
    const [fieldValue, setFieldValue] = useState(props.value)
   
    React.useEffect(()=>{
            setFieldValue(props.value)
      },[props.value]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
       
        setFieldValue(e.currentTarget.value);
        props.onChange(e.currentTarget.value);
         
    }
   
    return (
        <div className="row rowMarginControl" key={"Main" + props.id}>
            {props.label && <div className={props.firstCol == undefined ? "col-sm-3" : "col-sm-" + props.firstCol}><label className='g-label'>{props.label}</label><small className="asterisk">{(props.requiredIdentifier ?"*":"")}</small></div>}
            <div className={props.firstCol == undefined ? "col-sm-9" : "col-sm-" + (12 - props.firstCol)}>
                <input disabled={props.disabled} className={!(props.isNotValid) ? "g-TextBox-default" : "g-TextBox-default validationFailed"} onChange={handleInputChange} value={fieldValue} maxLength={props.maxLength} autoFocus={props.setFocus} autoComplete="off" aria-autocomplete='none' list="autocompleteOff" />
            </div>
        </div>
    );
}
export default TextBox;

