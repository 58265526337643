import React from "react";
import Confirm from "../Generic/ConfirmDialog";
import { iUser } from "../../interfaces/iUser";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from '../../state/store'
import { RemoveEnquiriesRequest, RemoveEnquiries as RemoveEnquiriesCall } from "../../Logic/EmsAPI/Enquiries";

export interface Props {
    enquiries: Array<iEnquiry>,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void,
    UserList?: iUser[] | undefined,
}

 function RemoveEnquiries(props: Props) {  
    const userID = useSelector((state: RootState) => state.UserInformation).userID;
    const dispatch = useAppDispatch();
    async function UpdateEnquiryStatus(){
        let removeEnquiriesRequest: RemoveEnquiriesRequest = {
                Enquiries: props.enquiries.map(enq => enq.id)
            }
             
      const response = await RemoveEnquiriesCall(removeEnquiriesRequest);
      if (!response.isOk) {
          throw new Error(`${response.status} returned from server.`);
      }
      props.onCreated();
    }
    return (<><Confirm title="Warning"
        onCancel={() => props.onCancel()}
        onConfirm={() => { UpdateEnquiryStatus() }}
    >
        <>Are you sure you want to remove the enquiry/enquiries from the list?</>
        </Confirm> <></>
 </>
 )
}
export default RemoveEnquiries;