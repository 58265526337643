import iSkin from "../interfaces/Configuration/iSkin";
import iTag from "../interfaces/Configuration/iTag";
import iTemplate from "../interfaces/Configuration/iTemplate";
import { iMailboxConfig } from "../interfaces/iMailboxConfig";
import store from "../state/store";
import { Get } from "../utilities/apiCall";
import { addItem, getItem } from "../utilities/localStore/indexDBCalls";

export async function GetCachedTemplate(id: number): Promise<iTemplate> {
    const configMetadata = store.getState().Configuration;

    const lastUpdatedAt = configMetadata?.templates.find(x => x.id === id)?.updatedAt;
    if (!lastUpdatedAt) {
        throw new Error("Template not found.");
    }

    return await GetOrRefreshCache("template-" + id, lastUpdatedAt, "Config/Template/" + id) as iTemplate;
}

export async function GetCachedSkin(id: number): Promise<iSkin> {
    const configMetadata = store.getState().Configuration;

    const lastUpdatedAt = configMetadata?.skins.find(x => x.id === id)?.updatedAt;
    if (!lastUpdatedAt) {
        throw new Error("Skin not found.");
    }

    return await GetOrRefreshCache("skin-" + id, lastUpdatedAt, "Config/Skin/" + id) as iSkin;
}

export async function GetCachedTenantTag(key: string): Promise<iTag> {
    const configMetadata = store.getState().Configuration;

    const lastUpdatedAt = configMetadata?.tags.find(x => x.key === key)?.updatedAt;
    if (!lastUpdatedAt) {
        throw new Error("Tag not found.");
    }

    return await GetOrRefreshCache("tag-" + key, lastUpdatedAt, "Config/Tag/" + key) as iTag;
}

export async function GetCachedEditorConfig(id: number): Promise<iMailboxConfig> {
    const configMetadata = store.getState().Configuration;

    const lastUpdatedAt = configMetadata?.editorConfigs.find(x => x.id === id)?.updatedAt;
    if (!lastUpdatedAt) {
        throw new Error("EditorConfig not found.");
    }

    return await GetOrRefreshCache("editorConfig-" + id, lastUpdatedAt, "Config/Editor/" + id) as iMailboxConfig;
}

async function GetOrRefreshCache(uId: string, lastUpdatedAt: Date, refreshUrl: string): Promise<any> {
    const cachedData = await GetCachedConfig(uId);
    if (cachedData && cachedData.updatedAt === lastUpdatedAt) {
        return cachedData.data as iTemplate;
    }

    const newData = await Get(refreshUrl);
    await UpdateCachedConfig(uId, lastUpdatedAt, newData);
    return newData;
}

async function GetCachedConfig(uId: string): Promise<any> {
    return await getItem("Configurations", uId);
}

async function UpdateCachedConfig(uId: string, lastUpdatedAt: Date, data: any) {
    addItem("Configurations", { uId, updatedAt: lastUpdatedAt, data });
}