import { useEffect } from 'react'
import './styles.scss'
import { BsExclamationCircleFill, BsInfoCircleFill, BsXLg } from 'react-icons/bs'

export type confirmProps = {
    title?: string | undefined,
    messageType?: "info" | "warn" | "err" | undefined,
    onConfirm: Function,
    onCancel?: Function | undefined,
    children?: JSX.Element[] | JSX.Element | undefined,
    suppressKeyCapture?: boolean | undefined,
    isValid?: boolean
}

const Confirm = (props: confirmProps) => {

    const getIcon = () => {
        if (props.messageType === undefined) {
            return []
        }
        if (props.messageType === "info") {
            return <i className="icon">
                <BsInfoCircleFill  className="confirm-message-icon"/>
            </i>
        }
        if (props.messageType === "warn") {
            return <i className="icon">
                <BsExclamationCircleFill  className="confirm-message-icon"/>
            </i>
        }
    }

    useEffect(() => {
        if (props.suppressKeyCapture) {
            return;
        }
        const keyDownHandler = async (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                props.onConfirm();
            }
            else if (e.key === "Escape") {
                props.onCancel?.();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [props.suppressKeyCapture]);

    return (
        <div className="confirm-comp">
            <div className="content">
                <section className="header-section">
                    <div className="header">
                        {props.title ?? ""}
                    </div>
                    <i
                        className='x'
                        onClick={() => { props.onCancel ? props.onCancel() : props.onConfirm() } }
                    >
                        <BsXLg className='close-bttn' />
                    </i>
                </section>
                <section className="body">
                    <div className="confirm-message-body">
                        {props.messageType && getIcon()}
                        <div style={{width:"100%"}}>
                            {props.children}
                        </div>
                    </div>
                </section>
                <section className="buttons-section">
                    <div className="buttons-wrapper">
                    <button key="dialog-confirm" className={`g-btn ${(props.isValid === false ? "g-btn-disabled" : "g-btn-action")}`} onClick={() => props.onConfirm()}>Ok</button>
                        {props.onCancel && <button key="dialog-cancel" className="g-btn g-btn-alternate" onClick={() => props.onCancel?.()}>Cancel</button>}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Confirm