import { Delete, callApi, iRequest } from "../utilities/apiCall"
import { getActiveListEnquiries, getEnquiries, getEnquiriesMap, getSessionValue, saveSessionValue } from "../utilities/localStore/calls"
import { iEnquiry,iEnquiries } from "./iEnquiry"
import { iLastTouched } from "../interfaces/lastTouched";
import { setItemEnabled } from "../utilities/loading";
import { AdvancedSearchResults } from "./AdvancedSearch/AdvancedSearchResults";
import { getUnresolvedEnquiriesByQueueGlobal } from "../Logic/Enquiries/Queue";
import { getResolvedEnquiriesGlobal, getUnresolvedEnquiriesGlobal } from "../Logic/Enquiries/My";
import { GetSearchResults } from "../Logic/Search";
import { AdvancedSearchPM } from "./AdvancedSearch/AdvancedSearchPM";
import { getLastTouched } from "../Logic/LastTouched/misc";
import store from "../state/store";

export interface Enquiries {
    enquiries: Array<EnquiryList>
}

export interface EnquiryList {
    title: string,
    enquiries: Array<iEnquiry>
}

 

export const getActiveEnquiries =(): iEnquiries =>{
         const ActiveList = getActiveListEnquiries();
        const allEnquiries = getEnquiries(ActiveList?? []);
        return {"enquiries":allEnquiries}
 
}

export   const  refreshList  = async (userId: number, updateState?:boolean): Promise<any> => {
    const iLastTouched = getLastTouched();
    const listName = iLastTouched?.lastActiveListType
    if (listName == 'Unresolved') {
        return  await getUnresolvedEnquiriesGlobal(userId) ;
    }
    else if (listName == 'Resolved') {
        return await getResolvedEnquiriesGlobal(userId)
       
    }
    else if(listName == 'Results'){
        const sQuery =  getSessionValue<AdvancedSearchPM>("advancedSearchQuery") ; 
        if (sQuery) {
            const results = await GetSearchResults(sQuery);
            saveSessionValue("advancedSearchResults", results);
            return results;
        }
    }
    else if (!Number.isNaN(listName)) {
        return await getUnresolvedEnquiriesByQueueGlobal(Number(listName))
    }

    return new Promise<any>((resolve, reject) => reject());
}

export const getActiveList = (actList: string) => {
    const iLastTouched = getLastTouched();

    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export const getLastActiveList = () => {
    const iLastTouched = getLastTouched();
    const actList = iLastTouched.lastActiveListType
    if (actList == "Mailboxes") {
        return iLastTouched.Queue.ActiveList + ""
    }
    else if (actList == "My Enquiries") {
        return iLastTouched.MyEnquiries.ActiveList
    }
    return ""
}

export async function activateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    var promise = new Promise<any>((resolve, reject) => {

        let request: iRequest = {
            url: `Enquiry/${enquiryId}/Action`,
            method: "POST",
            headers: [{ key: "Content-Type", value: "application/json" }],
            body: "{}",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            setItemEnabled(id, true)

            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });

    })
    return promise;
}
export async function deactivateEnquiryWizard(enquiryId: number, id: string = ""): Promise<any> {
    setItemEnabled(id, false)
    if (document.getElementById("loader")) {
        (document.getElementById("loader") as HTMLElement)!.style.display = "block";
    }
    
    try {
        await Delete(`Enquiry/${enquiryId}/Action`);
    } catch { } // Do nothing as enquiry is probably no longer locked anyway.
    
    setItemEnabled(id, true);
}

export const getEnquiryById = (id: number): iEnquiry | undefined => {
    let Enquiry: iEnquiry | undefined;
    const enquiries = store.getState().EnquiryCache.data;
    Enquiry = enquiries.find(x=>x.id==id);
    return Enquiry;
}

export const scrollToView = (elementId: string, scrollParentId: string) => {

    let elementContainer = document.getElementById(scrollParentId)!;
    let elementHtml: HTMLElement = document.getElementById(elementId)!;
    if (elementContainer != null && elementHtml != null) {
        if (!isWithinParentView(elementHtml, elementContainer)) {
            if (elementHtml.offsetTop > 400) {
                elementContainer.scrollTop = elementHtml.offsetTop - 400;
            }
            else {
                elementContainer.scrollTop = 0;
            }
        }
    }
}

const getMaxScroll = (container: HTMLElement) => {
    return container.scrollHeight - container.clientHeight;
}

export const isWithinParentView = (elt: HTMLElement, parent: HTMLElement) => {
    const eltRec = elt.getBoundingClientRect();
    const parRec = parent.getBoundingClientRect();

    const eltAbove = eltRec.bottom < parRec.top
    const eltBelow = eltRec.top > parRec.bottom

    return !(eltAbove || eltBelow)
}