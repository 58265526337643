import { BsEnvelope, BsEnvelopeOpen, BsWhatsapp, BsSticky, BsCardHeading, BsFileText, BsPeople, BsTelephone, BsClipboard2Check, BsEnvelopePaper } from "react-icons/bs";
import { Instagram, MessageCircle } from 'react-feather';

type commIconProps = {
    type: string,
    unread?: boolean | undefined,
    className?: string | undefined,
    inBound?: boolean | undefined,
}

function commIcon(props: commIconProps) {
    if (props.type == "Email") {
        return (
            props.inBound ? (props.unread ? <BsEnvelope className={props.className} /> :
                <BsEnvelopeOpen className={props.className} />) :
                <BsEnvelopePaper className={props.className} />
        )
    }
    if (props.type == "Note") return <BsSticky className={props.className} />
    if (props.type == "Task") return <BsClipboard2Check className={props.className} />
    if (props.type == "Phone Old") return <BsTelephone className={props.className} />
    if (props.type == "Facsimile") return <BsCardHeading className={props.className} />
    if (props.type == "Meeting") return <BsPeople className={props.className} />
    if (props.type == "Letter") return <BsFileText className={props.className} />
    if (props.type == "Phone") return <BsTelephone className={props.className} />

    if (props.type == "Instagram") return <Instagram className={props.className} />
    if (props.type == "Whatsapp") return <BsWhatsapp className={props.className} />
    // default
    return <MessageCircle className={props.className} />
}

export default commIcon;