export enum ActiveTabName {
    general = "General",
    datesTimes = "Dates & Times",
    owners = "Owners",
    alarms = "Alarms",
    history = "History",
    production = "Production",
    userCycle = "User Cycle",
    response = "Response",
    mis = "MIS"
}