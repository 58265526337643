import {useEffect, useRef} from 'react';

const useInterval = (callback: () => void, interval: number) => {
  const savedCallback = useRef(() => {});

  savedCallback.current = callback;

  useEffect(() => {
    const intervalId = setInterval(() => {
        savedCallback.current()
    }, interval);

    return () => {
        clearInterval(intervalId)
    };
  }, [interval]);
}

export default useInterval;