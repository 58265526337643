import { configureStore,Action, ThunkDispatch, applyMiddleware, PayloadAction  } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import workflowReducer from './workflowSlice'
import UserInformation from './userSlice'
import QueuesSlice  from './queueSlice'
import commSlice  from './commSlice'
import activeEnquiry  from './activeEnquiry'
import secondCol from './secondColSlice'
import tabRow from './tabRow'
import { useDispatch } from 'react-redux'
import SiteSlice from './siteSlice'
import Connection from './connection'
import enquiriesCache from './enquiriesCache'
import { persistStore, persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session' 
import { combineReducers } from 'redux'
import activeWizardSlice from './activeWizards'
import activeComm from './activeComm'
import RestrictedView from './restrictedViewSlice'
import PermissionSlice from './PermissionSlice'
import Configuration from './configSlice'

const persistConfig = {
    key: 'root',
    storage,
  }
  const combinedReducers = combineReducers({
        getWorkflow: workflowReducer?.reducer,
        UserInformation: UserInformation.reducer,
        QueueInformation: QueuesSlice.reducer,
        SiteInformation: SiteSlice.reducer,
        commToView: commSlice.reducer,
        activeEnquiry: activeEnquiry.reducer,
        secondCol:secondCol.reducer,
        tabRow:tabRow.reducer,
        activeWizards: activeWizardSlice.reducer,
        Connection: Connection.reducer,
        activeComm: activeComm.reducer,
        restrictedView: RestrictedView.reducer,
        permissions: PermissionSlice.reducer,
        Configuration: Configuration.reducer,
        EnquiryCache: enquiriesCache.reducer
});
const rootReducer = (state:any, action:any) => {
  if (action.type === 'USER_LOGOUT') { // check for action type 
    state = undefined;
    storage.removeItem('persist:root')
  }
  return combinedReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
 export  const store = configureStore({

    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })//.concat(createStateSyncMiddleware({blacklist: ["persist/PERSIST", "persist/REHYDRATE"]}))
});

//used for testing
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()//.concat(createStateSyncMiddleware({blacklist: ["persist/PERSIST", "persist/REHYDRATE"]}))
  })
}

 


//initStateWithPrevTab(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

export default store;
