import { IconBaseProps } from "react-icons";

export default function ExclamationLg({ color }: IconBaseProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.005 3.19988C6.99104 3.04819 7.00638 2.89499 7.05003 2.75017C7.09368 2.60535 7.16467 2.47211 7.25842 2.35906C7.35217 2.246 7.4666 2.15564 7.59433 2.0938C7.72206 2.03195 7.86026 2 8 2C8.13974 2 8.27794 2.03195 8.40567 2.0938C8.5334 2.15564 8.64783 2.246 8.74158 2.35906C8.83533 2.47211 8.90632 2.60535 8.94997 2.75017C8.99362 2.89499 9.00896 3.04819 8.995 3.19988L8.607 10.1272C8.59201 10.2915 8.52159 10.4438 8.40942 10.5546C8.29724 10.6654 8.15132 10.7268 8 10.7268C7.84868 10.7268 7.70276 10.6654 7.59058 10.5546C7.47841 10.4438 7.40799 10.2915 7.393 10.1272L7.005 3.19988ZM7 12.9091C7 12.6197 7.10536 12.3423 7.29289 12.1377C7.48043 11.9331 7.73478 11.8182 8 11.8182C8.26522 11.8182 8.51957 11.9331 8.70711 12.1377C8.89464 12.3423 9 12.6197 9 12.9091C9 13.1984 8.89464 13.4759 8.70711 13.6805C8.51957 13.8851 8.26522 14 8 14C7.73478 14 7.48043 13.8851 7.29289 13.6805C7.10536 13.4759 7 13.1984 7 12.9091Z"
                fill={color ?? "#FF0000"}
            />
        </svg>
    );
}