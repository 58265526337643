import { useEffect, useState } from "react"

interface iWindowSize {
    width: number,
    height: number
}

export function useWindowResize(persistHandleResize?: (width: number, height: number) => void) {

    const [windowSize, setWindowSize] = useState(getCurrentWindowSize())

    const handleResize = () => {
        const currentSize = getCurrentWindowSize()
        setWindowSize(currentSize)
        persistHandleResize?.(currentSize.width, currentSize.height)
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return { width: windowSize.width, height: windowSize.height };
}

function getCurrentWindowSize(): iWindowSize {
    return { width: window.innerWidth, height: window.innerHeight }
}