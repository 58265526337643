import { useRef, useState } from "react";
import { BsList } from "react-icons/bs";
import useOutsideClick from "../../hooks/useOutsideClick";
import { EnquiryMenuProps } from "../../interfaces/menu";
import { EnquiryMenuItems } from ".";

export function EnquiryBurgerMenu({ enquiryId }: EnquiryMenuProps) {

    const [barMenuActive, setBarMenuActive] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null)
    useOutsideClick(menuRef, () => setBarMenuActive(false));

    return (
        <div className={`g-dropdown-basic${barMenuActive ? "-selected" : ""}`} ref={menuRef}>
            <button tabIndex={0} onClick={() => setBarMenuActive(b => !b)} className={`enquiry-burger-button${barMenuActive ? " selected" : ""} col-xs-1`}>
                <i><BsList style={{width:"16px", height:"16px"}} /></i>
            </button>
            {barMenuActive && <div
                style={{ position: "absolute", top: 30, left: -123 }}
            >
                <EnquiryMenuItems onHide={()=>setBarMenuActive(false)} enquiryId={enquiryId} menuStyle={"menu"} />
            </div>
            }
        </div>
    )
}
