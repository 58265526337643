import { useState } from 'react';
import { componentProps } from '../../../interfaces/ActionStartResponse';
import '../mis.scss';

function Memo(props: componentProps) {
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)

    const handleSelectChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        setFieldValue(e.currentTarget.value);
        const sendToParent = props.returnFunc;
        sendToParent({ fieldID: props.MisEntry.fieldID, value: e.currentTarget.value });
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div className='col-sm-3'><label className='g-label'>{props.MisField.label}</label></div>
            <div className='col-sm-9'>
                <textarea
                    className="mis-comment"
                    onChange={handleSelectChange}
                    value={fieldValue}
                    maxLength={512}
                />
                <label></label>
            </div>
        </div>
    );
}
export default Memo;

