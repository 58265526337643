
import { useEffect, useRef, useState } from "react";
import ConfirmDialog from "../../Generic/ConfirmDialog";
import { FindDialogProps, FindOption } from "../../Generic/FindDialog";
import Preview from "../../Email/preview";
import { iGenericComm } from "../../../interfaces/iGenericComm";

type previewProps =  {
    comm: iGenericComm,
    FullWidth: boolean,
    enquirerName: string,
    activeEnquiryId:number,
    onCancel: Function,
}
function PreviewMessage(props: previewProps){

 

    const firstTabElement = useRef<HTMLInputElement>(null);

    return (
        <>
            <ConfirmDialog title="" onConfirm={() => props.onCancel( )} onCancel={() =>  {props.onCancel()}}>
                <div style={{width:"650px", height:"550px",overflowY:"auto"}}>
                     <Preview comm={props.comm} FullWidth={props.FullWidth} enquirerName={props.enquirerName} activeEnquiryId={props.activeEnquiryId}/>
                </div>
            </ConfirmDialog>
            <span tabIndex={0} onFocus={() => firstTabElement.current?.focus()} />
        </>
    );
}

export default PreviewMessage