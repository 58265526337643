import { getTenantToken, UpdateAuthTokens } from "../Auth/TokenManager";
import { showHideLoader } from "../../utilities/loading";

export interface iEmsApiResponse {
    isOk: boolean;
    status: number;
    statusText: string;
    validationErrors?: iValidationError | undefined;
}

export interface iEmsApiResponseBody<TResponse> extends iEmsApiResponse {
    data?: TResponse | undefined;
}

export interface iValidationError {
    title: string;
    errors: {
        [key: string]: string[];
    };
}

export async function CallEmsApi<TResponse>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    url: string,
    body?: any,
): Promise<iEmsApiResponseBody<TResponse>> {
    showHideLoader("flex");

    const emsResponse = {
        isOk: false,
        status: 0,
        statusText: "",
        data: undefined,
        validationErrors: {
            title: "",
            errors: {},
        },
    };

    let sURL = process.env.REACT_APP_API + url;

    const headers = new Headers();
    headers.append("Authorization", "bearer " + getTenantToken());

    if (body) {
        headers.append("Content-Type", "application/json");
    }

    var fetchOptions: any = {};
    fetchOptions.method = method;
    fetchOptions.headers = headers;
    if (body) {
        fetchOptions.body = JSON.stringify(body);
    }

    var response = await fetch(sURL, fetchOptions);
    if (response.status === 401) {
        await UpdateAuthTokens();
        response = await fetch(sURL, fetchOptions);
    }

    emsResponse.isOk = response.ok;
    emsResponse.status = response.status;
    emsResponse.statusText = response.statusText;

    if (response.ok && response.status === 200) {
        emsResponse.data = await response.json();
    } else if (response.status === 400) {
        emsResponse.validationErrors = await response.json();
    }

    showHideLoader("none");
    return emsResponse;
}
