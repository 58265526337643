import { useRef, useState } from "react";
import { BsPaperclip, BsChevronDown, BsFileEarmark, BsEnvelope} from "react-icons/bs";
import useOutsideClick from "../../../hooks/useOutsideClick";
import '../Workflow.scss'
import { EPriority } from "../../../interfaces/Priority";
import ArrowDown from "../../../assets/svg/arrow-down.icon";
import ExclamationLg from "../../../assets/svg/exclamation-lg.icon";

type barProps = {
    uploadFunction: Function,
    embedMessageFunction?: ()=>void | undefined,
    onPriorityChange: (name: EPriority) => void,
    emailPriority: EPriority
}

function EmailToolBar(props:barProps) {
    const fileDropdownRef = useRef<HTMLDivElement>(null);
    const fileButtonRef = useRef<HTMLButtonElement>(null);
    const [showAttachMenu, setShowAttachMenu] = useState(false)
    const ShowFileDropdown = ()=>{
        setShowAttachMenu(true)
    }
    const HideFileDropdown = ()=>{
        setShowAttachMenu(false);
    }

    const openFileDialogue = ()=>{
        HideFileDropdown()
        props.uploadFunction();
    }
    const embedMessage = ()=>{
        HideFileDropdown()
        props.embedMessageFunction!();
    }

    useOutsideClick(fileDropdownRef, HideFileDropdown)

    return (
            <div  style={{border:"solid 1px #EDEDED", padding:"3px",paddingBottom:"3px", width:"78px", }}>
                <div style={{display: " grid", columnGap:"0px", gridTemplateColumns:"20px 20px 28px 55px", }}>
                   
                    <button
                        onClick={() => props.onPriorityChange(EPriority.LOWEST)}
                        className={`email-priority low${props.emailPriority > EPriority.NORMAL ? " active" : ""}`}
                  
                    >
                        <i><ArrowDown className="priority-icon" /></i>
                    </button>
                    <button
                        onClick={() => props.onPriorityChange(EPriority.HIGHEST)}
                        className={`email-priority high${props.emailPriority < EPriority.NORMAL ? " active" : ""}`}
                     
                    >
                        <i><ExclamationLg className="priority-icon" /></i>
                    </button>
                    
                        <button className="email-priority normal"  onClick={ShowFileDropdown} style={{border:"0px solid red" ,paddingLeft:"0px",paddingRight:"0px" ,margin:"0px"}} ref={fileButtonRef}><BsPaperclip style={{width:"12px"}}></BsPaperclip> <BsChevronDown style={{width:"10px"}}></BsChevronDown></button>
                    
                    
                </div>    
              {
               showAttachMenu && <div className="tlbar-dropdown-list" ref={fileDropdownRef}>
                                <div onClick={embedMessage} className="tlbar-optn" style={{opacity: props.embedMessageFunction==undefined? "0.7" : "1", pointerEvents:props.embedMessageFunction==undefined? "none" : "all"}}>
                                    <BsEnvelope className="tlbar-optn-icon" />
                                    <label className="tlbar-optn-label">Insert Messages from Enquiry</label>
                                </div>
                                <div onClick={openFileDialogue} className="tlbar-optn">
                                    <BsFileEarmark className="tlbar-optn-icon" />
                                    <label className="tlbar-optn-label">Insert Files from folders</label>
                                </div>
                            </div>
                }
            </div> 
    );
}

export default EmailToolBar;
