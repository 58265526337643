import QueueLists from '../../Enquiries/QueueLists/index';
import MyEnquiries from '../../Enquiries/MyEnquiry';
import SearchResults from '../../Enquiries/SearchResults/Results'
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
function FirstCol(){
    const SiteInformation = useSelector((state: RootState) => state.SiteInformation);
return(
    <>
      {
      SiteInformation.queuesListVisible && <QueueLists></QueueLists>
      }

      {
       SiteInformation.enquiriesListVisible && <MyEnquiries  FullWidth={true}></MyEnquiries>
      }
      {
    SiteInformation.searchResultsVisible && <SearchResults  FullWidth={true}></SearchResults>
      }
      </>
)

};
export default FirstCol;