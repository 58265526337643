import { Col, Row } from "react-bootstrap";
import { SearchFieldPM } from "../../interfaces/AdvancedSearch/SearchFieldPM";
import { useEffect, useRef } from "react";
import ComboBox from "../Generic/ComboBox";

interface props {
    searchField: SearchFieldPM,
    onChange: (searchField: SearchFieldPM) => void,
    history?: string[] | undefined,
}

function SearchFieldRowText(props: props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputFocusRef = useRef(false);

    const searchField = props.searchField;

    function setEnabled() {
        searchField.Enabled = !searchField.Enabled;

        if (searchField.Enabled) {
            inputFocusRef.current = true;
        }

        props.onChange(searchField);
    };

    function setValue(newValue: string) {
        searchField.Value = newValue;
        props.onChange(searchField);
    }

    function onInputColClick() {
        if (!searchField.Enabled) {
            setEnabled();
        }
    }

    useEffect(() => {
        if (inputFocusRef.current) {
            inputRef.current!.focus();
            inputFocusRef.current = false;
        }
    });

    return (
        <Row className="rowMarginControl">
            <Col xs={4}  >
                <input className="g-Checkbox-default" type="checkbox" checked={searchField.Enabled} onChange={setEnabled} />
                <label className="g-label " onClick={setEnabled}>{searchField.Label}</label>
            </Col>
            <Col xs={8} onClick={onInputColClick}>
                <ComboBox
                    comboKey={"search" + searchField.Label.replace(" ", "")}
                    className={'g-TextBox-default' + (!searchField.Enabled ? " search-pointer-events" : "")}
                    disabled={!searchField.Enabled}
                    value={searchField.Value}
                    onChange={(value) => setValue(value)}
                    ref={inputRef}
                    options={props.history}
                />
            </Col>
        </Row>
    )
}

export default SearchFieldRowText;
