import React from "react";
import { iUser } from "../../interfaces/iUser";
import FindDialog, { FindOption } from '../Generic/FindDialog';
import { useEffect} from "react";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { GetMailboxUsers } from "../../Logic/Enquiries/Queue";
import { AllocateEnquiries, AllocateEnquiriesRequest } from "../../Logic/EmsAPI/Enquiries";
export interface Props {
    enquiries: Array<iEnquiry>,
    onCancel: () => void,
    onCreated: () => void,
    onError: () => void,
    UserList?: iUser[] | undefined,
}
export interface iGenericOptions {
    id: String,
    userName: string
}
function ChangeEnquiryOwnership(props: Props) {
    const [options, setOptions] = React.useState(props.UserList);
     
    async function allocateNewUser(value:string){
        if (value != "") {
             
             let allocateEnquiriesDetails: AllocateEnquiriesRequest = {
                UserID: +value,
                Enquiries:props.enquiries.map(enq=>enq.id)
            }
           const response = await AllocateEnquiries(allocateEnquiriesDetails);
           if (!response.isOk) {
               throw new Error(`${response.status} returned from server.`);
           }
           props.onCreated();
        } 

    }
    useEffect(() => {
        let mailboxes = props.enquiries.map(x => x.mailbox);
        mailboxes = [...new Set(mailboxes)]; // Get distinct/deduplicated list of mailboxes for all enquiries in question.

        GetMailboxUsers(mailboxes).then((users) => {
            setOptions(users);
        });
    },[props.enquiries]);

    let genericOptions:Array<iGenericOptions>;
    genericOptions = options?.map(item=>({id:item.id.toString(),userName:item.firstname  + " "+ item.lastname}))!
    let RemoveOptions:iGenericOptions={ id:"zzz",userName:"<Remove all Ownership>"};
    genericOptions?.push(RemoveOptions);
    genericOptions?.sort((a,b) => a.userName>b.userName? 1:-1)
    
    return (<>        
  {genericOptions != undefined && genericOptions.length > 0 ?
<FindDialog  
                    title="Change Enquiry Ownership" 
                    onCancel={() => props.onCancel()}
                    onConfirm={(selectedValue) => {
                        if (selectedValue !== ""){
                            allocateNewUser(selectedValue);
                        }
                    }}
                    values={genericOptions!.map<FindOption>(x => {
                        return {
                            label: x.userName,
                            value: x.id.toString(),
                        };
                    })}/>:
       
       <></>
}    
 </>
 )
}
export default ChangeEnquiryOwnership;