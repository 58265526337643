let v_pos:any = 0;
let topRowMinHeight = 0, maxHeight=0;
function vResize(e:any){
    var topP = document.getElementById("commListHistory")!;
    var bottomP= document.getElementById("bodyRow")!;
    var dx = v_pos - e.y;
    v_pos = e.y;
    if(dx >0 && dx <60){ 
        if(parseInt(getComputedStyle(topP, '').height)>=(topRowMinHeight)){
            topP.style.height = (parseInt(getComputedStyle(topP, '').height) - Math.abs(dx)) + "px";
            bottomP.style.height =(parseInt(getComputedStyle(bottomP, '').height) + Math.abs(dx))  + "px";
        }}
     if(dx<0){//means cursor is beign dragged to the bottom of the screen (communication history is getting bigger)
        if(parseInt(getComputedStyle(topP, '').height)<=(maxHeight)){
        
            topP.style.height = (parseInt(getComputedStyle(topP, '').height) + Math.abs(dx)) + "px";
            bottomP.style.height =(parseInt(getComputedStyle(bottomP, '').height) - Math.abs(dx))  + "px";
        }
    }
}

function fixHeight(e: any){
 
    document.removeEventListener("mouseup", fixHeight, false);
    document.removeEventListener("mousedown", fixHeight, false);
    document.removeEventListener("mousemove", vResize, false);
    var parent = document.getElementById("secondColContainer")!;
    if (parent) {
        parent.className="secondColContainer" ;
    }
    else if (document.getElementById("leftPane")) {
        parent = document.getElementById("leftPane") as HTMLElement;
        parent.className="leftPane";
    }
}

export const startVDrag =   (e: React.MouseEvent<HTMLDivElement, MouseEvent>, enqLength: number) => {
    var parent = document.getElementById("secondColContainer") as HTMLElement;
    if (parent) {
        parent.className="secondColContainer disableSelection" ;
    }
    else if (document.getElementById("leftPane")) {
        parent = document.getElementById("leftPane") as HTMLElement;
        parent.className="leftPane disableSelection" ;
    }
    switch(enqLength){
        case 2:
            topRowMinHeight=48;  maxHeight = 48;
        break;
        case 3:
            topRowMinHeight=72;  maxHeight = 72;
        break;
        default:
            topRowMinHeight=96;  maxHeight =  24 * enqLength;
            break
    }
    if(maxHeight >  (Math.round(0.65 *parseInt(getComputedStyle(parent, '').height)))){
        maxHeight = (Math.round(0.65 *parseInt(getComputedStyle(parent, '').height)));
    }
   
    v_pos = e.pageX;
    document.addEventListener("mousemove", vResize, false);
    document.addEventListener("mouseup",  fixHeight, false);

}