import CommPreview from '../../Email/switch'
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { getEnquiryById } from '../../../interfaces/enquiryList';
import { inWizard } from '../../../Logic/Enquiries/activateWizard';
import { getActiveListEnquiries } from '../../../utilities/localStore/calls';

function SecondCol() {
    const secondCol = useSelector((state: RootState) => state.secondCol);
	const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    let enq = getEnquiryById(activeEnquiry.id);
    if (enq == undefined) {
        const activeList = getActiveListEnquiries();
        if (activeList !== null && activeList.length > 0) {
            enq = getEnquiryById(+activeList[0]);
        }
        else {
            enq = undefined;
        }
    }
    return (
 <>

        <div className="secondColContainer" id="secondColContainer">
        
            <div style={{ paddingRight: "2px", flex: "9 1 0", display: "flex", flexDirection: "column", height:"100%" }}>
                {(activeEnquiry.id > 0 && secondCol.emailVisible && enq !== undefined && Object.keys(enq).length > 0) ?
                    <div className="email-preview-col">
                            <CommPreview
                                FullWidth={true}
                                enquiry={enq}
                                hasActivateWiz={true}
                            />
                    </div>
                : null}
            </div>
        </div>    
        </>
    )

};
export default SecondCol;