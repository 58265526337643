import ParsedTags from "./ParsedTags";
import StandardTag from "./StandardTag";
import Tag from "./Tag";

const smartTagPreviewPlaceholder = "______";
const cursorPreviewPlaceholder = "(*-- Cursor Here --*)";

export function MergeTags(text: string, tags: ParsedTags): string {

    const orderedTags = SortTags(tags);

    if (orderedTags === undefined || orderedTags.length < 1) {
        return text;
    }

    let result = "";

    result += text.slice(0, orderedTags[0].StartPosition);

    for (let i = 0; i < orderedTags.length; i++) {
        const tag = orderedTags[i];

        result += tag.Value;

        if ((i + 1) < orderedTags.length) {
            const nextTag = orderedTags[i + 1];
            const startPosition = tag.StartPosition + tag.Length;
            result += text.slice(startPosition, nextTag.StartPosition);
        } else {
            result += text.slice(tag.StartPosition + tag.Length);
        }
    }

    return result;
}

export function MergePreviewTags(text: string, tags: ParsedTags): string {
    const copiedTags: ParsedTags = JSON.parse(JSON.stringify(tags));

    for (const tag of copiedTags.SmartTags) {
        tag.Value = smartTagPreviewPlaceholder;
    }

    let previousTag: Tag | undefined;
    for (const tag of copiedTags.CursorTags) {
        // Only use the last cursor tag to position cursor if there are multiple.
        if (previousTag !== undefined) {
            previousTag.Value = ""
        }

        previousTag = tag;
        tag.Value = cursorPreviewPlaceholder;
    }

    return MergeTags(text, copiedTags);
}

function SortTags(tags: ParsedTags) : Array<Tag> {
    const orderedTags = new Array<Tag>();

    for (let propertyName in tags) {
        let propKey = propertyName as keyof typeof tags;
        
        if (tags.hasOwnProperty(propKey) && Array.isArray(tags[propKey])) {
            orderedTags.push(...tags[propKey]);
        }
    }

    return orderedTags.sort((a, b) => a.StartPosition - b.StartPosition);
}