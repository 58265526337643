import { createContext } from 'react';
import { Wizard } from '../state/workflowSlice';

export interface wizContext{
    wizard:Wizard;
    onNext: ()=>void;
    onBack: ()=>void;
    setWizard: React.Dispatch<React.SetStateAction<Wizard>>;
    onCommVerificationFailed: () => Promise<void>
}

export const initialWizardContext = {
    wizard: {} as Wizard,
    onNext: () => {},
    onBack: () => {},
    setWizard: ()=>{},
    onCommVerificationFailed: async () => {}
}
export const WizardContext = createContext<wizContext>(
    initialWizardContext
);