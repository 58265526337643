import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    showEnquiry:false,
    showWizard:false
}
export const RestrictedView = createSlice({
    name: 'RestrictedView',
    initialState,
    reducers: {
        setShowEnquiry: (state,action) => {
            state.showEnquiry = action.payload
        },
        setShowWizard: (state,action) => {
            state.showWizard = action.payload;
        },
        setEnquiryAndWorkflowInvisible: (state,action) => {
            state.showEnquiry = false;
            state.showWizard = false;
        },
    }
})


// Action creators are generated for each case reducer function
export const {  setShowEnquiry,setShowWizard,setEnquiryAndWorkflowInvisible } = RestrictedView.actions

export default RestrictedView