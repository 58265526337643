import { iActiveSlaCycle, iCommunication, iEnquiry } from "../../interfaces/iEnquiry";
import { iUser } from "../../interfaces/iUser";
import { CallEmsApi, iEmsApiResponse, iEmsApiResponseBody } from "./FetchExtension";

export async function GetMyEnquiries(
    getCompleted: boolean = true,
): Promise<iEmsApiResponseBody<{ enquiries: iEnquiry[] }>> {
    return await CallEmsApi<{ enquiries: iEnquiry[] }>(
        "GET",
        "Enquiries/My" + getCompleted ? "?getCompleted=true" : "",
    );
}

export async function GetQueueEnquiries(queue: number): Promise<iEmsApiResponseBody<{ enquiries: iEnquiry[] }>> {
    return await CallEmsApi<{ enquiries: iEnquiry[] }>("GET", `Enquiries/Queue/${queue}`);
}

export interface AdvancedSearchRequest {
    EnquiryReference?: string | undefined;
    OtherReference?: string | undefined;
    OtherReferenceClause: SearchClause;
    EnquirerName?: string | undefined;
    EnquirerNameClause: SearchClause;
    EmailAddress?: string | undefined;
    EmailAddressClause: SearchClause;
    Subject?: string | undefined;
    SubjectClause: SearchClause;
    Body?: string | undefined;
    BodyClause: SearchClause;
    Owner?: number | undefined;
    EnquiryStatus?: number | undefined;
    Mailbox?: number | undefined;
    ReceivedAfter?: string | undefined;
    ReceivedBefore?: string | undefined;
    ReturnFirst: number;
}
export enum SearchClause {
    StartsWith = 1,
    Contains = 2
}
export async function GetAdvancedSearchResults(
    request: AdvancedSearchRequest,
): Promise<iEmsApiResponseBody<{ enquiries: iEnquiry[] }>> {
    return await CallEmsApi<{ enquiries: iEnquiry[] }>("POST", "Enquiries/AdvancedSearch", request);
}

export interface AllocateEnquiriesRequest {
    UserID: number | undefined;
    Enquiries: Array<number>;
}
export async function AllocateEnquiries(request: AllocateEnquiriesRequest): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("POST", "Enquiries/Allocate", request);
}

export interface PrioritiseEnquiriesRequest {
    Priority: number | undefined;
    Enquiries: Array<number>;
}
export async function PrioritiseEnquiries(request: PrioritiseEnquiriesRequest): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("POST", "Enquiries/Prioritise", request);
}

export interface RemoveEnquiriesRequest {
    Enquiries: Array<number>;
}
export async function RemoveEnquiries(request: RemoveEnquiriesRequest): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("POST", "Enquiries/Remove", request);
}

export async function TakeOwnership(enquiryId: number): Promise<iEmsApiResponse> {
    return await CallEmsApi<any>("POST", `Enquiries/${enquiryId}/TakeOwnership`);
}

export interface iEnquiryDetails {
    id: number;
    mailbox: number;
    enquiryReference: string;
    enquirerName: string;
    enquirerEmail: string;
    priority: number;
    subject: string;
    status: number;
    workItems: number;
    updatedBy: iUser;
    updatedAt: string;
    sentAt: string;
    receivedAt: string;
    allocatedAt: string;
    completedAt: string;
    boxSlaCycle: iActiveSlaCycle;
    responseSlaCycle: iActiveSlaCycle;
    owners: iUser[];
}
export async function GetEnquiryDetails(enquiryId: number): Promise<iEmsApiResponseBody<iEnquiryDetails>> {
    return await CallEmsApi<iEnquiryDetails>("GET", `Enquiries/${enquiryId}`);
}
export async function UpdateEnquiryDetails(iEnquiryDetails: iEnquiryDetails): Promise<iEmsApiResponse> {
    return await CallEmsApi<iEnquiryDetails>("PUT", `Enquiries/${iEnquiryDetails.id}`, iEnquiryDetails);
}

export async function GetEnquiryCommunications(enquiryId: number): Promise<iEmsApiResponseBody<iCommunication[]>> {
    return await CallEmsApi<iCommunication[]>("GET", `Enquiries/${enquiryId}/Communications`);
}

export interface iCommCount {
    count: number;
    isPendingProcessing: boolean;
}
export async function GetEnquiryCommunicationCount(enquiryId: number): Promise<iEmsApiResponseBody<iCommCount>> {
    return await CallEmsApi<iCommCount>("GET", `Enquiries/${enquiryId}/Communications/Count`);
}
