import { IconBaseProps } from "react-icons";

export default function ArrowDown({ color }: IconBaseProps) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 2C8.11786 2 8.23089 2.04515 8.31423 2.12552C8.39756 2.20589 8.44438 2.31489 8.44438 2.42854V12.5362L11.2404 9.83893C11.3239 9.75846 11.4371 9.71325 11.5551 9.71325C11.6731 9.71325 11.7862 9.75846 11.8697 9.83893C11.9531 9.9194 12 10.0285 12 10.1423C12 10.2561 11.9531 10.3653 11.8697 10.4457L8.31462 13.8741C8.27334 13.914 8.2243 13.9457 8.17032 13.9673C8.11633 13.9889 8.05845 14 8 14C7.94155 14 7.88367 13.9889 7.82968 13.9673C7.7757 13.9457 7.72666 13.914 7.68538 13.8741L4.13032 10.4457C4.04688 10.3653 4 10.2561 4 10.1423C4 10.0285 4.04688 9.9194 4.13032 9.83893C4.21376 9.75846 4.32694 9.71325 4.44494 9.71325C4.56295 9.71325 4.67612 9.75846 4.75957 9.83893L7.55562 12.5362V2.42854C7.55562 2.31489 7.60244 2.20589 7.68577 2.12552C7.76911 2.04515 7.88214 2 8 2Z"
                fill={color ?? "#529AF0"}
            />
        </svg>
    );
}