
import { Fragment, useState } from 'react';
import './Workflow.scss';
import Step from './template'
import { Dispatch } from "redux";
import { useAppDispatch } from '../../state/store'
import MISDropDownRow from '../../components/MIS/DropDown/';
import MISTextWithDropDownRow from '../../components/MIS/TextWithDropdown/';
import MISTextRow from '../../components/MIS/TextBox/';
import MISMemo from '../../components/MIS/Memo/';
import MISCheckbox from '../../components/MIS/Checkbox/';
import MISNumeric from '../../components/MIS/Numeric/';
import { MisEntry } from '../../interfaces/ActionStartResponse';
import { EStepKey } from '../../Logic/Wokflow/stepLogic';
import { WizardStepProps } from './stepType';
import { WizardContext } from '../../contexts/wizard-context';
import React from 'react';
import { Wizard } from '../../state/workflowSlice';

function Step3(props: WizardStepProps) {
    let {wizard, onNext, setWizard, onBack} = React.useContext(WizardContext);
    wizard = JSON.parse(JSON.stringify(wizard));
   // const [MISEntries, setMISEntries] = useState(wizard!.workflow.data.MISData);
    const [initialLoad,setInitioalLoad] = useState(true); // we use this becuase the first time the component loads it must NOT validate;

    const nextFunc = () => {
        let passValidation = true;
        let invalidFieldAdded = 0;
        wizard!.workflow.data.MISData.forEach(x => {
            wizard.MISModel!.fields.forEach(f => {
                if (f.fieldType == "Dropdown") {
                    if (f.id == x.fieldID) {
                        if (f.lookupValues?.find(c => c.id.toString() == x.value) == undefined) {
                            passValidation = false;
                            if(invalidFieldAdded==0){
                                invalidFieldAdded = invalidFieldAdded+1;
                                document.getElementById("mis-select" + f.id)!.focus()
                            }
                        }
                    }
                }
            });
        });
        if (passValidation) {
            onNext()
        }
        else {
            setInitioalLoad(false);
        }
    }

 

    const updateMIS = (data: MisEntry) => {
        if (Object.keys(data).length > 0) {
            MISDataMap.set(data.fieldID, data);
            setWizard(currentState=>{
                currentState = JSON.parse(JSON.stringify(currentState));
                currentState.workflow.data.MISData = Array.from(MISDataMap, ([, value]) => value);
              return currentState;
            }) ;
        }
        setInitioalLoad(false);

    }

    let MisControls: any = [];

   // let MISDataMap = new Map(MISEntries.map(x => [x.fieldID, x]));
   let MISDataMap = new Map(wizard!.workflow.data.MISData.map(x => [x.fieldID, x]));
   
    let invalidFieldAdded = 0;
    wizard.MISModel!.fields.forEach((item) => {
        let mData: MisEntry = { ...MISDataMap.get(item.id)! }
        mData.fieldID = (item.id);
        mData.value = MISDataMap.get(item.id)!.value;

        switch (item.fieldType) {
            case "DisplayTitle":
                MisControls.push(<div className="row rowMarginControl" key={"mis-field-" + item.id}><div className="col-sm-12"><h6 className="font-bold">{item.label}</h6></div></div>)
                break;
                case "Dropdown":
                if(initialLoad==true){
                    MisControls.push(<MISDropDownRow MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true} />)
                }else{
                    let passValidation = true;
                
                    wizard.MISModel!.fields.forEach(f => {
                            if (f.id == mData.fieldID) {
                                if (f.lookupValues?.find(c => c.id.toString() == mData.value) == undefined) {
                                    passValidation = false;
                                    invalidFieldAdded = invalidFieldAdded+1;
                                }
                            }
                    });
                    MisControls.push(<MISDropDownRow MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={passValidation} setFocus={invalidFieldAdded==1? true:false}/>)
                }
                break;
            case "TextWithDropdown":
                MisControls.push(<MISTextWithDropDownRow MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true} />)
                break;
            case "TextInput":
                MisControls.push(<MISTextRow MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true}></MISTextRow>)
                break;
            case "Memo":
                MisControls.push(<MISMemo MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true} />)
                break;
            case "Checkbox":
                MisControls.push(<MISCheckbox MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true} />)
                break;
            case "NumericInput":
                MisControls.push(<MISNumeric MisField={item} MisEntry={mData} returnFunc={updateMIS} key={"mis-field-" + item.id} Valid={true} />)
                break;
            case "DisplayGap":
                MisControls.push(<div className="DisplayGapRowHeight" key={"Main" + item.id} />)
                break
            case "DisplayDivider":
                MisControls.push(<div className="row DisplayDividerRowHeight"  key={"Main" + item.id}><div className="col-sm-12"  ><hr  style={{margin:"0px",padding:"0px"}}/></div></div>)
                break
        }
    });
 
    return (
        <Fragment key={"MIS_" + props.Enquiry}>

            <Step
            title="MIS"
            nextF={ nextFunc }
            backF={onBack}
            step={EStepKey.MIS_STEP}
            Enquiry={props.Enquiry}
            Popped = {props.Popped}
            FullWidth = {props.FullWidth}
            >
                <div className="row align-items-center formRowMargin" >
                    {MisControls}

                </div>
            </Step>

 
        </Fragment>
    );
}

export default Step3;
