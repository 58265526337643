import { MisEntry } from "../../interfaces/ActionStartResponse";
import iSkin from "../../interfaces/Configuration/iSkin";
import iTemplate from "../../interfaces/Configuration/iTemplate";
import { iEnquiry } from "../../interfaces/iEnquiry";
import store from "../../state/store";
import { Get } from "../../utilities/apiCall";
import { GetCachedSkin, GetCachedTemplate } from "../TenantConfigCache";
import ParsedTags from "./ParsedTags";
import SmartTag from "./SmartTag";
import FillTags from "./TagFiller";
import { MergePreviewTags, MergeTags } from "./TagMerger";
import ParseTags from "./TagParser";


export interface iParsedTemplate {
    subject: string,
    subjectTags: ParsedTags,
    body: string,
    bodyTags: ParsedTags,
    include?: string | undefined,
    includeTags?: ParsedTags | undefined,
    skin: string,
    skinTags: ParsedTags,
    embed: string,
}

interface MergeResult {
    subject: string,
    body: string,
}

export interface SmartField {
    ID: string,
    Caption: string,
    Type: "text" | "numeric" | "currency" | "dropdown" | "date" | "time" | "datetime",
    Options?: Array<string> | undefined,
    Value: string,
}


export async function ParseAndFillTemplate(templateID: number, enquiry: iEnquiry, misData: MisEntry[]): Promise<iParsedTemplate> {
    const template = await ParseTemplate(templateID, GetDefaultSkinID(enquiry.mailbox));
    await FillTemplate(template, enquiry, misData);
    return template;
};

async function ParseTemplate(id: number, defaultSkinID?: number): Promise<iParsedTemplate> {
    const template = await GetCachedTemplate(id);
    const bodyTags = ParseTags(template.body);

    let skinID = defaultSkinID;
    if (bodyTags.SkinTags.length > 0) {
        skinID = bodyTags.SkinTags[0].SkinID;
    }

    let skinContent = "";
    if (skinID !== undefined) {
        const skin = await GetCachedSkin(skinID);
        skinContent = skin.data;
    } else {
        skinContent = '<html><body>[#BODY]<div>[#INCLUDE]</div><br></div>[#EMBED]</body></html>';
//        skinContent = '<html><body ><div contenteditable="false">[#BODY]</div><div>[#INCLUDE]</div><br></div>[#EMBED]</body></html>';
//        skinContent = '<html><body >[#BODY]<table><tr><td></td></tr></table><div>[#INCLUDE]</div><br></div>[#EMBED]</body></html>';

    }

    return {
        subject: template.subject,
        subjectTags: ParseTags(template.subject),
        body: template.body,
        bodyTags: bodyTags,
        include: template.include,
        includeTags: template.include ? ParseTags(template.include) : undefined,
        skin: skinContent,
        skinTags: ParseTags(skinContent),
        embed: "<div id='EmbedDiv'></div>",
    }
}

function GetDefaultSkinID(mailboxID: number): number | undefined {
    const skin = store.getState().Configuration.skins
        .find(x => x.mailboxes.find(y => y === mailboxID));

    return skin?.id;
};

async function FillTemplate(template: iParsedTemplate, enquiry: iEnquiry, misData: MisEntry[]) {
    await FillTags(template.subjectTags, enquiry, misData);
    await FillTags(template.bodyTags, enquiry, misData);
    await FillTags(template.skinTags, enquiry, misData);

    if (template.includeTags) {
        await FillTags(template.includeTags, enquiry, misData);
    }
}

export function GetSmartFields(template: iParsedTemplate): Array<SmartField> {
    const result = new Array<SmartField>();
    AddDistinctTags(result, template.subjectTags.SmartTags);
    AddDistinctTags(result, template.bodyTags.SmartTags);
    AddDistinctTags(result, template.skinTags.SmartTags);

    if (template.includeTags) {
        AddDistinctTags(result, template.includeTags.SmartTags);
    }

    return result;
}

function AddDistinctTags(tags: Array<SmartField>, newTags: Array<SmartTag>) {
    for (const tag of newTags) {
        if (!tags.find(x => x.ID === tag.ID) && tag.Subtype !== undefined) {
            tags.push({
                ID: tag.ID,
                Caption: tag.Caption,
                Type: tag.Subtype,
                Options: tag.options ? [...tag.options] : undefined,
                Value: tag.Value,
            });
        }
    }
}

export function UpdateSmartTags(template: iParsedTemplate, smartTags: Array<SmartField>) {
    UpdateSmartTagArray(template.subjectTags.SmartTags, smartTags);
    UpdateSmartTagArray(template.bodyTags.SmartTags, smartTags);
    UpdateSmartTagArray(template.skinTags.SmartTags, smartTags);

    if (template.includeTags) {
        UpdateSmartTagArray(template.includeTags.SmartTags, smartTags);
    }
}

function UpdateSmartTagArray(smartTags: Array<SmartTag>, smartFields: Array<SmartField>) {
    for (const tag of smartTags) {
        const field = smartFields.find(x => x.ID === tag.ID);
        if (field) {
            tag.Value = field.Value;
        }
    }
}

export function BuildTemplate(template: iParsedTemplate): MergeResult {
    return BuildTemplateInternal(template, (text: string, tags: ParsedTags) => MergeTags(text, TagsToHtml(tags)));
}

export function BuildTemplatePreview(template: iParsedTemplate): MergeResult {
    return BuildTemplateInternal(template, (text: string, tags: ParsedTags) => MergePreviewTags(text, TagsToHtml(tags)));
}

function BuildTemplateInternal(template: iParsedTemplate, mergeDelegate: (text: string, tags: ParsedTags) => string): MergeResult {
    const builtSubject = mergeDelegate(template.subject, template.subjectTags);

    let builtInclude = "";

    if (template.include && template.includeTags) {
        for (const tag of template.includeTags.EmbedTags) {
            tag.Value = template.embed;
        }
        builtInclude = template.include ? mergeDelegate(template.include, template.includeTags) : "";
    }

    const cursorPin = "[cursor]";
    const hasCursorTag = template.bodyTags.CursorTags.length > 0;
    if (hasCursorTag) {
        template.bodyTags.CursorTags[0].Value = cursorPin;
    }

    for (const tag of template.bodyTags.EmbedTags) {
        tag.Value = template.embed;
    }
    let builtBody = mergeDelegate(template.body, template.bodyTags);
    builtBody = BodyToHtml(builtBody);

    for (const tag of template.skinTags.EmbedTags) {
        tag.Value = template.embed;
    }
    for (const tag of template.skinTags.IncludeTags) {
        tag.Value = builtInclude;
    }
    for (const tag of template.skinTags.BodyTags) {
        tag.Value = "<div contentEditable=\"true\">" + builtBody + "</div>";
    }

    return {
        subject: builtSubject,
        body: mergeDelegate(template.skin, template.skinTags),
    }
}

function TagsToHtml(tags: ParsedTags): ParsedTags {

    var result = JSON.parse(JSON.stringify(tags));

    for (let propertyName in result) {
        let propKey = propertyName as keyof typeof result;
        
        if (result.hasOwnProperty(propKey) && Array.isArray(result[propKey])) {
            // skip tags for which the content will already be html
            if (propertyName !== "IncludeTags" && propertyName !== "EmbedTags" && propertyName !== "BodyTags") {
                for (const tag of result[propKey]) {
                    tag.Value = TextToHtml(tag.Value);
                }
            }
        }
    }

    return result;
}

function TextToHtml(text: string): string {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

function BodyToHtml(text: string): string {
    const lines = text.split(/(?:\r\n|\r|\n)/g);
    //do not remove the first div where contenteditable=false; it prevents the bug where if you select all it deletes everything including the skin from the editor
    let result = "<div contentEditable=\"false\"><div id='templateBody' contentEditable=\"true\">";
    for (const line of lines) {
        result += "<p>" + line + "</p>";
    }
    result += "</div></div>";

    return result;
}