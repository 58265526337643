import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import CommPreview from '../../Email/switch'
import Wizard from '../../Workflow';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset } from '../../../error-boundary/logerror';
import { iEnquiry } from '../../../interfaces/iEnquiry';
export interface restrictedWorkflowProps {
    Enquiry: iEnquiry,
}
function Workflow(props: restrictedWorkflowProps) {
     const secondCol = useSelector((state: RootState) => state.secondCol);
 
    return (
        <>
         
        <div style={{  height: "100%" , padding: "15px 5px 5px 2px",overflow:"auto" ,display:"flex", width:"100%", flex:"1"}}>
            <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
            {(secondCol.workflowVisible && Object.keys(props.Enquiry).length>0) ? <><Wizard Popped={false} FullWidth={false}  Enquiry={props.Enquiry} ></Wizard> <br /></> : null}
            </ErrorBoundary>
        </div>
        </>
    );
}

export default Workflow;
