import { useSelector } from "react-redux";
import { RootState } from '../../../state/store'
import './index.scss'
import { getPreference, iPreference, savePrefence } from "../../../interfaces/preference";
import { useEffect, useState } from "react";
import { iEnquiry } from "../../../interfaces/iEnquiry";
import { getActiveListEnquiries, getEnquiries, getEnquiriesMap, saveSessionValue } from "../../../utilities/localStore/calls";
import EnquiryGrid from "../enquiryGrid";
import { getLastTouched, saveLastTouched } from "../../../Logic/LastTouched/misc";
import { sortByField, sortEnquiriesByField, SortEnquiriesParams, sortOwner, sortStatus } from "../../../utilities/sort";
function MyEnquiries({ FullWidth }: { FullWidth: boolean }) {
    const [busyLoading, setLoading] = useState(true);
    const [allEnquiries, setAllenquiries] = useState<Array<iEnquiry>>([]);
    const activeEnquiry = useSelector((state: RootState) => state.activeEnquiry);
    const LastTouched = getLastTouched();
    const enquiriesCache = useSelector((state: RootState) => state.EnquiryCache.data);
    const [sortBy, setSortBy] = useState<SortEnquiriesParams>();
    useEffect(() => {
        const LastTouched = getLastTouched();
        const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name === LastTouched.MyEnquiries.ActiveList)?.orderBy ?? { orderBy: null }
        if ((activeEnquiry.id > 0 || activeEnquiry.id == -1)) {
            const allEnqs = getActiveListEnquiries();
            if (allEnqs != null) {
                const enqs = getEnquiries(allEnqs);
                setAllenquiries(
                    lastSort.orderBy ?
                        sortEnquiriesByField(enqs, lastSort.orderBy, lastSort.desc) :
                        enqs
                );
            }
            setLoading(false);
        }
        setSortBy(lastSort);
    }, [enquiriesCache]);

    let preference: iPreference = getPreference();
    if (preference == null || preference == undefined) {
        preference = {
            interface: {
                MailboxMenuVisible: true,
                MyEnquiriesQueueStatusVisible: false,
                LeftPane: 50,
                RightPane: 50
            }
        }
        savePrefence(preference);
    }
    const handleHeaderClick = (fieldName: string): any => {
        let enSort: SortEnquiriesParams
        const LastTouched = getLastTouched();

        const activeList = LastTouched.MyEnquiries.ActiveList
        const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)?.orderBy ?? {orderBy: null};
        const lastOrderBy = lastSort.orderBy

        if (lastOrderBy == fieldName) {
            LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)!.orderBy = { orderBy: fieldName, desc: !lastSort.desc }
            enSort = { orderBy: fieldName, desc: !lastSort.desc };
        }
        else {
            LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name == activeList)!.orderBy = { orderBy: fieldName }
            enSort = { orderBy: fieldName };
        }

        const order = enSort?.orderBy;
        if (order) {
            if (order == "owner") {
                setAllenquiries(es => sortOwner(es, enSort.desc))
            }
            else if (order == "status") {
                setAllenquiries(es => sortStatus(es, enSort.desc))
            }
            else {
                setAllenquiries(es => sortByField(es, order, enSort.desc))
            }
        }
        saveLastTouched(LastTouched);
        setSortBy(enSort)
        const ids = allEnquiries.map(e => e.id)
        saveSessionValue("ActiveListEnquiries", ids);
    }
    return (
        <>
            {(busyLoading == false && allEnquiries.length > 0) &&
                <EnquiryGrid data={allEnquiries} theme="dataTable_default" 
                    sortFunction={handleHeaderClick}
                    sortCol = {sortBy}
                    selectedEnquiry={activeEnquiry.id}
                    columns={[LastTouched.MyEnquiries.ActiveList=="Resolved"? "": "verticalSPacer",
                                 "priority",
                                "hasAlarm",
                                "hasUnworkedAttachments",
                                "enquiryReference",
                                "enquirerName",
                                "dateReceived",
                                "activeSlaCycle.timeout",
                                "status",
                                "subject",
                                LastTouched.MyEnquiries.ActiveList=="Resolved"? "dateCompleted": "",
                                "workItems"
                            ]}
                ></EnquiryGrid>
                }
            {

            }
 

        </>
    );
}

export default MyEnquiries;