import { MisEntry } from "../../interfaces/ActionStartResponse";
import { iEnquiry } from "../../interfaces/iEnquiry";
import store from "../../state/store";
import { Get } from "../../utilities/apiCall";
import { AddHours, FormatDate, FormatFullDate, FormatTime } from "../DateTimeFormatters";
import { GetCachedTenantTag } from "../TenantConfigCache";
import MisTag from "./MisTag";
import ParsedTags from "./ParsedTags";
import SmartTag from "./SmartTag";
import StandardTag from "./StandardTag";
import { MergeTags } from "./TagMerger";
import ParseTags from "./TagParser";

export default async function FillTags(tags: ParsedTags, enquiry?: iEnquiry, misData?: MisEntry[]): Promise<void> {
    await FillSmartTags(tags.SmartTags, enquiry, misData);
    FillMisTags(tags.MisTags, misData);
    FillStandardTag(tags.StandardTag, enquiry);
    await FillTenantTags(tags.TenantTags, enquiry, misData);
}

async function FillWithoutSmartTags(tags: ParsedTags, enquiry?: iEnquiry, misData?: MisEntry[]): Promise<void> {
    FillMisTags(tags.MisTags, misData);
    FillStandardTag(tags.StandardTag, enquiry);
    await FillTenantTags(tags.TenantTags, enquiry, misData);

    // Clear inner smart tags as they can not be handled without user input.
    for (const innerTag of tags.SmartTags) {
        innerTag.Value = "";
    }
}

function FillMisTags(tags: Array<MisTag>, misData?: MisEntry[]) {
    if (!misData) {
        return;
    }

    for (const tag of tags) {
        const misEntry = misData.find(x => x.fieldID === tag.FieldID)
        if (misEntry  !== undefined) {
            tag.Value = misEntry.value;
        }
    }
}

function FillStandardTag(tags: Array<StandardTag>, enquiry?: iEnquiry) {
    const currentDate = new Date().toISOString();

    const user = store.getState().UserInformation;
    const mailbox = user.mailboxes.find(x => x.id === enquiry?.mailbox);

    for (const tag of tags) {
        switch (tag.Subtype) {
            case "date_received":
                tag.Value = enquiry ? FormatDate(enquiry.dateReceived) : "";
                break;
            case "date_received_full":
                tag.Value = enquiry ? FormatFullDate(enquiry.dateReceived) : "";
                break;
            case "date_sent":
                tag.Value = enquiry ? FormatDate(enquiry.dateSent) : "";
                break;
            case "date_sent_full":
                tag.Value = enquiry ? FormatFullDate(enquiry.dateSent) : "";
                break;
            case "date":
                tag.Value = FormatDate(currentDate);
                break;
            case "time":
                tag.Value = FormatTime(currentDate);
                break;
            case "date_time":
                tag.Value = FormatFullDate(currentDate);
                break;
            case "user":
                tag.Value = (user?.firstname ?? "") + " " + (user?.lastname ?? "");
                break;
            case "user_firstname":
                tag.Value = user?.firstname ?? "";
                break;
            case "user_lastname":
                tag.Value = user?.lastname ?? "";
                break;
            case "mailbox_address":
                tag.Value = mailbox?.emailAddress ?? "";
                break;
            case "mailbox_name":
                tag.Value = mailbox?.name ?? "";
                break;
            case "mailbox_telephone":
                tag.Value = mailbox?.telephone ?? "";
                break;
            case "mailbox_facsimile":
                tag.Value = mailbox?.facsimile ?? "";
                break;
            case "reference":
                tag.Value = enquiry?.enquiryReference ?? "";
                break;
            case "enquirer_name":
                tag.Value = enquiry?.enquirerName ?? "";
                break;
            case "enquirer_address":
                tag.Value = enquiry?.enquirerEmail ?? "";
                break;
            case "enquiry_subject":
                tag.Value = enquiry?.subject ?? "";
                break;
            case "user_telephone":
                tag.Value = user?.telephone ?? "";
                break;
            case "user_facsimile":
                tag.Value = user?.facsimile ?? "";
                break;
            case "user_mobile":
                tag.Value = user?.mobile ?? "";
                break;
            case "user_address":
                tag.Value = user?.email ?? "";
                break;
            default:
                tag.Value = "***INVALIDTAG***";
        }
    }
}

async function FillTenantTags(tags: Array<StandardTag>, enquiry?: iEnquiry, misData?: MisEntry[]): Promise<void> {
    const tenantTags = store.getState().Configuration.tags;

    for (const tag of tags) {
        const tenantTag = tenantTags.find(x => x.key.toLocaleLowerCase() === tag.Subtype)
        if (!tenantTag) {
            tag.Value = "***INVALIDTAG***";
            return;
        }

        tag.Value = (await GetCachedTenantTag(tenantTag.key)).content!;
    
        const innerTags = ParseTags(tag.Value);
        await FillWithoutSmartTags(innerTags, enquiry, misData);

        tag.Value = MergeTags(tag.Value, innerTags);
    }
}

async function FillSmartTags(tags: Array<SmartTag>, enquiry?: iEnquiry, misData?: MisEntry[]) {
    const currentDate = new Date();

    for (const tag of tags) {
        if (tag.Value.startsWith("[#date_time]+")) {
            const offset = parseFloat(tag.Value.slice(13));

            let date = currentDate;
            if (!isNaN(offset)) {
                date = AddHours(date, offset);
            }
            
            tag.Value = FormatFullDate(date.toISOString());
        } else if (tag.Value.startsWith("[#date]+")) {
            const offset = parseFloat(tag.Value.slice(8));
            let date = currentDate;

            if (!isNaN(offset)) {
                date = AddHours(date, offset);
            }
            
            tag.Value = FormatDate(date.toISOString());
        } else {
            const innerTags = ParseTags(tag.Value);
            await FillWithoutSmartTags(innerTags, enquiry, misData);

            tag.Value = MergeTags(tag.Value, innerTags);
        }
    }
}
