import * as React from 'react';
import { useContext, useEffect } from 'react';
import { iEnquiry } from '../../../interfaces/iEnquiry';
import { useState } from 'react';
import "./enquiry.scss";
import { useSelector } from "react-redux";
import { RootState } from '../../../state/store'
import UserIcon from '../../../assets/img/user.svg'
import { Bell } from 'react-feather';
import {  deactivateEnquiryWizard,  getEnquiryById } from '../../../interfaces/enquiryList';
import { ToGenericComm } from '../../../interfaces/iGenericComm';
import Statuses from '../../../interfaces/Statuses';
import { BsExclamationLg, BsFileEarmark, BsMagic, BsPaperclip } from 'react-icons/bs';
import {startVDrag} from '../../../Logic/Resize/vertical'
import Communication from '../communication/communication';
import { ActivateWizardModule } from '../../../Logic/Enquiries/activateWizard';
import { GlobalModalContext } from '../../../contexts/GlobalModalContext';
import { EnquiryContextMenu } from '../../Menu';
import { useContextMenu } from '../../../hooks/useContextMenu';
import { CopyTag } from '../../CopyTag';
 
export const SwitchContext = React.createContext({changeCommToView: (commId:number) => {}});
type EnquiryProp = {
	e: iEnquiry,
	interface?: string,
    commOnclick: Function,
    activeCommId:number,
	hasActivateWiz: boolean
}
function Enquiry(props: EnquiryProp) {
	const e = props.e;

	const showModal = useContext(GlobalModalContext);
	const keydownHandler = (commId: number, event: React.KeyboardEvent<HTMLTableRowElement>) => {
		if (event == null)
			return;
		if (event.key != "ArrowUp" && event.key != "ArrowDown")
			return
		event.preventDefault();
		const activeCommIndex = props.e.communications.findIndex(x => x.id === commId);
		let nextCommId = 0;
		let nextCommIndex;
		
		if (event.key== "ArrowUp") {
			nextCommIndex = activeCommIndex > 0 ? activeCommIndex - 1 : activeCommIndex;
		}
		else {
			nextCommIndex = activeCommIndex < props.e.communications.length - 1 ? activeCommIndex + 1 : activeCommIndex;
		}
		nextCommId = props.e.communications[nextCommIndex].id;
		let commContainer: HTMLElement = document.getElementById('commListHistory')!;
		commContainer.scrollTop = nextCommIndex * 15 ;
		if (commId != nextCommId) {
			props.commOnclick(nextCommId);
		}  
	}
     let HistoryList1;
     if (props.e.communications.length > 0) {
    
        HistoryList1 = props.e.communications.map(
            (item, index) =>
				<Communication id={item.id} type={item.type} dateStamp={item.dateStamp} email={item.email}
					comm={ToGenericComm(item)}   isCompletedEnquiry={Statuses.IsCompleted(e.status)} key={"com_" + item.id}
					interface={props.interface} onClick={props.commOnclick} activeCommId={props.activeCommId} onKeyDown={keydownHandler}
             />
        );
       
        }
	let [currentDate, setCurrentDate] = useState(new Date());

	const getExpiryColour = () => {
		if (e.activeSlaCycle != null && !Statuses.IsCompleted(e.status)) {
			let reminderDate = e.activeSlaCycle.reminder ? new Date(e.activeSlaCycle.reminder) : undefined;
			let alarmDate = e.activeSlaCycle.alarm ? new Date(e.activeSlaCycle.alarm) : undefined;
			let timeoutDate = e.activeSlaCycle.timeout ? new Date(e.activeSlaCycle.timeout) : undefined;

            if (timeoutDate && timeoutDate < currentDate) {
				return " expiry-purple"
            } else if (alarmDate && alarmDate < currentDate) {
				return " expiry-red"
            } else if (reminderDate && reminderDate < currentDate) {
                return " expiry-orange"
            } else {
                return " expiry-green"
            }
		}
		return "";
	}

	const {contextMenuState, setContextMenuState} = useContextMenu()

	const showContextMenu = (enquiryId: number) => (event: React.MouseEvent) => {
		// Disable the default context menu
		event.preventDefault();

		if (props.hasActivateWiz) {
			const container = document.getElementById("enquiryHolder") as HTMLElement || null;
		if (container) {
			const containerRect = container.getBoundingClientRect()
			setContextMenuState({data:"0",visible: true, x: event.pageX-containerRect.x, y: event.pageY-containerRect.y});
		}
		else setContextMenuState({data:"0",visible: true, x: event.pageX, y: event.pageY});
		}
	};

	let [expiryColour, setExpiryColour] = useState(getExpiryColour());
	const dateReceived = dateTimeFormatN22(e.dateReceived)
	const activeSlaCycleTimeOut = e?.activeSlaCycle?.timeout ? dateTimeFormatN22(e?.activeSlaCycle?.timeout) : "";
	useEffect(() => {

		var timer = setInterval(() => {
			setCurrentDate(new Date())
			const currentColour = getExpiryColour()
			if (currentColour != expiryColour) {
				setExpiryColour(currentColour)
			}
		}, 60000)
		return function cleanup() {
			clearInterval(timer)
		}

		
	});

  
	const barPercentage = (priority: number) => {
		return ((255 - priority) / 255.0) * 100.0;
	}

const startVDragging =   (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    startVDrag(e, props.e.communications.length)
}

	return (
   
		<div key={e?.id} id="enquiryHolder">
      
			<div
				className={"enquiry" + expiryColour}
				id={"e_" + e.id}
				key={"ee_" + e.id}
				data-testid="enquiry1"
				onDoubleClick={ () => {
					const wizardModule = new ActivateWizardModule()
					if (props.hasActivateWiz && wizardModule.wizardPermitted(e)) {
						wizardModule.ActivateWizard(e.id, () => showModal({ type: "EnquiryLocked" }))
					}
				}}
			>
				<div
					className={
						"enquiry--card" +
						(
							(!Statuses.IsCompleted(e.status)) && e.hasNew ? " has-new" : ""
						) + (
							 " active" 
						) + expiryColour
					}
					id={e?.id?.toString()}
					onContextMenu={showContextMenu(e.id)}
					data-testid="enquiry-card1"
				>
					{(props.hasActivateWiz && contextMenuState.visible) && (
						<div style={{ position: "absolute" }}>
							<EnquiryContextMenu
								x={contextMenuState.x}
								y={contextMenuState.y}
								enquiryId={e.id}
								onHide={() => setContextMenuState({data:"0", visible: false, x: 0, y: 0 })}
							/>
						</div>
					)}
					<div className="vertical-border"></div>
					<div className="enquiry--card--enquiry-ref">
						<CopyTag
							value={e.enquiryReference}
							tooltip={`Enquiry reference: ${e.enquiryReference}`}
						/>
					</div>
					<div className="icon-label enquiry-owner" title={`Enquiry owner: ${e.owners.map((item) => { return `"${item.lastname}, ${item.firstname}"` }).join(', ')}`}>
						<img src={UserIcon} alt="" className='icon' />
						<div className="label limit-text" data-testid="enquiry-owner1">
							{e.owners.map((item) => { return `${item.lastname}, ${item.firstname}` }).join('; ')}
						</div>
					</div>
					<div className="enquiry--card--enquirer-name limit-text" title={`Enquirer: ${e.enquirerName}`} data-testid="enquirer-name1">{e.enquirerName}</div>
					<div className="enquiry--card--enquiry-subject limit-text" title={`Enquiry subject: ${e.subject}`} data-testid="enq-subject1">{e.subject}</div>

					<div className="enquiry--card--right">
						<div className="enquiry--card--right-row enquiry--card--enquiry-status justify-content-between">
							<div className="icon-label wiz-row">
								<div className="icon"></div>
								<div className="limit-text enq-stat-label" title={`Enquiry status: ${Statuses.Name(e.status)}`} data-testid="enquiry-status1">{Statuses.Name(e.status)}</div>
								{(new ActivateWizardModule().wizardPermitted(e)) && <button className="g-btn-action enq-act-wiz py-1" style={{ paddingLeft: "7px" }} title='Activate Wizard' onClick={() => new ActivateWizardModule().ActivateWizard(e.id, () => showModal({ type: "EnquiryLocked" }))}>
									<i className='activate-wizard'><BsMagic /></i>
								</button>}
							</div>
						</div>

						<div className="enquiry--card--right-row">
							<>
								{Statuses.IsCompleted(e.status) ? <div className="icon-label">
									{(!e.hasAlarm) ? <div className="icon"></div> : <Bell className="icon" data-testid="enq-alarm-icon1" />}
									<div className="enquiry--card--date-completed limit-text" data-testid="enq-completed-label1">
										Completed:<div className="completed-date limit-text" data-testid="enq-completed-date1">
											{
												e.dateCompleted ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(e.dateCompleted)).replace(",", "")
													: ""
											}
										</div>
									</div>
								</div>
									: <div className="icon-label expiry--icon-label">
										{(!e.hasAlarm) ? <div className="icon"></div> : <Bell className="icon" data-testid="enq-alarm-icon1" />}
										<div className="label expiry--label">Expiry:</div>
										<div className="date expiry--date limit-text" data-testid="enquiry-expiry1">
                                            {activeSlaCycleTimeOut}
										</div>
									</div>}

							</>
						</div>

						<div className="enquiry--card--right-row received-date">
							<div className="icon-label">
								<div className="sla-priority-bar">
									<div style={{ height: barPercentage(e.priority) + "%" }} className="sla-priority--value"></div>
								</div>
								<div className="label">Received:</div>
							</div>
							<div className="date received--date limit-text" data-testid="enquiry-date-received1">
								{dateReceived}
							</div>
						</div>
					</div>
				</div>

                {props.e.communications.length>0 &&   
        <table className="comm-list"   cellPadding={0} cellSpacing={0}>
         <thead  className={props.e.communications.length > 4 ? "tHeaddWithScroll comm-list--header" : "tHeadd comm-list--header"}>
            <tr style={{ height: "100%", width:"100%" }} className="noScrollBar">
                    <th style={{ width: "15px", textAlign: "center",  paddingLeft: "4px" }}><i style={{width:"100%"}}><BsExclamationLg style={{height:"20px"}}/></i></th>
                    <th  style={{ width: "11px", textAlign: "center", padding:"0px",margin:"0px" }}><i style={{height:"20px" }}><BsFileEarmark style={{height:"20px"}}/></i></th>
                    <th  style={{ width: "15px", textAlign: "center", height: "20px", paddingRight: "4px" }}><i style={{width:"100%"}}><BsPaperclip style={{height:"20px"}}/></i></th>

                    <td  className="limit-text" style={{ width: "12%" }}>Date</td>
                    <td  className="limit-text" style={{ width: "20%" }}>From</td>
                    <td  className="limit-text" style={{ width: "20%" }}>To</td>
                    <td  className="limit-text" style={{ width: "35%" }}>Subject</td>
                    <td  className="limit-text" style={{ width: "7%", paddingRight: "10px", marginRight:"5px" }}>Size</td>
                </tr>
            </thead>
            <tbody className='comm-list--list' data-testid='enquiry-communication-list1' id="commListHistory">
            {props.e.communications.length==0 && <tr><td style={{padding:"13px"}}>No enquiry history to display</td></tr> }
            {
                  HistoryList1
            }
            </tbody>
            <tfoot>
                <tr style={{width:"100%"}}>
                    <td colSpan={8} className="details comm-expand-btn">Items: {props.e.communications.length}</td>
                </tr>
                <tr style={{height:"3px"}}>
                    <td colSpan={8} className={props.e.communications.length > 4 ? "commResizeWait" : "commResize"} style={{height:"3px"}} onMouseDown={(event) => startVDragging(event)}></td>
                </tr>
                </tfoot>
        </table>
       }

			</div>
		</div>
    
	)
}

export const handleDeactivateWizard1 = (enquiryId: number, userId: number) => {
	let promise = new Promise<any>((resolve, reject) => {
		deactivateEnquiryWizard(enquiryId).then((res) => {
			resolve(res)
		}).catch((err) => {
			console.log(err)
			reject(err);
		})
	})
	return promise;
}

export const dateTimeFormat222 = (dateTime: string) => {
    try {
		return new Intl.DateTimeFormat('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(dateTime)).replace(",", "")
	}
	catch (err) {
		return dateTime;
	}
}
export const dateTimeFormatN22 = (dateTime: string) => {
	try {
		return new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).format(new Date(dateTime)).replace(",", "")
	} catch (err) {
		return dateTime;
	}
}

export const dateTimeFormatLong = (dateTime: string) => {
	try {
		return new Intl.DateTimeFormat('en-GB', {
            dateStyle: 'full',
            timeStyle: 'short',
          }).format(new Date(dateTime)).replace(",", "")
	} catch (err) {
		return dateTime;
	}
}

export default Enquiry