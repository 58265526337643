import { Col, Row } from "react-bootstrap";
import { SearchFieldPM } from "../../interfaces/AdvancedSearch/SearchFieldPM";
import { useEffect, useRef } from "react";

interface props {
    searchField: SearchFieldPM,
    onChange: (searchField: SearchFieldPM) => void,
    min: number,
    max: number,
}

function SearchFieldRowNumber(props: props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const inputFocusRef = useRef(false);

    const searchField = props.searchField;

    function setEnabled() {
        searchField.Enabled = !searchField.Enabled;

        if (searchField.Enabled) {
            inputFocusRef.current = true;
        }
        if (!searchField.Enabled) {
            searchField.Value = props.max;
        }
        props.onChange(searchField);
    };

    function setValue(newValue: string) {
       
            searchField.Value = bound(newValue);
            props.onChange(searchField);
        
    }

    function bound(value: string): number {
        let result = parseInt(value);

        if (result < props.min)
            return props.min;
        if (result > props.max)
            return props.max;

        return result;
    }

    function onInputColClick() {
        if (!searchField.Enabled) {
            setEnabled();
        }
    }

    useEffect(() => {
        if (inputFocusRef.current) {
            inputRef.current!.focus();
            inputFocusRef.current = false;
        }
    });

    return (
        <Row className="rowMarginControl">
            <Col xs={4} >
                <input className="g-Checkbox-default" type="checkbox" checked={searchField.Enabled} onChange={setEnabled} />
                <label className="g-label" onClick={setEnabled}>{searchField.Label}</label>
            </Col>
            <Col xs={8} onClick={onInputColClick}>
                <input
                    type="number"
                    className={'g-TextBox-default search-return-first' + (!searchField.Enabled ? " search-pointer-events" : "")}
                    disabled={!searchField.Enabled}
                    value={searchField.Value}
                    step={1} min={props.min} max={props.max}
                    onChange={(e) => setValue(e.target.value)}
                    ref={inputRef}
                />
            </Col>
        </Row>
    )
}

export default SearchFieldRowNumber;
