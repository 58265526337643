import SecondRow from './secondRow';
import FirstColumn from './firstCol';
import SecondColumn from './secondCol';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { getPreference, iPreference, savePrefence } from '../../../interfaces/preference';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { startDrag } from '../../../Logic/Resize/horizontal';
import { burgerMenuMatchLeftPanePos } from '../../../Logic/BurgerMenu';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '../../../error-boundary/errorFallback';
import { defaultReset, logErrorToService } from '../../../error-boundary/logerror';
function DefaultView() {

    const SiteInfo = useSelector((state: RootState) => state.SiteInformation);

    useEffect(() => {
        let preference:iPreference =  getPreference();
        if(preference.interface.LeftPane==undefined || preference.interface.RightPane==undefined){
            preference.interface.LeftPane = 50;
            preference.interface.RightPane = 50;
            savePrefence(preference);
        }
        document.getElementById("leftPane")!.style.width = preference.interface.LeftPane + "%";
        document.getElementById("rightPane")!.style.width = preference.interface.RightPane + "%";
        burgerMenuMatchLeftPanePos()
      },[]);

    return (
        <>
              <SecondRow FullWidth={true}></SecondRow>
<div className='MainFlexRow ' id="mainPane">
    <div id="leftPane" className='firstColFullWidth' style={{marginTop: SiteInfo.restrictedView ? "0px":"0px"}}>
                     <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
                <FirstColumn></FirstColumn>
        </ErrorBoundary>
        </div>
        <div  id="rightPane" className='secondCol'>
             <div id="resize" onMouseDown={(event)=>startDrag(event)}></div>
             <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onReset={defaultReset} 
                    >
            <SecondColumn></SecondColumn> 
            </ErrorBoundary>
        </div>
</div>
              {/*
              <Row style={{ overflow: "hidden", marginTop:"2px" }}>
                <Col   xs="6" className="firstColFullWidth">
                    <FirstColumn></FirstColumn>
                </Col>
                <Col   id="topRightProfile"  xs="6" className="secondCol">
                    <SecondColumn></SecondColumn>
                </Col>
             </Row>
              */}
        </>
    )

};
export default DefaultView;