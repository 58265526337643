import './Workflow.scss';
import { EEnquiryAction } from '../../interfaces/wizard';
import { iEnquiry } from '../../interfaces/iEnquiry';
type ActionOp = {
    action: EEnquiryAction,
    handleChange: (chosenAction: EEnquiryAction, enabled: boolean) => void,
    wizardActionOption?: EEnquiryAction,
    Enquiry: iEnquiry,
    enabled?: boolean,
    head: string,
    subhead: string,
    children?: React.ReactNode,
    color: "default" | "orange" | "red",
    onDoubleClick: (chosenAction: EEnquiryAction) => void,
    includeFeedback?: boolean,
    onIncludeFeedbackChange?: (value: boolean) => void,
    disableCheckbox?: boolean
}
function EnquiryAction(props: ActionOp) {

    return (
        <>
            <input
                type="radio"
                name="EnquiryAction"
                value={props.action}
                id={`action-option-${props.action}`}
                onChange={(e) => props.handleChange(
                    +e.target.value,
                    props.enabled ?? false
                )}
                checked={props.wizardActionOption === props.action}
            />
            <label
                htmlFor={`action-option-${props.action}`}
                className={`enquiry-action${!(props.enabled) ? ' default' : ''}`}
            >
                <div
                    className={`enquiry-action--icon ${props.color}`}
                    onDoubleClick={() => {
                        if (props.enabled) {
                            props.onDoubleClick(props.action)
                        }
                    }}
                >
                    {props.children}
                </div>
                <div
                    className="enquiry-action--details"
                    onDoubleClick={() => {
                        if (props.enabled) {
                            props.onDoubleClick(props.action)
                        }
                    }}
                >
                    <div className="enquiry-action--details--head limit-text">{props.head}</div>
                    <div className="enquiry-action--details--subhead limit-text">{props.subhead}</div>
                </div>
                {props.onIncludeFeedbackChange && <div className="enquiry-action--checkbox-feedback-control">
                    <input
                        type="checkbox"
                        name="action-request-internal-feedback"
                        id={`action-request-internal-feedback-${props.action}`}
                        disabled={!props.enabled || (props.disableCheckbox ?? false)}
                        checked={props.includeFeedback}
                        onChange={e => props.onIncludeFeedbackChange?.(e.target.checked)}
                    />
                    <label htmlFor={`action-request-internal-feedback-${props.action}`} style={{userSelect: 'none'}}>Include Enquirer Feedback Email</label>
                </div>}
            </label>
        </>
    )
}

export default EnquiryAction;
