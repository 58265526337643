import { iCommunication } from "./iEnquiry";
import { isNullOrWhitespace } from "../utilities/string";
import { getComm, getMailUrls } from "../Logic/GenericComms/CommParts";
import { iUser } from "./iUser";

export interface iGenericComm {
    id: number;
    type: string
    incoming?: boolean;
    from: string;
    to: string;
    cc?: string;
    bcc?: string;
    priority?: number;
    subject: string;
    size?: number | undefined;
    hasAttachments: boolean;
    unread?: boolean | undefined;
    unsent?: boolean | undefined;
    date: string;
    getBody:  ()=>Promise<string>
    getAttachments:  ()=>Promise<Array<{ id: string, name: string, mimeType: string, size: number, }>>
}

function UserToLabel(user: iUser | undefined): string {
    if (!user)
        return "";
    
    if (!user.firstname)
        return user.lastname ?? "";

    if (!user.lastname)
        return user.firstname;

    return user.firstname + " " + user.lastname;
}

export class mailComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.incoming = comm.email?.incoming;
        this.from = (isNullOrWhitespace(comm.email!.from.name) ? comm.email!.from.address : comm.email!.from.name!)
        this.to = comm.email!.to.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;
        this.cc = comm.email!.cc.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;
        this.bcc = comm.email!.bcc.map((item)=>{
            return (isNullOrWhitespace(item.name) ? item.address: item.name)
        }
        ).join(", ") ;;
        this.priority = comm.email?.priority;
        this.subject = comm.email?.subject!
        this.size = comm.contentLength;
        this.hasAttachments = JSON.parse(comm.email?.hasAttachments ?? "false");
        this.unread = JSON.parse(comm.email?.unread ?? "");
        this.unsent = JSON.parse(comm.email?.unsent ?? "");
        this.getBody = async ()=> {
            const result  =  await  getMailUrls(comm);  
            return  result.body;
        }
        this.getAttachments = async ()=> {
            const result  =  await  getMailUrls(comm); 
            return  result.attachments;
        }
    }

    id: number;
    type: string;
    incoming?: boolean | undefined;
    from: string;
    to: string;
    cc?: string | undefined;
    bcc?: string | undefined;
    priority?: number | undefined;
    subject: string;
    size?: number | undefined;
    hasAttachments: boolean;
    unread: boolean;
    unsent: boolean;
    date: string;
    getBody:  ()=>Promise<string>;
    getAttachments:  ()=>Promise<Array<{ id: string, name: string, mimeType: string, size: number, }>>
}

export class noteComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.from = UserToLabel(comm.note?.from);
        this.to = UserToLabel(comm.note?.to);
        this.priority = comm.note?.priority;
        this.subject = comm.note?.subject ?? "";
        this.size = comm.contentLength;
        this.hasAttachments = comm.note?.hasAttachments ?? false;
        this.getBody = async ()=> {
            const result  =  await  getComm(comm); 
            return  result.body?.replace(/(?:\r\n|\r|\n)/g, '<br />') ?? "";
        }
        this.getAttachments = async ()=> {
            const result  =  await  getComm(comm); 
            return  result.attachments;
        }
    }

    id: number;
    type: string;
    from: string;
    to: string;
    priority?: number | undefined;
    subject: string;
    size?: number | undefined;
    hasAttachments: boolean;
    date: string;
    getBody:  ()=>Promise<string>;
    getAttachments:  ()=>Promise<Array<{ id: string, name: string, mimeType: string, size: number, }>>
}

export class mediaComm implements iGenericComm {
    constructor(comm: iCommunication) {
        this.id = comm.id;
        this.type = comm.type;
        this.date = comm.dateStamp;
        this.from = UserToLabel(comm.media?.from);
        this.to = comm.media?.to ?? "";
        this.subject = comm.media?.subject ?? ""
        this.size = comm.contentLength;
        this.hasAttachments = comm.media?.hasAttachments ?? false;
        this.getBody = async ()=> {
            const result  =  await  getComm(comm); 
            return  result.body?.replace(/(?:\r\n|\r|\n)/g, '<br />') ?? "";
        }
        this.getAttachments = async ()=> {
            const result  =  await  getComm(comm); 
            return  result.attachments;
        }
    }

    id: number;
    type: string;
    from: string;
    to: string;
    subject: string;
    hasAttachments: boolean;
    size?: number | undefined;
    date: string;
    getBody:  ()=>Promise<string>;
    getAttachments:  ()=>Promise<Array<{ id: string, name: string, mimeType: string, size: number, }>>
}
 
export function ToGenericComm(comm: iCommunication) {
    if (comm.type == "Email")
        return new mailComm(comm);

    if (comm.type == "Note")
        return new noteComm(comm);

    return new mediaComm(comm);
}