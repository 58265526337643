export default class TextReader {

    private _text: string;

    constructor(text: string) {
        this._text = text;

        this.Length = text.length;
    }

    public Position: number = -1;
    public readonly Length: number;

    public Read(): boolean {
        const newPosition = this.Position + 1;
        
        if (newPosition < 0 || this.Length <= newPosition) {
            return false;
        }

        this.Position = newPosition;
        return true;
    }

    public get Char(): string {
        return this._text[this.Position];
    }
}