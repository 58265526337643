import { Copy } from "../../assets/svg/copy.icon"
import './index.scss'

interface Props {
    value: string,
    tooltip?: string
}
export function CopyTag({ value, tooltip }: Props) {

    return (
        <div className="copy-tag">
            <div className="limit-text" title={tooltip} data-testid="enquiry-reference1">{value}</div>
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value);
                }}
                className='copy-icon'
            >
                <i><Copy style={{ height: "10px" }} /></i>
            </button>
        </div>
    )
}