import { useContext } from "react";
import { GlobalModalContext } from "../../contexts/GlobalModalContext";
import ConfirmDialog from "../Generic/ConfirmDialog";

export interface iGlobalModalsProps {
    type?: "EnquiryLocked" | undefined,
    payload?: any | undefined
}

export default function GlobalModals(props: iGlobalModalsProps) {
    const setModalContext = useContext(GlobalModalContext);

    return (
        <>
        {
            props.type === "EnquiryLocked" &&
            <ConfirmDialog
                title="Information"
                messageType="info"
                onConfirm={() => { setModalContext({ }) }}
            >
                <>
                    This enquiry has been locked for processing.<br />
                    If this is an issue, please escalate it to your manager.
                </>
            </ConfirmDialog>
        }
        </>
    );
}