import { iCommunication, iEnquiries, iEnquiry } from "../../interfaces/iEnquiry";
import { callApi, iRequest } from "../../utilities/apiCall";
import { setItemEnabled } from "../../utilities/loading";
import {  saveSessionValue } from "../../utilities/localStore/calls";
import { getEnquiriesMap, saveActiveListEnquiries, saveEnquiriesMap } from "../../utilities/localStore/calls";
import { sortComms, sortEnquiriesByField, sortResolved, sortUnresolved } from "../../utilities/sort";
import { setActiveEnquiry } from '../../state/activeEnquiry';
import store from "../../state/store";
import { updateEnquiriesCache } from "../../state/enquiriesCache";
import { getLastTouched } from "../LastTouched/misc";

export async function getUnresolvedEnquiriesGlobal(userId?: number, id: string = "", tenant?:string): Promise<any> {
    const LastTouched = getLastTouched();
    setItemEnabled(id, false)
    var promise = new Promise<any>((resolve, reject) => {

        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/My",
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request,tenant).then((resp) => {
            let ActiveList: number[] = [];
            const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name === "Unresolved")?.orderBy ?? { orderBy: null }
            const sortedEnqs = lastSort.orderBy ?
                sortEnquiriesByField(resp.enquiries, lastSort.orderBy, lastSort.desc) :
                sortUnresolved(resp.enquiries);
            sortedEnqs.map(x=> sortComms(x.communications)); //this sorts the communications history to order by latest on top
            ActiveList = sortedEnqs.flatMap(x=> x.id);
            store.dispatch(updateEnquiriesCache(resp.enquiries));
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true)
            resolve(resp);
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });
    })
    return promise;

}

export async function getResolvedEnquiriesGlobal(userId?: number, id: string = ""): Promise<iEnquiries> {
    const LastTouched = getLastTouched();
    var promise = new Promise<any>((resolve, reject) => {
        if (document.getElementById("loader")) {
            (document.getElementById("loader") as HTMLElement)!.style.display = "block";
        }
        let request: iRequest = {
            url: "Enquiries/My?getCompleted=true",
            method: "GET",
            headers: [],
            body: "",
            responseType: "json"
        }
        callApi(request).then((resp) => {
            let ActiveList: number[] = [];
            const lastSort = LastTouched.MyEnquiries.EnquiryLists.find(l => l.Name === "Resolved")?.orderBy ?? { orderBy: null }
            const sortedEnqs = lastSort.orderBy ?
                sortEnquiriesByField(resp.enquiries, lastSort.orderBy, lastSort.desc) :
                sortResolved(resp.enquiries);
            sortedEnqs.map(x=> sortComms(x.communications)); //this sorts the communications history to order by latest on top
            ActiveList = sortedEnqs.flatMap(x=> x.id);
            store.dispatch(updateEnquiriesCache(resp.enquiries));
            saveSessionValue("ActiveListEnquiries",ActiveList);
            setItemEnabled(id, true)
            resolve(resp);
 
        }).catch((err) => {
            reject(err);
            setItemEnabled(id, true)
        });

    })
    return promise;
}