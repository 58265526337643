 
import { createSlice} from '@reduxjs/toolkit'
const initialState  = {
    id: undefined,
};

export const activeComm = createSlice({
    name: 'setActiveComm',
    initialState,
    reducers: {
        setActiveComm: (state, action) => {
            state.id = action.payload
          },
    },
   
});

// Action creators are generated for each case reducer function
export const { setActiveComm} = activeComm.actions

export default activeComm;