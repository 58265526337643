import { Col, Row } from "react-bootstrap";
import { SearchFieldPM } from "../../interfaces/AdvancedSearch/SearchFieldPM";
import { useEffect, useRef, useState } from "react";
import { iUser } from "../../interfaces/iUser";
import { SearchForUsers } from "../../Logic/UserSearch";
import debounce from "../../utilities/debounce";
import { BsX } from "react-icons/bs";

interface props {
    searchField: SearchFieldPM,
    onChange: (searchField: SearchFieldPM) => void,
    history?: iUser[] | undefined,
}

const debouncedSearch = debounce((searchText: string, setOptions: any) =>
    SearchForUsers(searchText).then((users) => {
        setOptions(users);
    }), 400);

function SearchFieldRowUser(props: props) {
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputFocusRef = useRef(false);

    const searchField = props.searchField;

    const [searchText, setSearchText] = useState("");
    const [userSelected, setUserSelected] = useState(props.searchField.Value?.id !== undefined);
    const [options, setOptions] = useState(props.history);

    useEffect(() => {
        if (props.searchField.Value?.id !== undefined && typeof props.searchField.Value.id === "number") {
            setUserSelected(true);
            setSearchText(
                props.searchField.Value.firstname +
                " " +
                props.searchField.Value.lastname
            );
        }
    }, [props.searchField.Value]);

    useEffect(() => {
        if (userSelected) {
            return;
        }

        let selectedUser = options!.find((value) =>
            value.firstname + " " + value.lastname === searchText
        );

        if (selectedUser != undefined) {
            setUserSelected(true);
            props.onChange({...searchField, Value: selectedUser});
            return;
        }

        if (searchText.length === 0 && props.history != undefined) {
            setOptions(props.history);
        } else if (searchText.length >= 3) {
            debouncedSearch(searchText, setOptions);
        }
    }, [searchText])

    useEffect(() => {
        setOptions(props.history);
    }, [props.history])

    useEffect(() => {
        if (!userSelected) {
            setSearchText("");
            props.onChange({...searchField, Value: undefined});
            inputRef.current!.focus();
        }
    }, [userSelected]);

    function setEnabled() {
        searchField.Enabled = !searchField.Enabled;

        if (searchField.Enabled) {
            inputFocusRef.current = true;
        }

        props.onChange(searchField);
    };

    function onInputColClick() {
        if (!searchField.Enabled) {
            setEnabled();
        }
    }

    useEffect(() => {
        if (inputFocusRef.current) {
            if (userSelected) {
                buttonRef.current?.focus();
            }
            else {
                inputRef.current?.focus();
            }
            inputFocusRef.current = false;
        }
    });

    return (
        <Row className="rowMarginControl">
            <Col xs={4}  >
                <input className="g-Checkbox-default" type="checkbox" checked={searchField.Enabled} onChange={setEnabled} />
                <label className="g-label " onClick={setEnabled}>{searchField.Label}</label>
            </Col>
            <Col xs={8} onClick={onInputColClick}>
                { userSelected ?
                    <div className='g-TextBox-default'>
                        <div className="search-selected-user">{searchText}</div>
                        <button className="search-remove-user" ref={buttonRef} onClick={() => setUserSelected(false)}><BsX/></button>
                    </div> :
                    <>
                        <input
                            type="textbox"
                            className={'g-TextBox-default' + (!searchField.Enabled ? " search-pointer-events" : "")}
                            list="searchOwner"
                            disabled={!searchField.Enabled}
                            value={searchText}
                            onChange={(e) => setSearchText(e.currentTarget.value)}
                            autoComplete="off"
                            spellCheck={false}
                            ref={inputRef}
                        />
                        {options != undefined && options.length > 0 ?
                            <datalist id="searchOwner">
                                {options.map((user) =>
                                    <option key={user.id} value={user.firstname + " " + user.lastname} />
                                )}
                            </datalist> :
                            <></>
                        }
                    </>
                }
            </Col>
        </Row>
    )
}

export default SearchFieldRowUser;
