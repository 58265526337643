
import { iLastTouched } from "../../interfaces/lastTouched";
import store from "../../state/store";
import { showHideLoader } from "../../utilities/loading";
import { iEnquiry } from "../../interfaces/iEnquiry";
import { keepTheConnectionAlive } from "../../utilities/userProcessing";
import { ActionStartResponse, MisEntry, MisModel } from "../../interfaces/ActionStartResponse";
import { setActiveEnquiry } from "../../state/activeEnquiry";
import { setShowEnquiry, setShowWizard } from "../../state/restrictedViewSlice";
import { addTab } from "../../state/tabRow";
import { setWorkflowVisible } from "../../state/secondColSlice";
import { GetWorkflowFromStorage, SaveWorkflowToStorage, Wizard, setState } from "../../state/workflowSlice";
import { getEnquiryById, refreshList } from "../../interfaces/enquiryList";
import Statuses from "../../interfaces/Statuses";
import { getLocalValue, getSessionValue, removeSessionValue, saveLocalValue, saveSessionValue } from "../../utilities/localStore/calls";
import { iWizards } from "../../interfaces/wizard";
import { GetMailboxPermissions } from "../../interfaces/Permissions";
import { EStepKey, initialiseSteps } from "../Wokflow/stepLogic";
import iAlarm from "../../interfaces/iAlarm";
import { getLastTouched, saveLastTouched } from "../LastTouched/misc";
import { updateEnquiriesCache } from "../../state/enquiriesCache";

export class ActivateWizardModule {
    state: any;
    SiteInformation: any;
    UserInfo: any;
    click: number;
    permissions: any;

    constructor() {
        this.state = store.getState();
        this.SiteInformation = this.state.SiteInformation;
        this.UserInfo = this.state.UserInformation;
        this.permissions = this.state.permissions;
        this.click = 0
    }

async showEnquiry (EnquiryId:number) {
    this.lastTouched(EnquiryId);
    store.dispatch(setActiveEnquiry(EnquiryId));
    store.dispatch(setShowEnquiry(true));
}

async ActivateWizard(EnquiryId:number, showConfirmDialogue?:Function) {
    if (this.wizardIdPermitted(EnquiryId)) {
    this.state = store.getState();
    this.SiteInformation = this.state.SiteInformation;
    this.UserInfo = this.state.UserInformation;

    const enQ = getEnquiryById(EnquiryId);
    if (enQ !== undefined) {
        this.lastTouched(EnquiryId);
        await this.handleActivateWizard(enQ, showConfirmDialogue);
    }
    }else{
        this.showEnquiry(EnquiryId)
    }
}

showDiv(divId: number) {
    const iLastTouched = getLastTouched();
    if (this.SiteInformation.queuesListVisible == true) {
        if (iLastTouched.Queue.ActiveList > 0) {
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = divId;
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
        }
    } else if (this.SiteInformation.searchResultsVisible == true) {
        iLastTouched.SearchResults.EnquiryLists[0].EnquiryId = divId;
        iLastTouched.SearchResults.EnquiryLists[0].CommId = 0;
    }
    else {
        if (iLastTouched.MyEnquiries.ActiveList == "Unresolved") {
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId = divId;
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.CommId = 0;
        }
        else if (iLastTouched.MyEnquiries.ActiveList == "Resolved") {
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId = divId;
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.CommId = 0;
        }
    }
    saveLastTouched(iLastTouched);
    // store.dispatch(setEnquiryValue(divId));
}

wizardIdPermitted(enquiryId: number) {
    const e = getEnquiryById(enquiryId)
    return e !== undefined && this.wizardPermitted(e);
}

wizardPermitted(e: iEnquiry) {
    return (
        GetMailboxPermissions(this.permissions, e.mailbox).ActionEnquiry
    ) && e?.owners?.find(ow => ow.id == this.UserInfo.userID) != undefined && !inWizard(e.id);
}

async btClick(divId: number, showConfirmDialogue: Function) {
		this.click++;
		setTimeout(async () => {

			if (this.click == 1) {
				this.showDiv(divId);
				store.dispatch(setActiveEnquiry(divId));
				store.dispatch(setShowEnquiry(true));

				this.click = 0;
			}
			else if (this.click > 1) {
                if (this.wizardIdPermitted(divId)) {
                    new ActivateWizardModule().ActivateWizard(divId,showConfirmDialogue);
                }
                else {
                    this.showDiv(divId);
				    store.dispatch(setActiveEnquiry(divId));
				    store.dispatch(setShowEnquiry(true));
                }
                this.click = 0;
			}
		}, 300);
	}

     


lastTouched(EnquiryId: number): void {
    const iLastTouched = getLastTouched();
    if (this.SiteInformation.queuesListVisible == true) {
        if (iLastTouched.Queue.ActiveList > 0) {
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.EnquiryId = EnquiryId;
            iLastTouched.Queue.EnquiryLists.find(x => x.Name == iLastTouched.Queue.ActiveList)!.CommId = 0;
        }
    } else if (this.SiteInformation.searchResultsVisible == true) {
        iLastTouched.SearchResults.EnquiryLists[0].EnquiryId = EnquiryId;
        iLastTouched.SearchResults.EnquiryLists[0].CommId = 0;
    }
    else {
        if (iLastTouched.MyEnquiries.ActiveList == "Unresolved") {
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.EnquiryId = EnquiryId;
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Unresolved")!.CommId = 0;
        }
        else if (iLastTouched.MyEnquiries.ActiveList == "Resolved") {
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.EnquiryId = EnquiryId;
            iLastTouched.MyEnquiries.EnquiryLists.find(x => x.Name == "Resolved")!.CommId = 0;
        }
    }
    saveLastTouched(iLastTouched);
    //   dispatch(setEnquiryValue(EnquiryId));
}


async handleActivateWizard(e:iEnquiry, showConfirmDialogue?:Function) {
    showHideLoader("flex");
    if (e.owners.find(x => x.id == this.UserInfo.userID)) {
        keepTheConnectionAlive(e.id).then((response: ActionStartResponse) => {
            if (response.misModel !== undefined && response.misModel != null) {

                if (response.misData === null || response.misData === undefined) {
                    response.misData = new Array<MisEntry>();
                }

                const dataMap = new Map<number, MisEntry>(response.misData.map(x => [x.fieldID, x]));

                response.misModel.fields.sort((a, b) => a.displayOrder - b.displayOrder);
                response.misModel.fields.forEach(x => {
                    if (x.lookupValues !== null && x.lookupValues !== undefined) {
                        x.lookupValues.sort((a, b) => a.order - b.order);
                    }

                    if (!dataMap.has(x.id)) {
                        response.misData.push({
                            "fieldID": x.id,
                            "value": x.defaultValue ? x.defaultValue : ""
                        });
                    }
                });
            }
            this.WorkTheCase(e, response.alarm, response.misData, response.misModel);
            const enQ = JSON.parse(JSON.stringify(e));
            if (enQ.status != Statuses.Processing.ID) {
                enQ.status = Statuses.Processing.ID;
                enQ.isCompleted = false;
            }
            store.dispatch(updateEnquiriesCache([enQ]));
            refreshList(this.UserInfo.userID).then(() => { });
            showHideLoader("none");
        }).catch((err) => {
           showConfirmDialogue?.(e.id, true)
            // alert("Case in user processing");
            showHideLoader("none");
        });

    } else {
        this.lastTouched(e.id);
        store.dispatch(setActiveEnquiry(e.id));
        store.dispatch(setShowEnquiry(true));
        store.dispatch(setShowWizard(false));
        showHideLoader("none");
    }
}


WorkTheCase(e: iEnquiry, alarm: iAlarm | undefined, misData: Array<MisEntry>, misModel: MisModel | undefined) {
    if (e.owners.find(x => x.id == this.UserInfo.userID)) {
        store.dispatch(addTab({ active: true, enquiryId: e.id, label: e.enquiryReference + " " + e.enquirerName }));
        this.activateWorkflow(e, alarm, misData, misModel);
        setTimeout(() => {
            store.dispatch(setWorkflowVisible(true));
            store.dispatch(setActiveEnquiry(e.id));
            store.dispatch(setShowEnquiry(true));
            store.dispatch(setShowWizard(true));
        }, 300)
    }
}


activateWorkflow(e: iEnquiry, alarm: iAlarm | undefined, misData: Array<MisEntry>, misModel: MisModel | undefined) {
    let wizard = GetWorkflowFromStorage(e.id);
    if (!wizard) {
        wizard =
        {
            EnquiryId: e.id,
            oldEnquiryStatus: e.status,
            isNew: (e.enquirerName=='' && e.enquirerEmail=='') ? true : e.status == (Statuses.Received.ID),
            includeFeedbackRIF: true,
            includeFeedbackForward: true,
            includeFeedbackEscalate: true,
            includeFeedbackPend: true,
            mailDrafts: [],
            workflow: {
                LinkToEnquiry: {},
                data: {
                    EnquiryId: e.id,
                    EnquiryTracker: "",
                    EnquirerDetails: {
                        FullName: e.enquirerName,
                        Email: e.enquirerEmail,
                        EmailSelector: "",
                        Subject: e.subject,
                        WorkItems: e.workItems
                    },
                
                    MISData: misData,
                    UserId: this.UserInfo.userID,
                    Drafts: [],
                    CommVerification: e.communications.length
                },
                history: [],
                currentStep: EStepKey.ENQUIRY_TRACKER_STEP,
                ApiResult: {
                    "success": false,
                    "notFound": false,
                    "error": false
                }
            },	
            MISModel: misModel,
            CurrentAlarm: alarm,
        }
        initialiseSteps(e, wizard)
    }

    if ((document.getElementById(e.id.toString()) as HTMLElement) && (document.getElementById(e.id.toString()) as HTMLElement).className.indexOf("active") < 0) {
        (document.getElementById(e.id.toString()) as HTMLElement).className = (document.getElementById(e.id.toString()) as HTMLElement).className + " active";
    }

    SaveWorkflowToStorage(wizard);
    store.dispatch(setState(wizard));
}
}
export function saveNewEnquiryId(id: string) {
    const newEnqs = getLocalValue<string[]>("new-enquiries");
    if (newEnqs != null) {
        if (newEnqs.length > 0) {
            saveLocalValue("new-enquiries", [...newEnqs, id])
        }
        else {
            saveLocalValue("new-enquiries", [id])
        }
    }
    else {
        saveLocalValue("new-enquiries", [id])
    }
}

export function removeNewEnquiryId(id: number) {
    let newEnqs = getLocalValue<string[]>("new-enquiries");
    if (newEnqs != null) {
        newEnqs = newEnqs.filter(en => en != id.toString())
        if (newEnqs.length > 0) {
            saveLocalValue("new-enquiries", newEnqs)
        }
        else {
            localStorage.removeItem("new-enquiries")
        }
    }
    else {
        localStorage.removeItem("new-enquiries")
    }
}

export function inWizard(enquiryId: number): boolean {
    const nonPoppedWizs = getSessionValue<Wizard[]>("Workflows")
    if (nonPoppedWizs?.find(wiz => wiz.EnquiryId == enquiryId)) {
        return true;
    }
    const poppedWizs = getSessionValue<iWizards>("poppedWizards")
    if (poppedWizs?.wizards.find(wiz => wiz.id == enquiryId)) {
        return true;
    }
    return false;
}

export function removePopped(enquiryId: number) {
    const poppedWizs = getSessionValue<iWizards>("poppedWizards")
    if (poppedWizs != null) {
        poppedWizs.wizards = poppedWizs.wizards.filter(wiz => wiz.id != enquiryId) || null;
        if (poppedWizs.wizards.length > 0) {
            saveSessionValue("poppedWizards", poppedWizs);
        }
        else {
            removeSessionValue("poppedWizards")
        }
    }
    else {
        removeSessionValue("poppedWizards")
    }
}

export function isNewEnquiry(id: number) {
    const newEnqs = getLocalValue<string[]>("new-enquiries");
    return newEnqs != null && newEnqs.length > 0 && newEnqs.find(enqId => enqId == id.toString()) != undefined;
}