import { AddHours } from "../Logic/DateTimeFormatters";
import { isNullOrWhitespace } from "./string";

export enum EDay {
    TOMMORROW = -1,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
}

/**
 * Obtains the next week's date on given day
 * @param targetDay Day of the week next week of given date (0 = Mon, 1 = Tue, ..., 6 = Sun)
 * @param date Start date
 * @returns Next week's date of a given day
 */
export function getNextWeekDateOnDay(targetDay: EDay, date: Date): Date {
    if (targetDay === EDay.TOMMORROW) {
        throw new Error('Day must be between 0 and 6 (Monday and Sunday).');
    }
    const daysLeft = (8 + targetDay) - date.getDay();
    return addDays(daysLeft, date);
}

export function addDays(count: number, date: Date): Date {
    date.setDate(date.getDate() + count);
    return date;
}

export function updateTime(newTime: string, dateTime: string): Date {
    const date = new Date(dateTime)
    const timeSplit = newTime.split(":");
    date.setHours(+timeSplit[0]);
    date.setMinutes(+timeSplit[1]);
    if (timeSplit[2].includes(".")) {
        const secondsSplit = timeSplit[2].split(".");
        date.setSeconds(+secondsSplit[0]);
        if (secondsSplit[1].length === 3)
            date.setMilliseconds(+secondsSplit[1]);
    }
    else
        date.setSeconds(+timeSplit[2]);
    return date;
}

/**
 * Rounds off the time to the nearest hour
 * @param date Date with time to round off hours
 */
export function ceilHours(date: Date): Date {
    const newDate = AddHours(date, 1);
    newDate.setMinutes(0, 0, 0);
    return newDate;
}

export function toTime(date: string): string {
    const dateTime = new Date(date);
    const hours = dateTime.getHours() >= 10 ?
        dateTime.getHours() + "" :
        "0" + dateTime.getHours();
    const minutes = dateTime.getMinutes() >= 10 ?
        dateTime.getMinutes() + "" :
        "0" + dateTime.getMinutes();
    const seconds = dateTime.getSeconds() >= 10 ?
        dateTime.getSeconds() + "" :
        "0" + dateTime.getSeconds();
    return hours + ":" + minutes + ":" + seconds;
}

export function toAnalogueTime(date: string): string {
    const dateTime = new Date(date);
    const hoursN = dateTime.getHours();
    const minsN = dateTime.getMinutes();
    const hours = hoursN === 0 || hoursN === 12 ?
        "12" :
        hoursN % 12;
    const mins = minsN > 9 ?
        minsN :
        "0" + minsN;
    const antePost = hoursN < 12 ? "AM" : "PM";
    return `${hours}:${mins} ${antePost}`;
}

export function addZeros(val: number, targetLength: number): string {
    let zeros = "";
    for (let i = 0; i < targetLength - (val+"").length; i++) {
        zeros += "0";
    }
    return (zeros + val);
}

export function toDate(date: string): string {
    const dateTime = new Date(date);
    return `${addZeros(dateTime.getFullYear(), 4)}-${addZeros(1 + dateTime.getMonth(), 2)}-${addZeros(dateTime.getDate(), 2)}`;
}

export function toDateTime(date: string): string {
    if (isNullOrWhitespace(date))
        return "";
    
    return `${toDate(date)}T${toTime(date)}`;
}
