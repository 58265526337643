import { useState } from 'react';
import { componentProps } from '../../../interfaces/ActionStartResponse';
import '../mis.scss';

function TextBox(props: componentProps) {
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)

    const handleSelectChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.value);
        const sendToParent = props.returnFunc;
        sendToParent({ fieldID: props.MisEntry.fieldID, value: e.currentTarget.value });
    }

    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div className='col-sm-3'><label className='g-label'>{props.MisField.label}</label></div>
            <div className='col-sm-9'>
                <input type="text" className="g-TextBox-default" onChange={handleSelectChange} value={fieldValue} maxLength={512} autoComplete="off" aria-autocomplete='none' list="autocompleteOff" />
            </div>
        </div>
    );
}
export default TextBox;

