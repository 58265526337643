import { useEffect, useRef, useState } from 'react'

import { BsChevronDown } from 'react-icons/bs'
import React from 'react'
import useOutsideClick from '../../../hooks/useOutsideClick'
 

export type dropdownBasicProps = {
 id:number,
 label:string,
 children: Array<any>
}
const DropdownBasic = (props: dropdownBasicProps) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const onOutsideClick = () => {
        setDropdownOpen(false)
    }
    useOutsideClick(buttonRef, onOutsideClick)

    return (
         <div className={"g-dropdown-basic" + (dropdownOpen ? "-selected" : "")} key={props.id} ref={buttonRef}  >
                <button tabIndex={0}
                    onClick={() => setDropdownOpen(o => !o)}
                > {props.label}  <BsChevronDown style={{ fontSize: "10px", marginLeft: "10px" }} > </BsChevronDown>
                </button>
                <ul onMouseLeave={() => setDropdownOpen(false)}>
                    {props.children}
                </ul>
         </div>
    )
}

export default DropdownBasic