import { createSlice } from '@reduxjs/toolkit';
import { ConstructDefault, Permissions as iPermissions } from "../interfaces/Permissions";

const initialState: iPermissions = ConstructDefault();
 
export const PermissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        setPermissions: (state, action) => {
            return {...action.payload}
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPermissions } = PermissionSlice.actions

export default PermissionSlice