import { IconBaseProps } from "react-icons";

export function PriorityIcon(props: IconBaseProps) {
    return (
        <svg width="12" height="16"
            viewBox="0 0 12 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="2" y="1"
                width="8" height="13"
                stroke={props.color ?? "black"}
                stroke-width="0.63"
            />
            <rect
                x="2" y="7"
                width="8" height="7"
                fill={props.color ?? "black"}
            />
        </svg>
    )
}
