//state/workflow

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { iComposeEmail } from '../interfaces/emailModel';
import { getSessionValue, removeSessionValue, saveSessionValue } from '../utilities/localStore/calls';
import { iCommunication } from '../interfaces/iEnquiry';
import { getActiveList } from '../interfaces/enquiryList';

import { iAuth } from '../interfaces/auth';
import { getTenantToken } from '../Logic/Auth/TokenManager';
import { getUnresolvedEnquiriesByQueueGlobal } from '../Logic/Enquiries/Queue';
import { getResolvedEnquiriesGlobal, getUnresolvedEnquiriesGlobal } from '../Logic/Enquiries/My';
import { MisEntry, MisModel } from '../interfaces/ActionStartResponse';
import { deleteWorkerProcessFromSession } from '../utilities/userProcessing';
import { AdvancedSearchResults } from '../interfaces/AdvancedSearch/AdvancedSearchResults';
import { EStepKey } from '../Logic/Wokflow/stepLogic';
import { EEnquiryAction } from '../interfaces/wizard';
import iAlarm from '../interfaces/iAlarm';
import { EDay } from '../utilities/date';
import iEmailAddress from '../interfaces/iEmailAddress';
import { iAttachment } from '../components/CreateNoteForm';
import { ECommType } from '../constants/OtherMediumType';
import { iUser } from '../interfaces/iUser';
import { iExplanatoryForm, iExplanatoryNote } from '../interfaces/ExplanatoryNote';
import { removeConfirm } from '../utilities/windowConfirm';
import { Finish } from '../Logic/EmsAPI/EnquiryAction';
import { iEmsApiResponse } from '../Logic/EmsAPI/FetchExtension';

export interface Wizard {
    EnquiryId: number,
    oldEnquiryStatus: number,
    workflow: WorkflowState,
    lastCommunication?: iCommunication,
    newAlarmControls?: {
        isQuicky: boolean,
        selectedQuicky?: EDay,
        alarmEnabled: boolean
    },
    otherMediumControl?: iCommLoggerForm,
    explanatoryNoteControl?: iExplanatoryForm,
    otherUserControl?: iUser,
    newAlarm?: iAlarmControl,
    MISModel?: MisModel | undefined,
    CurrentAlarm?: iAlarm | undefined,
    isNew: boolean,
    includeFeedbackRIF: boolean,
    includeFeedbackForward: boolean,
    includeFeedbackEscalate: boolean,
    includeFeedbackPend: boolean,
    mailDrafts: { // Ordered list. As they are ordered; so they shall be inserted and shown in comm history.
        action: number,
        draft: iMailDraft
    }[]
}

export interface iMailDraft {
    id: number,
    subject: string,
    to: iEmailAddress[],
    cc: iEmailAddress[],
    bcc: iEmailAddress[],
}

export interface iAlarmControl {
    subject?: string,
    alarmAtTime?: string,
    alarmAtDate?: string,
    notes?:	string
}

export interface iAlarmData {
    subject?: string,
    alarmAt: string,
    notes?:	string
}


export interface iOtherMedium {
    Type?: ECommType,
    Subject?: string,
    Body?: string,
    To?: string,
    DraftID?: number
}

export interface iFileMeta {
    lastModified?: number,
    name: string,
    size: number,
    type: string,
    webkitRelativePath?: string
}

export interface iAttachmentMeta {
    f: iFileMeta,
    partId: number
}

export interface iCommLoggerForm extends iOtherMedium {
    attachments?: iAttachmentMeta[]
}

export interface WorkflowState {
    LinkToEnquiry: iLinkToEnquiry,
    data: iWorkflowModel,
    ApiResult: ApiResult,
    history: iStepSummary [],
    currentStep: EStepKey
}

export interface iStepSummary {
    stepKey: EStepKey,
    summary: string []
}

export interface ApiResult {

    success: boolean,
    notFound: boolean,
    error: boolean
}
export interface iWorkflow {
    step: EStepKey,
    active: boolean,
    enabled: boolean
}
export interface iEnquiryMaildraftId {
    EnquiryId: number,
    MailDraftId: number
}
export interface iLinkToEnquiry {
    searchResults?: AdvancedSearchResults,
    chosenEnquiryActiveComm?: iCommunication
}
export interface iWorkflowModel {
    EnquiryId: number,
    EnquiryTracker: string,
    ExistingEnquiryID?: number,
    PendTo?: string,
    Alarm?: iAlarmData,
    EnquirerDetails: iEnquirerDetails,
    MISData: Array<MisEntry>,
    EnquiryAction?: EEnquiryAction,
    UserId: number,
    Drafts: iMailDraft[],
    OtherMedium?: iOtherMedium,
    NewEnquiryCommID?: number,
    NewUserID?: number,
    Note?: iExplanatoryNote,
    CommVerification: number
}
export interface iEnquirerDetails {
    [x: string]: string | number;
    FullName: string,
    Email: string,
    Subject: string,
    WorkItems: number
}



const initialState: WorkflowState = {
    LinkToEnquiry: {},
    data: {
        EnquiryId: 0,
        EnquiryTracker: "",
        EnquirerDetails: {
            FullName: "",
            Email: "",
            Subject: "",
            WorkItems: 3
        },
        MISData: [],
        UserId: 0,
        Drafts: [],
        CommVerification: 0
    },
    history:[],
    currentStep: EStepKey.ENQUIRY_TRACKER_STEP,
    ApiResult: {
        "success": false,
        "notFound": false,
        "error": false
    }
}


export const GetWorkflowFromStorage = (EnquiryId: number): Wizard | undefined => {
    const workflows = getSessionValue<Wizard[]>("Workflows");
    return workflows?.find(x => x.workflow.data.EnquiryId == EnquiryId);
}

export const SaveWorkflowToStorage = (workflow: Wizard) => {
if(Object.keys(workflow).length>0){
    let allWorkflows: Array<Wizard> = [];
    const workflows = getSessionValue<Wizard[]>("Workflows");
    // remove the current worklflow and overwrite it
    if (workflows) {
        allWorkflows = workflows.filter(item => item.workflow.data.EnquiryId != workflow.workflow.data.EnquiryId);
    }
    allWorkflows.push(workflow);

    saveSessionValue("Workflows", allWorkflows);
}
}

export const DeleteWorkflowInStorage = (EnquiryId: number) => {

    let allWorkflows: Array<Wizard> = [];
    const workflows = getSessionValue<Wizard[]>("Workflows");
    if (workflows) {
        allWorkflows = workflows.filter(item => item.workflow.data.EnquiryId != EnquiryId); // remove the current worklflow
        if (allWorkflows.length > 0)
            saveSessionValue("Workflows", allWorkflows);
        else
            removeSessionValue("Workflows");
    }
}

export const actionEnquiryProcess = async (wData: iWorkflowModel, popped: boolean): Promise<iEmsApiResponse> => {
    (document.getElementById("loader") as HTMLElement).style.display = "block";

    const res = await Finish(wData)
    if (res.isOk) {
        window.Workers = window.Workers.filter(x => x.enquiryId != wData.EnquiryId);
        deleteWorkerProcessFromSession(wData.EnquiryId);
        let tabValue = document.getElementById("tab-" + 10.35)
        if (tabValue == undefined) {
            tabValue = window.opener.document.getElementById("tab-" + 10.35);
        }
        if (tabValue != undefined) {
            const visibleList = getActiveList(tabValue.innerText)

            if (visibleList == "Unresolved") {
                await getUnresolvedEnquiriesGlobal(wData.UserId);
            }
            else if (visibleList == "Resolved" || tabValue.innerText == "Search Results") {
                await getResolvedEnquiriesGlobal(wData.UserId);
            }
            else if (!Number.isNaN(visibleList)) { // queues
                await getUnresolvedEnquiriesByQueueGlobal(Number(visibleList));
            }
        }
        else {
            await getUnresolvedEnquiriesGlobal(wData.UserId);
        }
        postWizardUpdate(popped);

        function postWizardUpdate(popped: boolean) {
            if (popped) {
                removeConfirm();
                if (window.opener != null && !window.opener.closed) {

                    window.opener.sessionStorage.setItem("ActiveListEnquiries", window.sessionStorage.getItem("ActiveListEnquiries"));
                }
            }
            (document.getElementById("loader") as HTMLElement).style.display = "none";
        }
    }
    return res;
}

export const postToWizardAction = async (wData: iWorkflowModel, popped: boolean): Promise<any> => {
    var promise = new Promise<any>((resolve, reject) => {
        // let element = (document.getElementById("step4Radio") as HTMLInputElement)
        if (wData.EnquiryAction !== undefined) {
            //   workflows.data.EnquiryAction =element.value;
            (document.getElementById("loader") as HTMLElement).style.display = "block";


            const res = fetch(process.env.REACT_APP_API + 'Enquiry', {
                method: "post",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    "Authorization": "bearer " + getTenantToken()

                },
                ),
                body: JSON.stringify(wData)
            }).then(
                (data) => {
                    if (data.status == 500) {
                        reject(data);
                    }
                    else if (data.status == 200 || data.status == 204) {

                        let tabValue = document.getElementById("tab-" + 10.35)
                        if (tabValue == undefined) {
                            tabValue = window.opener.document.getElementById("tab-" + 10.35);
                        }
                        if (tabValue != undefined) {
                            const visibleList = getActiveList(tabValue.innerText)

                            if (visibleList == "Unresolved") {
                                getUnresolvedEnquiriesGlobal(wData.UserId).then(() => {
                                    postWizardUpdate(popped)
                                });
                            }
                            else if (visibleList == "Resolved") {
                                getResolvedEnquiriesGlobal(wData.UserId).then(() => {
                                    postWizardUpdate(popped)
                                });
                            }
                            else if (!Number.isNaN(visibleList)) { // queues
                                getUnresolvedEnquiriesByQueueGlobal(Number(visibleList)).then(() => {
                                    postWizardUpdate(popped)
                                });
                            }
                        }
                        else {
                            getUnresolvedEnquiriesGlobal(wData.UserId).then(() => {
                                postWizardUpdate(popped)
                            });
                        }

                        function postWizardUpdate(popped: boolean) {
                            if (popped) {
                                removeConfirm();
                                window.close();
                            }
                            (document.getElementById("loader") as HTMLElement).style.display = "none";
                            resolve(data);
                        }

                    }
                }

            ).catch(
                (err) => {
                    alert(err);
                    reject(err);
                }
            )

        }
        else {
            alert("please select action")
            reject("please select action");
        }
    });
    return promise;
}



export const WorkflowSlice = createSlice({
    name: 'getWorkflow',
    initialState,
    reducers: {

        updateEnquiryDetails: (state, action: PayloadAction<iEnquirerDetails>) => {
            state.data.EnquirerDetails = action.payload;
        },
        setEnquiryId: (state, action: PayloadAction<number>) => {
            state.data.EnquiryId = action.payload;
        },
        setState: (state, action: PayloadAction<Wizard>) => {

            state.data = action.payload.workflow.data;
            state.data.EnquiryId = action.payload.workflow.data.EnquiryId;
            state.history = action.payload.workflow.history;
        },
    },
    extraReducers: (builder) => {   },
});

// Action creators are generated for each case reducer function
export const { updateEnquiryDetails, setEnquiryId, setState } = WorkflowSlice.actions

export default WorkflowSlice;

