import React from "react";
import { iContactData } from "../../../../interfaces/Contact";
import { isEmail } from "../../../../interfaces/emailModel";
import iEmailAddress from "../../../../interfaces/iEmailAddress";
import { iEnquiry } from "../../../../interfaces/iEnquiry";
import {   Wizard } from "../../../../state/workflowSlice";
 
export function getEnquiryContacts(enquiry: iEnquiry, wizard:Wizard): iEmailAddress[] {
    const recents: iEmailAddress[] = [];
    if (isEmail(wizard.workflow.data.EnquirerDetails.Email)) {
        recents.push(
            {
                name: wizard.workflow.data.EnquirerDetails.FullName,
                address: wizard.workflow.data.EnquirerDetails.Email
            }
        )
    }
    else if (isEmail(enquiry.enquirerEmail)) {
        recents.push(
            {
                name: enquiry.enquirerName,
                address: enquiry.enquirerEmail
            }
        )
    }

    for (let i = 0; i < enquiry.communications.length; i++) {
        const from: iEmailAddress | undefined = enquiry.communications[i].email?.from;
        const tos: iEmailAddress[] | undefined = enquiry.communications[i].email?.to;
        const ccs: iEmailAddress[] | undefined = enquiry.communications[i].email?.cc;
        const bccs: iEmailAddress[] | undefined = enquiry.communications[i].email?.bcc;
        if (from !== undefined && recents.find((t) => {
            return t.address.toLowerCase() === from.address.toLowerCase();
        }) === undefined) {
            recents.push(from);
        }
        tos?.forEach((ct) => {
            if (recents.find(t => t.address.toLowerCase() === ct.address.toLowerCase()) === undefined)
                recents.push(ct);
        })
        ccs?.forEach((ct) => {
            if (recents.find(t => t.address.toLowerCase() === ct.address.toLowerCase()) === undefined)
                recents.push(ct);
        })
        bccs?.forEach((ct) => {
            if (recents.find(t => t.address.toLowerCase() === ct.address.toLowerCase()) === undefined)
                recents.push(ct);
        })
    }

    return recents;
}

export function getResults(query: string, contacts: iContactData[], limit: number): iContactData[] {
    if (query === "")
        return contacts.slice(undefined, limit)

    // match and merge
    const matches: iContactData[] = [];
    const nonMatches: iContactData[] = [];

    contacts.forEach(ct => {
        if (ct.name && ct.name.toLowerCase().includes(query.toLowerCase())) {
            matches.push(ct);
        }
        else if (ct.address.toLowerCase().includes(query.toLowerCase())) {
            matches.push(ct);
        }
        else {
            nonMatches.push(ct);
        }
    });

    // sort by strong
    const sortedMatches: iContactData[] = matches.sort((a: iContactData, b: iContactData) => {
        const aNameStarts = a.name?.startsWith(query)
        const bNameStarts = b.name?.startsWith(query)
        const aAddStarts = a.address.startsWith(query)
        const bAddStarts = b.address.startsWith(query)

        return aNameStarts || aAddStarts ? -1 : bNameStarts || bAddStarts ? 1 : 0;
    })

    const results: iContactData[] = [...sortedMatches, ...nonMatches].slice(undefined, limit);

    return results;
}

export function sortContacts(contacts: iEmailAddress[]): iEmailAddress[] {
    return contacts.sort((a, b) => {
        const aVal = a.name === undefined || a.name === "" ? a.address.toLowerCase() : a.name.toLowerCase();
        const bVal = b.name === undefined || b.name === "" ? b.address.toLowerCase() : b.name.toLowerCase();
        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    })
}