import { useState } from 'react';
import { componentProps } from '../../../interfaces/ActionStartResponse';
import '../mis.scss';
function Checkbox(props: componentProps) {
    const [fieldValue, setFieldValue] = useState(props.MisEntry.value)

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFieldValue(e.currentTarget.checked ? "1" : "0");
        const sendToParent = props.returnFunc;
        sendToParent( {fieldID:props.MisEntry.fieldID,value:e.currentTarget.checked ? "1" : "0"});
    }
 
    return (
        <div className="row rowMarginControl" key={"Main" + props.MisField.id}>
            <div   className='col-sm-3'>
                <label  className='g-label'>
                    {props.MisField.label}
                </label>
            </div>
            <div  className='col-sm-9 '>
        
                <input
                    type="checkbox"
                    // className=" form-control form-control-sm"
                    onChange={handleChange}
                    checked={fieldValue === "1"}
                    className='g-Checkbox-default'
                />
                   <label  className='g-label'>Yes</label>
            </div> 
        </div>
    );
}
export default Checkbox;

