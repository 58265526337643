import { DraftTemplateGroup } from "../../constants/DraftTemplateGroup";
import { iEmail } from "../../interfaces/emailModel";
import { getActiveTenant } from "../../Logic/Auth/TokenManager";


function getDB(dbName:string): Promise<any>{
    
    let db: IDBDatabase | null = null;
    var promise = new  Promise<any>((resolve, reject) => {
        const request = indexedDB.open(dbName, 5);
        request.onupgradeneeded = (e) => {
            db = request.result;
            switch (e.oldVersion) {
                  // @ts-ignore
                case 0:
                    const Communications = db.createObjectStore('Communications',  {keyPath: "communicationId"});
                    const Blobs = db.createObjectStore('Blobs',  {keyPath: "id"});
                    const TempStore = db.createObjectStore('TempStore',  {keyPath: "id"});
                    let Maildrafts = db.createObjectStore('MailDrafts',  {keyPath: "uId"});
                    Blobs.createIndex("id","id",{unique:true});
                    TempStore.createIndex("id","id",{unique:true});
                    Maildrafts.createIndex("uId","uId",{unique:true});
                    Communications.createIndex("communicationId","communicationId",{unique:true});
              // @ts-ignore
                case 1: 
                    db?.deleteObjectStore('MailDrafts')
                    let maildrafts = db?.createObjectStore('MailDrafts',  {keyPath: "uId"});
                    maildrafts!.createIndex("uId","uId",{unique:true});
                  // @ts-ignore
                case 2: 
                    db?.deleteObjectStore('MailDrafts')
                    let mailDrafts = db?.createObjectStore('MailDrafts',  {keyPath: "uId"});
                    mailDrafts!.createIndex("uId","uId",{unique:true});
              // @ts-ignore
                case 3: 
                    let configurations = db?.createObjectStore('Configurations',  {keyPath: "uId"});
                    configurations!.createIndex("uId","uId",{unique:true});
                // @ts-ignore
                case 4: 
                    let cache = db?.createObjectStore('Cache',  {keyPath: "uId"});
                    cache!.createIndex("uId","uId",{unique:true});
                
            }
        }
        request.onsuccess = () => {resolve(request.result)};
        request.onerror = (e) => (resolve(e));
    })
    return promise;
}

export const deleteItem = (objectStoreName:string, data:any, tenantName?: string | undefined): Promise<any> =>{
    const dbName = tenantName?.toLocaleLowerCase() ?? getActiveTenant();
    var promise = new  Promise<any>((resolve, reject) => {
        getDB(dbName).then((db)=>{
        if ([...db.objectStoreNames].find((name) => name === objectStoreName)) {
            const transaction = db.transaction(objectStoreName, 'readwrite');
            const objectStore = transaction.objectStore(objectStoreName);
            const request = objectStore.delete(data);
            request.onerror = () => {
                reject(request.error);}
            transaction.oncomplete = () => {db.close();
                resolve("success");
            }
            ;
        } else {
            //indexedDB.deleteDatabase('data');
        }
        })
    })

    return promise;
}
export const addItem = (  objectStoreName:string, data:any, tenantName?: string | undefined): Promise<any> =>{
    const dbName = tenantName?.toLocaleLowerCase() ?? getActiveTenant();
    var promise = new  Promise<any>((resolve, reject) => {
        getDB(dbName).then((db)=>{
        if ([...db.objectStoreNames].find((name) => name === objectStoreName)) {
            const transaction = db.transaction(objectStoreName, 'readwrite');
            const objectStore = transaction.objectStore(objectStoreName);
            const request = objectStore.put(data);
            request.onerror = () => {
                alert(request.error);
                reject(request.error)
            };
            transaction.oncomplete = () => {db.close();
                resolve("success");
            }
       
        } else {
            //indexedDB.deleteDatabase('data');
        }
        })
    })

    return promise;
}

export const getItem = async (  objectStoreName:string, key:any, tenantName?: string | undefined): Promise<any> =>{
    const dbName = tenantName?.toLocaleLowerCase() ?? getActiveTenant();
    var promise = new  Promise<any>((resolve, reject) => {
        getDB(dbName).then((db)=>{
            let request!: IDBRequest;
        if ([...db.objectStoreNames].find((name) => name === objectStoreName)) {
            const transaction = db.transaction(objectStoreName, 'readwrite');
            const objectStore = transaction.objectStore(objectStoreName);
            if (key === 'all') {
                request = objectStore.getAll();
            }
            else {
                request = objectStore.get(key);
            };
                request.onerror = () => reject(
                    request.error);
                request.onsuccess = () => resolve(request.result);
                transaction.oncomplete = () => db.close();
        
        } else {
            reject("ObjectStore not found");
        }
        })
    })

    return promise;
}

export const gcMaildrafts = async (tenantName?: string) => {
    const dbName = tenantName?.toLocaleLowerCase() ?? getActiveTenant();
    const objectStoreName = "MailDrafts";
    const limitDuration = 30 * 24 * 60 * 60 * 1000; // 30 days
    const thirtyDays = new Date(new Date().valueOf() - limitDuration);
    const db: IDBDatabase = await getDB(dbName);
    if ([...db.objectStoreNames].find((name) => name === objectStoreName)) {
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const objectStore: IDBObjectStore = transaction.objectStore(objectStoreName);
        const request = objectStore.openCursor();
        request.onsuccess = () => {
            const cursor = request.result;
            if (cursor) {
                const draft: iEmail = cursor.value
                const updateDate: Date | undefined = draft.updateDate;
                if (!updateDate) {
                    objectStore.put({...draft, updateDate: new Date()}, draft.uId);
                }
                else if (updateDate < thirtyDays) {
                    objectStore.delete(cursor.key);
                }
                cursor.continue();
            }
        }
        transaction.oncomplete = () => db.close();
    }
}

export const deleteEnquiryMailDrafts = async (enquiryId: number, tenantName?: string | undefined): Promise<void> => {
    const dbName = tenantName?.toLocaleLowerCase() ?? getActiveTenant();
    const objectStoreName = "MailDrafts";
    const db: IDBDatabase = await getDB(dbName);
    if ([...db.objectStoreNames].find((name: string) => name === objectStoreName)) {
        const transaction: IDBTransaction = db.transaction(objectStoreName, 'readwrite');
        const objectStore: IDBObjectStore = transaction.objectStore(objectStoreName);
        Object.values(DraftTemplateGroup).forEach(action => {
            const request = objectStore.delete(`${enquiryId}_${action}`);
            request.onerror = (event) => event.preventDefault();
        });
        transaction.oncomplete = () => db.close();
        transaction.onerror = () => db.close();
    }
}