import React, { ForwardedRef, forwardRef, useState } from "react";
import './styles.scss'


export interface props {
    comboKey: string,
    className: string,
    disabled: boolean,
    value?: string | number | readonly string[] | undefined,
    onChange?: (value: string) => void,
    onBlur?: (value: any) => void,
    options?: string[] | number[] | undefined,
    maxLength?: number | undefined,
}

const ComboBox = forwardRef(function ComboBox(props: props, ref: ForwardedRef<HTMLInputElement>) {

    function onDropDownItemSelected(value: string) {
        props.onChange?.(value);
        setShowDropDown(false);
    }

    const [showDropDown, setShowDropDown] = useState(false);
    const [mouseOnOptions, setMouseOnOptions] = useState(false);

    return (
        <div className="comboBox">
            <input
                onBlur={(e) => {!mouseOnOptions && setShowDropDown(false) ; props.onBlur?.(e.target.value)} }
                type="textBox"
                className={props.className}
                disabled={props.disabled}
                value={props.value}
                maxLength={props.maxLength}
                onChange={(e) => props.onChange?.(e.target.value)}
                autoComplete="off"
                aria-autocomplete='none'
                list="autocompleteOff"
                spellCheck={false}
                ref={ref}
                onFocus={() => setShowDropDown(true)}
                
            />
            {showDropDown && props.options !== undefined && props.options.length > 0 &&
                <div className="comboBox-options" onMouseEnter={() => setMouseOnOptions(true)} onMouseLeave={() => setMouseOnOptions(false)}>
                    {props.options.map((option) =>
                        <div key={option} onMouseDown={() => onDropDownItemSelected(option as string)} className="comboBox-option">
                            {option}
                        </div>
                    )}
                </div>
            }
        </div>
    );
});

export default ComboBox;