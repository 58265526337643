import React from "react";
import { usePromiseTracker } from "react-promise-tracker";


export const showHideLoader = (showHide: "flex" | "none") => {
  if (document.getElementById("loader") != undefined) {
    if ((document.getElementById("loader") as HTMLElement).style.display != showHide) {
      (document.getElementById("loader") as HTMLElement).style.display = showHide;
    }
  }
}

export const setItemEnabled = (id: string, enabled: boolean) => {
  if (document.getElementById(id) && id!="") {
    document.getElementById(id)?.removeAttribute("disabled")
    if (enabled == false) {
      document.getElementById(id)?.setAttribute("disabled", "true")
    }
  }
}

export const Spinner = (props: string) => {
  const { promiseInProgress } = usePromiseTracker();



  return (
    promiseInProgress && (
      <div className="spinner">
       
        asd sad sadasdsa dsadsad sad
      </div>
    )
  );
};

export default Spinner;