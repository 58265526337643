import './index.scss'
import { ActiveTabName } from "./ActiveTabName"

interface Props {
    title: ActiveTabName,
    onClick: () => void,
    active: boolean,
    disabled?: boolean
}

export default function PropertiesTab(props: Props) {
    return (
        <button
            className={`enquiry-properties-tab${props.active ? " active": ""}${props.disabled ? " property-tab-disabled" : ""}`}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.title}
        </button>
    )
}
