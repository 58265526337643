import { IconMenuProps } from "../../interfaces/menu";

export function IconMenuItems(props: IconMenuProps) {

    return (
        <ul className={`enquiry-${props.menuStyle}-items`}>
            {
                props.options.map((op, i) =>
                    <li key={`option-${i}-${op.label}`}
                        className={`option${(op.disabled) ? " -disabled" : ""}${op.hidden ? " -hidden":""}`}
                        onClick={op.onClick}>
                        {op.icon}
                        <div className="limit-text">{op.label}</div>
                    </li>)
            }
        </ul>
    )
}
