import { iDraftRequest, iEmail } from "../../interfaces/emailModel";
import { Put, callApi, iRequest } from "../../utilities/apiCall";
import { addItem } from "../../utilities/localStore/indexDBCalls";
import { formatEmailList } from "./formatter";

export const createDraftId = async (ed:iEmail, EnquiryID:number): Promise<any> => {
    return new Promise(async (resolve, reject) => {
 
            let draft: iDraftRequest = {
              To: convereMailstoList(formatEmailList(ed.to)),
              Cc: convereMailstoList(formatEmailList(ed.cc)),
              Bcc: convereMailstoList(formatEmailList(ed.bcc)),
              Subject: ed.subject!,
              EnquiryID: EnquiryID,
              Priority: 3
            };
    
            let request: iRequest = {
              url: "Draft",
              method: "POST",
              headers: [{ key: "Content-Type", value: "application/json" }
              ],
              body: JSON.stringify(draft),
              responseType: "json"
            }
           await callApi(request).then((resp) => {
                resolve(resp.id);
    
    
            }).catch((err) => {
            reject(err);
            });
    
          }
    );
};
export const getDraftId = async (type: number, ed:iEmail): Promise<any> => {
    saveDraftToIndexedDb({...ed, updateDate: new Date()});
    var mypromise = new Promise(async (resolve, reject) => {
        if (type == 1) {
          let draft: iDraftRequest = {
            To: convereMailstoList(formatEmailList(ed.to)),
            Cc: convereMailstoList(formatEmailList(ed.cc)),
            Bcc: convereMailstoList(formatEmailList(ed.bcc)),
            Subject: ed.subject!,
            Priority: ed.priority,
            AttachedMessages : ed.attachedMessages
          };
          
         await Put(`Draft/${ed.maildraftId}`, draft).then((resp) => {
            resolve((ed.maildraftId));
          }).catch((err) => {
            reject("unable to update mail draft id");
          });

        }
        else {
          resolve((ed.maildraftId));
        }
     
    });
    return mypromise;
  }

 


export const saveDraftToIndexedDb = (ed: iEmail, complete: boolean = false ): Promise<any> => {
    return new Promise((resolve, reject) => {
      addItem( "MailDrafts", {...ed, updateDate: ed.updateDate ?? new Date()}).then(() => {
        resolve(true);
      }).catch((err) => {
        reject(err);
      })
    });

  }

  export const convereMailstoList = (listOfEmails: string): Array<{ Name: string, Address: string }> => {

    if (listOfEmails == "") {
      return [];
    }
    else {
      listOfEmails = listOfEmails.replaceAll(",", ";")
      let allEmails = listOfEmails.split(";").map((item) => { return { Name: "", Address: item.toString().trim() } })
      return allEmails;
    }
  }