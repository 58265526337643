import { createSlice } from '@reduxjs/toolkit'
export interface iSecondCol {
 
    workflowVisible: boolean,
    emailVisible: boolean,
    workflowPopped: boolean
 }

const initialState: iSecondCol = {
 
    workflowVisible: false,
    emailVisible: false,
    workflowPopped: false

}
export const SecondCol = createSlice({
    name: 'SecondCol',
    initialState,
    reducers: {
        setEmailAndWorkflowVisibility:(state, action) => {
            state.workflowVisible = action.payload;
            state.emailVisible = action.payload;
        },
        setWorkflowVisible: (state, action) => {
            state.workflowVisible = action.payload;
            state.emailVisible = true;
        },
        setEmailVisible: (state, action) => {
            state.emailVisible =  action.payload;
            //state.workflowVisible = false;
        },
        setWorkflowPopped: (state, action) => {
            state.workflowPopped = action.payload;
        },
       

    }
})


// Action creators are generated for each case reducer function
export const {  setWorkflowVisible, setEmailVisible , setWorkflowPopped,setEmailAndWorkflowVisibility} = SecondCol.actions

export default SecondCol