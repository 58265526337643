import { ContactProps } from "../../../../../interfaces/Contact";

export function Contact(props: ContactProps) {

    return (
        <li
            id={props.id}
            key={props.id}
            tabIndex={0}
            className="contact-container"
            onClick={() => props.onClick(props.id)}
            role="option"
        >
            <div className="c-contact">
                <div className="contact-name limit-text">
                    {props.name === undefined || props.name === "" ? props.address : props.name}
                </div>
                <div className="contact-address limit-text">{props.address}</div>
            </div>
            <div className="contact-tags">
                {props.inEnquiry && <div className="contact-tag enquiry-contacts">
                    In Enquiry
                </div>}
                {props.inMyContacts && <div className="contact-tag my-contacts">
                    My Contacts
                </div>}
            </div>
        </li>
    )
}