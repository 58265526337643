export function FormatSize(size: number | undefined): string {
    if (size === undefined || size == 0) {
        return "1 KB";
    }
    else if (size < Math.pow(2, 10)) {
        return size.toString() + " B";
    }
    else if (size < Math.pow(2, 20)) {
        return Math.round(size / Math.pow(2, 10)).toString() + " KB";
    }
    else {
        return Math.round(size / Math.pow(2, 20)).toString() + " MB";
    }
}