import React, { JSXElementConstructor, useRef, useState } from "react";
import { iEmail, iLocalMessageAttachment } from "../../../interfaces/emailModel";
import { isNullOrWhitespace } from "../../../utilities/string";
import { getIcon } from "../../../components/Generic/mimeIcon";
import { getAttachment } from "../../../Logic/GenericComms/CommParts";
import { BsFolder2Open, BsX } from "react-icons/bs";
import { deletePart, uploadFileViaXHR } from "../../../Logic/Mail/parts";
import { iAttachmentEvent } from "../../../interfaces/iAttachmentEvent";
import { getItem } from "../../../utilities/localStore/indexDBCalls";
import { saveDraftToIndexedDb } from "../../../Logic/Mail/draft";
import { Get } from "../../../utilities/apiCall";


type attachmentP = {
    attachment: iLocalMessageAttachment,
    emailComposer: iEmail,
    finishFunc : (ed:iEmail) =>  any,
}

function AttachmentProgress(props:attachmentP){
    const [errorThrown, setError] = React.useState(false);
    const progressReff = useRef<HTMLDivElement>(null);
    const [isContextMenuShown, setIsContextMenuShown] = useState<{ id: string, name: string, mimeType: string, size: number, visible:boolean, file:string}>({visible: false, id: "", file: "", name:"", mimeType: "", size: 0});
	const [aPosition, setAPosition] = useState({ x: 0, y: 0 });
    const [attachment, setAttachment] = React.useState<iLocalMessageAttachment>(props.attachment);

    React.useEffect(() => {
        (async () => {
            document.addEventListener("attach_Event_"+props.attachment.id,customEventProcessor);
        })();
        return () => {  document.removeEventListener("attach_Event_"+props.attachment.id,customEventProcessor);
                    };
    }, []);

    async function deleteAttachment(id: number) {
        let ec:iEmail = await getItem("MailDrafts", props.emailComposer.uId);
        deletePart(id, ec).then((returnVal) => {
            props.finishFunc(returnVal);
        }
        )
    }
    
   const customEventProcessor = (e:any)=>{
        let customEvent:iAttachmentEvent =  e.detail;
        let attach = JSON.parse(JSON.stringify(props.emailComposer.attachments.find(x=>x.id == props.attachment.id)!));
        switch(customEvent.process){

            case "Progress":
                var percent = Math.round((customEvent.loaded! / customEvent.total!) * 100);
                if( progressReff.current!=undefined){
                    progressReff.current!.style.width = (percent) + "%" 
                }
            break
            case "Uploaded":
                attach.status! = "uploaded"
                window.XhrRequests = window.XhrRequests.filter(x=>x.id!=props.attachment.id);
                setAttachment(attach);
            break
            case "Abort":
                attach.status! = null
                setAttachment(attach);
            break
            case "Error":
                attach.status! = "error"
                setAttachment(attach);
            break
        }
    }
    
  
 
    const showAttachmentContextMenu = (Attachment: {id:string, file:string, name:string,mimeType:string,size:number }) => (event: React.MouseEvent) => {
		// Disable the default context menu

		event.preventDefault();

		//setIsContextMenuShown({ visible: false, AttachmentId: attachId });
		const newPosition = {
			x: event.pageX,
			y: event.pageY,
		};
		setAPosition(newPosition);
		setIsContextMenuShown({ visible: true, id: Attachment.id, file: Attachment.file, name:Attachment.name, mimeType: Attachment.mimeType, size: Attachment.size});
	};    

    // Hide the custom context menu
	const hideContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
		setIsContextMenuShown({ visible: false, id: "", file: "", name:"", mimeType: "", size: 0});
	};
    const abortProcess= async (id:string)=>{
        if(window.XhrRequests.find(x=> x.id==id)){
            window.XhrRequests.find(x=> x.id==id)?.req.abort();
        }
        else{ await deleteAttachment(parseInt(id))
        }
    }
    const retryProcess= async (id:string)=>{
        const blob = await getItem( "Blobs", id);
        let ec:iEmail = await getItem("MailDrafts", props.emailComposer.uId);
        ec.attachments.find(x=>x.id == id)!.status! = "pending";
        await saveDraftToIndexedDb(ec, false);
        let part = await Get(`Draft/${ec.maildraftId}/Part/${id}`)
        uploadFileViaXHR(blob.data,id,part.uploadUrl,ec);
        setAttachment(ec.attachments.find(x=>x.id == id)!);
    }
    

    return (
     <>
{
    !isNullOrWhitespace(attachment.status) ?  (
        attachment.status=="uploaded" ?
             <div className="AttachmentItem" key={attachment.id}>   
            {getIcon(attachment.mimeType, attachment.name)}<div onContextMenu={showAttachmentContextMenu(attachment)}>    <span style={{color:"blue", fontWeight:"bold"}} onClick={()=>{getAttachment(attachment,'open')}} >{attachment.name} </span>  
               &nbsp; 
               {` ${(attachment.size ) > 999.99 ? ( (attachment.size / 1000).toFixed(2) ) + "MB"   : (attachment.size).toFixed(2) + 'KB' }`}
            </div>   
            <div onClick={() => { deleteAttachment(Number.parseInt(attachment.id)) }}><BsX style={{fontSize:"15px"}}></BsX></div>
            </div> : 

            attachment.status=="pending" ?
               <div className="AttachmentItem"  id='parentAtt_${aaa}' key={attachment.id}>   
                    {getIcon(attachment.mimeType, attachment.name)}<div>   {attachment.name} </div>   
                {
                    <><div style={{width:"100px",background:"white"}}  ><div className='uploadBar' style={{width: "1%"}} ref={progressReff}>&nbsp;</div> </div>   <div style={{cursor:"pointer"}} onClick={() => { abortProcess(attachment.id) }}>&nbsp;<BsX style={{fontSize:"15px"}}></BsX></div></>
                }
                
            </div>  : 
              attachment.status=="error" ?
              <div className="AttachmentItem"  id='parentAtt_${aaa}' key={attachment.id}>   
                   {getIcon(attachment.mimeType, attachment.name)}<div>   {attachment.name} </div>   
               {
                   <><div style={{width:"40px",background:"#E4E4E4"}}  ><div  ref={progressReff} style={{paddingTop:"1px"}}><span style={{color:"blue", fontWeight:"bold",fontSize:"8pt"}} onClick={()=>{retryProcess(attachment.id)}}>Retry</span></div> </div>   <div style={{cursor:"pointer"}} onClick={() => { abortProcess(attachment.id) }}>&nbsp;<BsX style={{fontSize:"15px"}}></BsX></div></>
               }
               
           </div>  : ""

    ): ""
    
}

      
    { isContextMenuShown!.visible && (
            <div style={{top:(aPosition.y +10),left:(aPosition.x), position:"fixed", float:"left"}}
                className="custom-context-menu" onMouseLeave={hideContextMenu}   >
                <div key='1' className="option" onClick={()=>{ getAttachment({id:isContextMenuShown!.id,
                    file:isContextMenuShown!.file,
                    name:isContextMenuShown!.name,
                    mimeType:isContextMenuShown!.mimeType,
                    size:isContextMenuShown!.size
                },'open')}}>
                <i><BsFolder2Open /></i>
                    <div>Open</div>
                </div>
                <div>

                </div>
                <div key='2' className="option" onClick={()=>{ deleteAttachment( parseInt(isContextMenuShown!.id))}}>
                    <i><BsX /></i>
                    <div>Delete</div>
                </div>

            </div>
        )}
     </>
     );

    
}

export default AttachmentProgress

 
