
import { FallbackProps } from 'react-error-boundary';
import { getRandomValue } from '../utilities/RandomValue';
import { logErrorToService } from './logerror';
import { useEffect, useState } from 'react';

export function ErrorFallback(props:FallbackProps) {
    const [logid, setLog] = useState("");
    useEffect(() => {
        let errorId = Array.from(getRandomValue()).map(x => x.toString(36)).join('').toUpperCase();
        setLog(errorId);
        logErrorToService(props.error, errorId);
    }, []);

    const restartA = () => {
        sessionStorage.clear();
        localStorage.clear();
        var urlParts = window.location.href.substring((window.location.href.indexOf("://")+3)).split("/");
            let redirectURL = ""
            if(urlParts.length>1 && urlParts[1]!=''){
                redirectURL = "?_referer="+ urlParts[1];
            }
        window.location.href = "/" + redirectURL;
    }
    return (
    <div style={{textAlign:"center",fontSize:"small", width:"100%"}}>
        <p><br></br><b>An error occured</b></p>
        <p>Please use the following reference number when contacting support</p>
        <p>Reference number: <b>{logid}</b></p>
        <p>You can retry to see if the problem persists or restart the application.</p>
        <button key="dialog-confirm" className={`g-btn g-btn-action`} onClick={() => props.resetErrorBoundary()}> Retry </button> &nbsp;&nbsp;&nbsp;&nbsp;
            <button key="dialog-cancel" className="g-btn g-btn-alternate" onClick={() => restartA()}> Restart Application </button>
    </div>
    )
  }